import formatServerUrl from '../../utils/URL';


export const SurroundingTypeIndexes = {
  COMP: 0,
  FLIP: 1,
  NEIGHBOR: 2,
  LINKED: 3,
  REMAINING: 4,
};

export const GroupListingTypes = [
  { name: 'On Market', type: 'K', editable: false, filter: { listingType: 'M' } },
  { name: 'Just Sold', type: 'J', editable: false, filter: {} },
  { name: 'Vacant', type: 'V', filter: { listingType: 'V' } },
  { name: 'High Equity', type: 'E', filter: { listingType: 'E' } },
  { name: 'Low Equity', type: 'Q', filter: { loanToValueRatioMin: 0.5 } },
  { name: 'Neg Equity', type: 'Y', filter: { estimatedEquityMax: -1 } },
  { name: 'Bank Owned', type: 'F', filter: { foreclosureStatus2: 'FORECLOSURE' } },
  { name: 'Pre-Foreclosure', type: 'P', filter: { listingType: 'P' } },
  { name: 'Auction', type: 'A', filter: { listingType: 'A' } },
  { name: 'Has Lien', type: 'L', filter: { listingType: 'L' } },
  { name: 'Free & Clear', type: 'R', filter: { listingType: 'R' } },
  { name: 'Bankruptcy', type: 'B', filter: { listingType: 'B' } },
].map(({ name, type, editable = true, filter }, seq) => ({
  name,
  type,
  editable,
  seq,
  filter: { ...filter, name },
}));

export const GroupListingTypeMap = GroupListingTypes.reduce((m, t) => ({ ...m, [t.type]: t }), {});

export const NonDisclosureStates = ['AK', 'ID', 'KS', 'LA', 'MS', 'MO', 'MT', 'NM', 'ND', 'TX', 'UT', 'WY'];

export const PropertyGroupTypes = {
  FAVORITE: 'FAVORITE',
  MARKETING: 'MARKETING',
  MONITORED: 'MONITORED',
  MOBILE: 'MOBILE',
};

export const GroupTypeNames = {
  FAVORITE: 'Favorites List',
  MARKETING: 'Marketing List',
  MONITORED: 'Automated List',
  CONTACT: 'Group',
  APPEND: 'Skip Tracing Job',
  MOBILE: 'Mobile Favorite',
};

export const GroupTypeHeaders = {
  FAVORITE: 'Favorites',
  MARKETING: 'Marketing Lists',
  MONITORED: 'Automated Lists',
  CONTACT: 'Contacts',
  APPEND: 'Skip Tracing Jobs',
  MOBILE: 'Mobile',
};

export const SurroundingPropertyContexts = {
  COMPARABLES: 'COMPARABLES',
  OFFER_ARV: 'OFFER_ARV',
  OFFER_EXIT_PRICE: 'OFFER_EXIT_PRICE',
};

export const GroupSearchModes = {
  LIST: 'LIST',
  PUSHPIN: 'PUSHPIN',
};

export const TaxExemptionOptions = [
  { label: 'Cemetery', value: 'CEMETERY' },
  { label: 'Disabled', value: 'DISABLED' },
  { label: 'Homestead', value: 'HOMESTEAD' },
  { label: 'Hospital', value: 'HOSPITAL' },
  { label: 'Library', value: 'LIBRARY' },
  { label: 'Religious', value: 'RELIGIOUS' },
  { label: 'School', value: 'SCHOOL' },
  { label: 'Senior Citizen', value: 'SENIOR_CITIZEN' },
  { label: 'Utilities', value: 'UTILITIES' },
  { label: 'Veteran', value: 'VETERAN' },
  { label: 'Welfare', value: 'WELFARE' },
  { label: 'Widow', value: 'WIDOW' },
  { label: 'No Exemption Status', value: 'NONE' },
];

export const alertIcons = ['iconHome', 'iconPlus', 'iconImportant', 'iconHammer'];

export const Colors = [
  '#ff6909',
  '#ff0909',
  '#ebcb35',
  '#ff6582',
  '#82b045',
  '#2a420b',
  '#3989c0',
  '#27527d',
  '#550885',
  '#a252d0',
];

export const PropertyClasses = [
  { name: 'Residential', id: 'R' },
  { name: 'Commercial', id: 'C' },
  { name: 'Office', id: 'O' },
  { name: 'Industrial', id: 'I' },
  { name: 'Agricultural', id: 'A' },
  { name: 'Recreational', id: 'F' },
  { name: 'Transportation', id: 'T' },
  { name: 'Exempt', id: 'E' },
  { name: 'Vacant Land', id: 'V' },
];

export const PropertyClassMap = PropertyClasses.reduce((m, c) => ({ ...m, [c.id]: c }), {});

export const PropertyTypes = [
  { id: '7000', name: 'Agricultural / Rural', classCode: 'A' },
  { id: '7004', name: 'Crop, Field Crops, Row Crops (all soil classes)', classCode: 'A' },
  { id: '7008', name: 'Dairy Farm', classCode: 'A' },
  { id: '7011', name: 'Desert or Barren Land', classCode: 'A' },
  { id: '7001', name: 'Farm land ', classCode: 'A' },
  { id: '7015', name: 'Feedlots', classCode: 'A' },
  { id: '7014', name: 'Grove (Agricultural)', classCode: 'A' },
  { id: '7017', name: 'Horticulture, Growing Houses (Agricultural)', classCode: 'A' },
  { id: '7021', name: 'Irrigation, Flood Control', classCode: 'A' },
  { id: '7016', name: 'Livestock', classCode: 'A' },
  { id: '7013', name: 'Misc. Structures - Ranch, Farm, Fixtures', classCode: 'A' },
  { id: '7022', name: 'Natural Resources', classCode: 'A' },
  { id: '7005', name: 'Orchard (fruit; nut)', classCode: 'A' },
  { id: '7012', name: 'Pasture, Meadow', classCode: 'A' },
  { id: '7007', name: 'Poultry Farm', classCode: 'A' },
  { id: '7002', name: 'Ranch', classCode: 'A' },
  { id: '7003', name: 'Range land (grazing)', classCode: 'A' },
  { id: '7020', name: 'Reservoir, Water Supply', classCode: 'A' },
  { id: '7023', name: 'Rural Improved / Non-Residential', classCode: 'A' },
  { id: '7009', name: 'Timberland, Forest, Trees (Agricultural)', classCode: 'A' },
  { id: '7019', name: 'Truck Crops', classCode: 'A' },
  { id: '7006', name: 'Vineyard (Agricultural)', classCode: 'A' },
  { id: '7018', name: 'Well Site (Agricultural)', classCode: 'A' },
  { id: '7010', name: 'Wildlife Refuge', classCode: 'A' },
  { id: '2045', name: 'Appliance Store', classCode: 'C' },
  { id: '2024', name: 'Auto repair, auto parts, Garage', classCode: 'C' },
  { id: '2015', name: 'Bakery', classCode: 'C' },
  { id: '2016', name: 'Bar, Tavern', classCode: 'C' },
  { id: '2054', name: 'Bed & Breakfast', classCode: 'C' },
  { id: '2025', name: 'Car wash', classCode: 'C' },
  { id: '2038', name: 'Casino', classCode: 'C' },
  { id: '2000', name: 'Commercial (General)', classCode: 'C' },
  { id: '2043', name: 'Commercial Building', classCode: 'C' },
  { id: '2052', name: 'Commercial Condominium (not offices)', classCode: 'C' },
  { id: '2044', name: 'Commercial/Office/Residential Mixed Use', classCode: 'C' },
  { id: '2008', name: 'Community Shopping Plaza or Shopping Center, Mini-Mall', classCode: 'C' },
  { id: '2019', name: 'Convenience Store with fuel', classCode: 'C' },
  { id: '2018', name: 'Convenience store ', classCode: 'C' },
  { id: '2032', name: 'Day care, Pre-school (Commercial)', classCode: 'C' },
  { id: '2004', name: 'Department Store (apparel, household goods, furniture)', classCode: 'C' },
  { id: '2005', name: 'Department Store (multi-story)', classCode: 'C' },
  { id: '2013', name: 'Drive-thru Restaurant, Fast Food', classCode: 'C' },
  { id: '2053', name: 'Drug Store or Pharmacy', classCode: 'C' },
  { id: '2026', name: 'Dry Cleaner, Laundry', classCode: 'C' },
  { id: '2050', name: 'Farm Supply & Equipment (Commercial)', classCode: 'C' },
  { id: '2037', name: 'Funeral Home, Mortuary (Commercial)', classCode: 'C' },
  { id: '2051', name: 'Garden Center, Home Improvement DIY', classCode: 'C' },
  { id: '2041', name: 'Gas Station', classCode: 'C' },
  { id: '2006', name: 'Grocery, Supermarket', classCode: 'C' },
  { id: '2034', name: 'Hotel', classCode: 'C' },
  { id: '2040', name: 'Hotel or Motel', classCode: 'C' },
  { id: '2039', name: 'Hotel-Resort', classCode: 'C' },
  { id: '2046', name: 'Kennel', classCode: 'C' },
  { id: '2047', name: 'Laundromat (self-service)', classCode: 'C' },
  { id: '2017', name: 'Liquor Store', classCode: 'C' },
  { id: '2031', name: 'Mini-Warehouse, Storage', classCode: 'C' },
  { id: '2033', name: 'Motel', classCode: 'C' },
  { id: '2009', name: 'Neighborhood Shopping Center, Strip Center/Mall, Enterprise Zone', classCode: 'C' },
  { id: '2048', name: 'Nightclub (Cocktail Lounge)', classCode: 'C' },
  { id: '2028', name: 'Nursery, Greenhouse, Florist', classCode: 'C' },
  { id: '2035', name: 'Parking Garage, Parking Structure', classCode: 'C' },
  { id: '2036', name: 'Parking Lot', classCode: 'C' },
  { id: '2030', name: 'Printer - Retail', classCode: 'C' },
  { id: '2007', name: 'Regional Shopping Center or Mall with Anchor store', classCode: 'C' },
  { id: '2012', name: 'Restaurant', classCode: 'C' },
  { id: '2001', name: 'Retail Stores (Personal Services, Photography, Travel)', classCode: 'C' },
  { id: '2027', name: 'Service Shop', classCode: 'C' },
  { id: '2020', name: 'Service station (full service)', classCode: 'C' },
  { id: '2021', name: 'Service station w/convenience store (food mart)', classCode: 'C' },
  { id: '2010', name: 'Shopping Center COMMON AREA parking, park etc.', classCode: 'C' },
  { id: '2002', name: 'Store (multi-story)', classCode: 'C' },
  { id: '2003', name: 'Store/Office (mixed use)', classCode: 'C' },
  { id: '2042', name: 'Stores & Apartments', classCode: 'C' },
  { id: '2014', name: 'Take-out Restaurant', classCode: 'C' },
  { id: '2022', name: 'Truck stop (fuel and diner)', classCode: 'C' },
  { id: '2023', name: 'Vehicle Rentals, Vehicle Sales', classCode: 'C' },
  { id: '2011', name: 'Veterinary or Animal Hospital', classCode: 'C' },
  { id: '2029', name: 'Wholesale Outlet, Franchise Discount Store', classCode: 'C' },
  { id: '9108', name: 'Cemetery (Exempt)', classCode: 'E' },
  { id: '9110', name: 'Charitable organization', classCode: 'E' },
  { id: '9107', name: 'Children\'s Home, Orphanage', classCode: 'E' },
  { id: '9210', name: 'City, Municipal, Town, Village Owned', classCode: 'E' },
  { id: '9215', name: 'Community Center', classCode: 'E' },
  { id: '9218', name: 'Correctional Facility, Jails, Prisons, Insane Asylum', classCode: 'E' },
  { id: '9211', name: 'County Owned', classCode: 'E' },
  { id: '9109', name: 'Crematorium, Mortuary (Exempt)', classCode: 'E' },
  { id: '9206', name: 'Cultural, Historical', classCode: 'E' },
  { id: '9103', name: 'Educational - PRIVATE', classCode: 'E' },
  { id: '9204', name: 'Educational Colleges, University-PUBLIC', classCode: 'E' },
  { id: '9208', name: 'Emergency (Police; Fire; etc.)', classCode: 'E' },
  { id: '9000', name: 'Exempt (full or partial)', classCode: 'E' },
  { id: '9213', name: 'Federal Property', classCode: 'E' },
  { id: '9202', name: 'Forest (park; reserve; recreation, conservation)', classCode: 'E' },
  { id: '9200', name: 'Governmental/Public Use (General)', classCode: 'E' },
  { id: '9207', name: 'Govt. Administrative Office', classCode: 'E' },
  { id: '9309', name: 'Historical District', classCode: 'E' },
  { id: '9304', name: 'Historical Office', classCode: 'E' },
  { id: '9308', name: 'Historical Park, Site, Misc.', classCode: 'E' },
  { id: '9307', name: 'Historical Recreation, Entertainment', classCode: 'E' },
  { id: '9301', name: 'Historical Residence', classCode: 'E' },
  { id: '9302', name: 'Historical Retail', classCode: 'E' },
  { id: '9305', name: 'Historical Transient Lodging (hotel/motel)', classCode: 'E' },
  { id: '9303', name: 'Historical Warehouse', classCode: 'E' },
  { id: '9300', name: 'Historical-PRIVATE (General)', classCode: 'E' },
  { id: '9104', name: 'Hospital-PRIVATE', classCode: 'E' },
  { id: '9219', name: 'Hospital-PUBLIC', classCode: 'E' },
  { id: '9001', name: 'Indian Lands', classCode: 'E' },
  { id: '9100', name: 'Institutional (General)', classCode: 'E' },
  { id: '9105', name: 'Medical Clinic', classCode: 'E' },
  { id: '9201', name: 'Military', classCode: 'E' },
  { id: '9209', name: 'Other exempt property', classCode: 'E' },
  { id: '9102', name: 'Parochial School, Private School', classCode: 'E' },
  { id: '9205', name: 'Post Office', classCode: 'E' },
  { id: '9112', name: 'Private Utility', classCode: 'E' },
  { id: '9214', name: 'Public Health Care Facility', classCode: 'E' },
  { id: '9203', name: 'Public School', classCode: 'E' },
  { id: '9216', name: 'Public Utility', classCode: 'E' },
  { id: '9111', name: 'Recreational Non-Taxable (Camps, Boy Scouts)', classCode: 'E' },
  { id: '9101', name: 'Religious, Church, Worship', classCode: 'E' },
  { id: '9106', name: 'Retired, Handicap, Convalescent, Nursing Home', classCode: 'E' },
  { id: '9212', name: 'State Owned', classCode: 'E' },
  { id: '9217', name: 'Welfare, Social Service, Low Income Housing (Exempt)', classCode: 'E' },
  { id: '4022', name: 'Amusement Park, Tourist Attraction', classCode: 'F' },
  { id: '4005', name: 'Arcades (Amusement)', classCode: 'F' },
  { id: '4011', name: 'Arenas, Convention Center', classCode: 'F' },
  { id: '4012', name: 'Auditoriums', classCode: 'F' },
  { id: '4003', name: 'Boat slips, Marina, Yacht Club, Boat Landing', classCode: 'F' },
  { id: '4004', name: 'Bowling Alley', classCode: 'F' },
  { id: '4018', name: 'Campground, RV Park', classCode: 'F' },
  { id: '4007', name: 'Clubs, Lodges, Professional Associations', classCode: 'F' },
  { id: '4009', name: 'Country Club', classCode: 'F' },
  { id: '4016', name: 'Dance Hall', classCode: 'F' },
  { id: '4021', name: 'Drive-In Theater', classCode: 'F' },
  { id: '4019', name: 'Fairgrounds', classCode: 'F' },
  { id: '4024', name: 'Fish Camps, Game Club, Target Shooting', classCode: 'F' },
  { id: '4031', name: 'Go-carts, Miniature Golf, Water slides', classCode: 'F' },
  { id: '4028', name: 'Golf Course', classCode: 'F' },
  { id: '4013', name: 'Golf Driving Range', classCode: 'F' },
  { id: '4015', name: 'Gym, Health Spa', classCode: 'F' },
  { id: '4008', name: 'Museums, Library, Art Gallery', classCode: 'F' },
  { id: '4025', name: 'Outdoor Recreation: Beach, Mountain, Desert', classCode: 'F' },
  { id: '4027', name: 'Park, Playground, Picnic Area', classCode: 'F' },
  { id: '4023', name: 'Piers, Wharf ', classCode: 'F' },
  { id: '4026', name: 'Pool Hall, Billiard Parlor', classCode: 'F' },
  { id: '4002', name: 'Public Swimming Pool', classCode: 'F' },
  { id: '4014', name: 'Race track ', classCode: 'F' },
  { id: '4029', name: 'Racquet or Tennis Court', classCode: 'F' },
  { id: '4001', name: 'Recreation Center', classCode: 'F' },
  { id: '4000', name: 'Recreational/Entertainment (General)', classCode: 'F' },
  { id: '4017', name: 'Riding Stable, Trails', classCode: 'F' },
  { id: '4006', name: 'Skating rink, Ice Skating, Roller Skating', classCode: 'F' },
  { id: '4010', name: 'Stadiums', classCode: 'F' },
  { id: '4020', name: 'Theater', classCode: 'F' },
  { id: '4030', name: 'Zoo', classCode: 'F' },
  { id: '5006', name: 'Assembly (light industrial)', classCode: 'I' },
  { id: '6020', name: 'Cannery', classCode: 'I' },
  { id: '6018', name: 'Chemical', classCode: 'I' },
  { id: '6024', name: 'Cold Storage', classCode: 'I' },
  { id: '5020', name: 'Common Area (Industrial)', classCode: 'I' },
  { id: '5009', name: 'Communications', classCode: 'I' },
  { id: '5010', name: 'Condominiums (Industrial)', classCode: 'I' },
  { id: '5019', name: 'Construction/Contracting Services (Industrial)', classCode: 'I' },
  { id: '6005', name: 'Distillery, Brewery, Bottling', classCode: 'I' },
  { id: '6002', name: 'Distribution Warehouse (Regional)', classCode: 'I' },
  { id: '6023', name: 'Dump Site', classCode: 'I' },
  { id: '6008', name: 'Factory (medium)', classCode: 'I' },
  { id: '5005', name: 'Food Packing, Packing Plant', classCode: 'I' },
  { id: '5007', name: 'Food Processing ', classCode: 'I' },
  { id: '6019', name: 'Foundry, Industrial Plant', classCode: 'I' },
  { id: '6021', name: 'Gasoline, Fuel Bulk Storage', classCode: 'I' },
  { id: '6022', name: 'Grain Elevator', classCode: 'I' },
  { id: '6000', name: 'Heavy Industrial (General)', classCode: 'I' },
  { id: '6015', name: 'Heavy Manufacturing', classCode: 'I' },
  { id: '5000', name: 'Industrial (General)', classCode: 'I' },
  { id: '5018', name: 'Industrial Loft Building, Loft Building', classCode: 'I' },
  { id: '5012', name: 'Industrial Park', classCode: 'I' },
  { id: '6016', name: 'Labor Camps (Industrial)', classCode: 'I' },
  { id: '5011', name: 'Laboratory, Research and Development Facility, Cosmetics, Pharmaceutical', classCode: 'I' },
  { id: '5002', name: 'Light Industrial', classCode: 'I' },
  { id: '5015', name: 'Lumber & Wood Product MFG (including furniture)', classCode: 'I' },
  { id: '6010', name: 'Lumberyard, Building Materials', classCode: 'I' },
  { id: '5001', name: 'Manufacturing (light)', classCode: 'I' },
  { id: '5014', name: 'Marine Facility/Boat Repairs (small craft or sailboat)', classCode: 'I' },
  { id: '6007', name: 'Mill', classCode: 'I' },
  { id: '6003', name: 'Mining', classCode: 'I' },
  { id: '5013', name: 'Multi-Tenant Industrial Bldg', classCode: 'I' },
  { id: '5016', name: 'Paper Product MFG & related products', classCode: 'I' },
  { id: '5017', name: 'Printing & Publishing (Light Industrial)', classCode: 'I' },
  { id: '6009', name: 'Processing Plant', classCode: 'I' },
  { id: '6014', name: 'Quarries', classCode: 'I' },
  { id: '5008', name: 'Recycling plant', classCode: 'I' },
  { id: '6006', name: 'Refinery, Petroleum Products', classCode: 'I' },
  { id: '6011', name: 'Shipyard/Storage - Built or Repaired (seagoing vessels)', classCode: 'I' },
  { id: '6012', name: 'Slaughter House, Stockyard', classCode: 'I' },
  { id: '6004', name: 'Storage yard (junk; auto wrecking, salvage)', classCode: 'I' },
  { id: '5004', name: 'Storage yard, Open Storage (light equipment, material)', classCode: 'I' },
  { id: '6025', name: 'Sugar Refinery', classCode: 'I' },
  { id: '6001', name: 'Transportation', classCode: 'I' },
  { id: '5003', name: 'Warehouse (Industrial)', classCode: 'I' },
  { id: '6013', name: 'Waste Disposal, Sewage, Treatment facility', classCode: 'I' },
  { id: '6017', name: 'Winery', classCode: 'I' },
  { id: '3000', name: 'Commercial Office (General)', classCode: 'O' },
  { id: '3011', name: 'Common Area (commercial)', classCode: 'O' },
  { id: '3008', name: 'Condominium Offices', classCode: 'O' },
  { id: '3005', name: 'Dental Bldg', classCode: 'O' },
  { id: '3007', name: 'Financial Bldg', classCode: 'O' },
  { id: '3006', name: 'Medical Bldg', classCode: 'O' },
  { id: '3010', name: 'Mixed Use (Commercial/Industrial)', classCode: 'O' },
  { id: '3012', name: 'Mobile Commercial Units', classCode: 'O' },
  { id: '3003', name: 'Office Bldg (General)', classCode: 'O' },
  { id: '3004', name: 'Office Bldg (multi-story)', classCode: 'O' },
  { id: '3001', name: 'Professional Bldg (legal; insurance; real estate; etc.)', classCode: 'O' },
  { id: '3002', name: 'Professional Bldg (multi-story)', classCode: 'O' },
  { id: '3009', name: 'Skyscraper/High-rise (Commercial Offices)', classCode: 'O' },
  { id: '1105', name: 'Apartment house (100+ units)', classCode: 'R' },
  { id: '1104', name: 'Apartment house (5+ units)', classCode: 'R' },
  { id: '1112', name: 'Apartments (generic)', classCode: 'R' },
  { id: '1108', name: 'Boarding or Rooming House, Apt Hotel, Transient Lodgings', classCode: 'R' },
  { id: '1013', name: 'Bungalow (Residential)', classCode: 'R' },
  { id: '1003', name: 'Cluster home (Residential)', classCode: 'R' },
  { id: '1004', name: 'Condominium (Residential)', classCode: 'R' },
  { id: '1005', name: 'Cooperative (Residential)', classCode: 'R' },
  { id: '1113', name: 'Dormitory, Group Quarters (Residential)', classCode: 'R' },
  { id: '1101', name: 'Duplex (2 units, any combination)', classCode: 'R' },
  { id: '1111', name: 'Fraternity or Sorority House', classCode: 'R' },
  { id: '1106', name: 'Garden Apt, Court Apt (5+ units)', classCode: 'R' },
  { id: '1018', name: 'Garden Home', classCode: 'R' },
  { id: '1107', name: 'High-rise Apartments', classCode: 'R' },
  { id: '1019', name: 'Landominium', classCode: 'R' },
  { id: '1016', name: 'Manufactured, Modular, Pre-Fabricated Homes', classCode: 'R' },
  { id: '1015', name: 'Misc Residential Improvement', classCode: 'R' },
  { id: '1109', name: 'Mobile Home or Trailer Park', classCode: 'R' },
  { id: '1006', name: 'Mobile home', classCode: 'R' },
  { id: '1110', name: 'Multi-Family Dwellings (Generic, 2+)', classCode: 'R' },
  { id: '1017', name: 'Patio Home', classCode: 'R' },
  { id: '1009', name: 'Planned Unit Development (PUD Residential)', classCode: 'R' },
  { id: '1103', name: 'Quadruplex (4 units, any combination)', classCode: 'R' },
  // { id: '1000', name: 'Residential (General) (Single)', classCode: 'R' },
  { id: '1010', name: 'Residential Common Area (Condo/PUD/etc.)', classCode: 'R' },
  { id: '1114', name: 'Residential Condominium Development (Association Assessment)', classCode: 'R' },
  { id: '1100', name: 'Residential Income (General) (Multi-Family)', classCode: 'R' },
  { id: '1901', name: 'Residential Parking Garage', classCode: 'R' },
  { id: '1902', name: 'Residential Storage Space is mainly applied to storage spaces that are assessed separately from their correlated Condos', classCode: 'R' },
  { id: '1007', name: 'Row house (Residential)', classCode: 'R' },
  { id: '1008', name: 'Rural Residence (Agricultural)', classCode: 'R' },
  { id: '1012', name: 'Seasonal, Cabin, Vacation Residence', classCode: 'R' },
  // { id: '1999', name: 'Single Family Residential', classCode: 'R' },
  // { id: '1001', name: 'Single Family Residential', classCode: 'R' },
  { id: '1011', name: 'Timeshare (Residential)', classCode: 'R' },
  { id: '1002', name: 'Townhouse (Residential)', classCode: 'R' },
  { id: '1102', name: 'Triplex (3 units, any combination)', classCode: 'R' },
  { id: '1014', name: 'Zero Lot Line (Residential)', classCode: 'R' },
  { id: '6501', name: 'Airport & related', classCode: 'T' },
  { id: '6505', name: 'Bus Terminal', classCode: 'T' },
  { id: '6509', name: 'Cable TV Station Communications', classCode: 'T' },
  { id: '6512', name: 'Commercial Auto Transportation/Storage', classCode: 'T' },
  { id: '6503', name: 'Freeways, State Hwys', classCode: 'T' },
  { id: '6510', name: 'Harbor & Marine Transportation', classCode: 'T' },
  { id: '6511', name: 'Microwave', classCode: 'T' },
  { id: '6513', name: 'Pollution Control', classCode: 'T' },
  { id: '6507', name: 'Radio or TV Station Communications', classCode: 'T' },
  { id: '6502', name: 'Railroad & related', classCode: 'T' },
  { id: '6504', name: 'Roads, Streets, Bridges', classCode: 'T' },
  { id: '6506', name: 'Telegraph, Telephone Communications', classCode: 'T' },
  { id: '6500', name: 'Transportation & Communications (General)', classCode: 'T' },
  { id: '6508', name: 'Truck Terminal (Motor Freight)', classCode: 'T' },
  { id: '8013', name: 'Abandoned Site, Contaminated Site', classCode: 'V' },
  { id: '8008', name: 'Agricultural-Unimproved Vacant Land', classCode: 'V' },
  { id: '8504', name: 'Centrally Assessed', classCode: 'V' },
  { id: '8002', name: 'Commercial-Vacant Land', classCode: 'V' },
  { id: '8006', name: 'Government-Vacant Land', classCode: 'V' },
  { id: '8003', name: 'Industrial-Vacant Land', classCode: 'V' },
  { id: '8005', name: 'Institutional-Vacant Land', classCode: 'V' },
  { id: '8007', name: 'Multi-Family-Vacant Land', classCode: 'V' },
  { id: '8004', name: 'Private Preserve, Open Space-Vacant Land (Forest Land, Conservation)', classCode: 'V' },
  { id: '8010', name: 'Recreational-Vacant Land', classCode: 'V' },
  { id: '8503', name: 'Redevelopment Agency or Zone', classCode: 'V' },
  { id: '8502', name: 'Regulating Districts & Assessments; Tax Abatement', classCode: 'V' },
  { id: '8001', name: 'Residential-Vacant Land', classCode: 'V' },
  { id: '8501', name: 'SBE - Special Assessments', classCode: 'V' },
  { id: '8500', name: 'Special Purpose', classCode: 'V' },
  { id: '8014', name: 'Under Construction', classCode: 'V' },
  { id: '8012', name: 'Unusable Land (Remnant, Steep, etc.)', classCode: 'V' },
  { id: '8000', name: 'Vacant Land (General)', classCode: 'V' },
  { id: '8009', name: 'Waste Land, Marsh, Swamp', classCode: 'V' },
  { id: '8011', name: 'Water Area (Lakes; River; Shore)', classCode: 'V' },
];

export const PropertyClassOptions = PropertyClasses.map(({ id, name }) => ({ value: id, label: name }));
export const PropertyTypeOptions = [
  { value: 'SFR', label: 'Single Family', pinned: true },
  { value: 'CONDO', label: 'Condo/Townhouse', pinned: true },
  { value: 'MULTI2', label: 'Multi-Family 2-4', pinned: true },
  { value: 'MULTI5', label: 'Multi-Family 5+', pinned: true },
].concat(PropertyTypes.map(({ id, name, classCode }) => ({ value: id, label: name, classCode, group: PropertyClassMap[classCode].name })));



export const SaleSituationOptions = [
  { value: 'C', label: 'Cash Buyer' },
  { value: 'A', label: 'Financed' },
  { value: 'N', label: 'New Construction' },
  { value: 'I', label: 'Intra-Family' },
  { value: 'F', label: 'Foreclosure' },
  { value: 'R', label: 'REO Sale' },
  { value: 'M', label: 'Multi-Parcel Sales'}, // ID23-1168 SubDivision Filter.
];

export const OwnerOccupiedOptions = [
  { label: 'Any', value: '' },
  { label: 'Owner Occupied', value: 'true' },
  { label: 'Non-Owner Occupied', value: 'false' },
];

export const YesNoOptions = [
  { label: 'Any', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const ExcludeIncludeOptions = [
  { label: 'Include', value: 'false' },
  { label: 'Exclude', value: 'true' },
];

export const PurchaseMethodOptions = [
  { label: 'Any', value: '' },
  { label: 'Cash', value: 'true' },
  { label: 'Financed', value: 'false' },
];

export const SaleSituations = [
  { name: 'Cash', code: 'C', color: 'forestgreen' },
  { name: 'Foreclosure', code: 'F', color: 'mediumturquoise' },
  { name: 'Bank', code: 'R', color: 'darkslateblue' },
  { name: 'New Construction', code: 'N', color: 'maroon' },
  { name: 'Intra-Family', code: 'I', color: 'darkorchid' },
  // Transitioned to css3 color names to support Google pins
  // { name: 'Cash', code: 'C', color: '#218c0a' },
  // { name: 'Foreclosure', code: 'F', color: '#3da3ba' },
  // { name: 'Bank', code: 'R', color: '#4d5cc6' },
  // { name: 'New Construction', code: 'N', color: '#735749' },
  // { name: 'Inter-Family', code: 'I', color: '#a40bc4' },
];

export const SaleSituationMap = SaleSituations.reduce((m, s) => ({ ...m, [s.code]: s }), {});

export const getGoogleImageUrl = (lat, lon, zoom = 20, width = 500, height = 400, color = 'blue') => {
  if (!lat || !lon) return null;
  const ll = `${lat},${lon}`;
  return `https://maps.googleapis.com/maps/api/staticmap?zoom=20&maptype=satellite&key=AIzaSyCPwp5TrICOkQ2uhNC-RNcY9yHzvMF38Hw&size=${width}x${height}&center=${ll}&markers=${ll}`;
};

export const getPropertyImageUrl = (id) => {
  return formatServerUrl(`resource/auth/ps4/property/${id}/image`);
};

// "format=JPEG&center=" + lat + "," + lng + "&scale=2&size=" + Math.round(width / 2) + "x" + Math.round(height / 2) + "&maptype=" + type.getCode() + "&key=" + key + pins + (zoom == null ? "" : "&zoom=" + zoom)

export const defaultSurroundingPropertySearch = {
  saleDateMin: null,
  saleDateMax: null,
  squareFeetMax: null,
  squareFeetMin: null,
  lotSquareFeetMax: null,
  lotSquareFeetMin: null,
  distanceFromSubjectMax: null,
  shapeDefinition: null,
  bedroomsMin: null,
  bedroomsMax: null,
  bathroomsMin: null,
  bathroomsMax: null,
  yearBuiltMin: null,
  yearBuiltMax: null,
  storiesMin: null,
  storiesMax: null,
  flipLengthMin: null,
  flipLengthMax: null,
  status: null,
  subdivision: null,
  propertyTypeCode: null,
  propertyClassCode: null,
  landUseCode: null,
  multiParcel: null,
  cashSale: null,
  excludeMultiParcel: false,
  saleSituation: null,
  saleSituationCode: null,
  listingDateMin: null,
  listingDateMax: null,
  auctionDateMin: null,
  auctionDateMax: null,
  mlsListingStatus: null,
  ownerOccupied: null,
  ownershipLengthMin: null,
  ownershipLengthMax: null,
  ltvRatioMin: null,
  ltvRatioMax: null,
  estimatedEquityMin: null,
  estimatedEquityMax: null,
  bankruptcyChapter: null,
  listingType: null,
};

export const defaultSelection = {
  allSelected: false,
  remainingSelected: false,
  items: {},
  selected: 0,
};

// NDA States definitions below
// Alaska (AK), Idaho (ID), Kansas (KS), Louisiana (LA), Mississippi (MS), 
// Montana (MT), New Mexico (NM), North Dakota (ND), South Dakota (SD), 
// Texas (TX), Utah (UT), and Wyoming (WY).
export const NDA_STATES = ['AK', 'ID', 'KS', 'LA', 'MS', 'MT', 'NM', 'ND', 'SD', 'TX', 'UT', 'WY'];

export default PropertyGroupTypes;
