import React, { useState, useEffect } from "react";
import css from "../style.scss";
import classNames from "classnames";

const OptionItem = ({ option, selected, onSelect }) => {
  const handleClick = () => {
    onSelect(option);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <li
      className={classNames(css.optionItem, { [css.active]: selected })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <span>{option?.label}</span>
    </li>
  );
};

const ToggleList = ({ headerTitle, headerTitleShow = true, options, onSelectOption, selected, headerClassName, isHighlightIndex }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [showHeader, setShowHeader] = useState(headerTitleShow === undefined ? true : headerTitleShow);

  useEffect(() => {
    onLoadSelectOptionSelect(selected);
  }, [selected]);

  useEffect(() => {
    setShowHeader(headerTitleShow);
  }, [headerTitleShow]);

  const onLoadSelectOptionSelect = (selectedValue) => {
    setSelectedOption(getSelectedValue(selectedValue));
  };

  const getSelectedValue = (selectedValue) => {
    if (selectedValue === true || selectedValue === false || selectedValue === '' || selectedValue) {
      return selectedValue;
    }
    return '';
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option.value);
    onSelectOption(option); // Pass selected option to parent
  };

  return (
    // <div className={!headerTitleShow ? css.toggleBorder: ""}>
    <div>
      {showHeader && (
        <h4 className={classNames(( headerClassName === "" ? css.clsSubHeading: headerClassName), (isHighlightIndex ? css.highlightOrange : "") )}>{headerTitle}</h4>
      )}
      <ul className={css.clsToggleTab}>
        {options && options.length && options.map((option) => (
          <OptionItem
            key={option.value}
            option={option}
            selected={option.value === selectedOption}
            onSelect={handleOptionSelect}
          />
        ))}
      </ul>
    </div>
  );
};

export default ToggleList;
