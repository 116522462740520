import React, { PureComponent } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AgGridReact } from 'ag-grid-react';
import { Reports, UserTypes, UserTypeMap } from 'data/user/constants';
import { List, Map } from 'immutable';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { getBrandCode, isReiAutomator, isElite, isProduction } from 'utils/brand';
import LoanApplication from 'app/components/LoanApplication';
import { gridValue, numberFormatter, currencyFormatter, percentFormatter, dateFormatter, properCaseFormatter, vacantFormatter, handleSurroundingTypeChange } from 'utils/grid';
import AddressLinkCellRenderer from 'utils/grid/addressLinkCellRenderer';
import { joinWith, toProperCase } from 'utils/string';
import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';
import withProperty from 'app/components/withProperty';
import { downloadReport, downloadPropertyData, purchaseReportAndLoad, selectDocumentLoading, selectTags, selectStatuses, selectOriginalLists, selectLoading, saveProperties, PropertyGroupTypes, updateSurroundingPropertySelection } from 'data/property';
import { selectPropertyDetailLayouts, selectUsers, selectProfile } from 'data/user';
import { selectPremiumReports } from 'data/user/selectors';
import numberToPrice from 'utils/currency/numberToPrice';
import formatDate, { formatDateYearMonth } from 'utils/date/formatDate';
import UserLogo from 'app/components/UserLogo';
import numberFormat, { formatYearMonth } from 'utils/number/format';
import percentFormat from 'utils/percent/formatPercent';
import formatFullAddressObject from 'utils/address/formatAddress';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import SVG from 'components/base/SVG';
import AnalysisButton from 'app/components/AnalysisButton';
import Chart from 'components/Chart';
import RestrictedContent from 'app/components/RestrictedContent';
import CustomTabs from 'components/CustomTabs';
import AddToGroup from 'app/components/AddToGroup';
import { selectSurroundingPropertyType, SurroundingPropertyContexts } from 'data/property';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ProgressBar from 'components/ProgressBar';
import Confirm from 'app/components/Confirm';
import config from 'config';

import Tags from './Tags';
import PropertyComparables from './Comparables';
import PropertyDocuments from './Documents';
import Demographics from './Demographics';
import Panel from './Panel';
import PropertyGallery from '../Gallery/Gallery';
import GalleryCellRenderer from '../Surrounding/galleryCellRenderer';
import ValueCell from './valueCell';
import css from './style.scss';
import IndexCellRenderer from '../Surrounding/indexCellRenderer';
import IndexHeaderRenderer from '../Surrounding/indexHeaderRenderer';
import { TAX_COLUMNS, LEFT_FIELDS, RIGHT_FIELDS, COMP_FIELDS, MORTGAGE_FIELDS, OPPORTUNITY_FIELDS, GridProps, writeValues, writeSections, writeFields, writeTable } from './Table';
import DocumentImageCellRenderer from './documentImageCellRenderer';
import BedIcon from '../../../assets/images/Bed.svg';
import DropdownToggle from '../../../components/DropdownToggle';
import { trimAndAddEllipsis } from '../../../utils/string';
import { Permissions } from '../../../data/user/constants';
import MyBluebookButton from '../../components/MyBluebookButton';
// Circular Progress Bar for top section ui
import CircularProgressBar from '../../../components/CircularProgressBar';

// Intelligence Score Icons
import WholeHome from '../../../assets/images/wholehome.svg';
import IntelligenceIconSmall from '../../../assets/images/IntelligenceIcon_Small.svg';
import IntelligenceIconMedium from '../../../assets/images/IntelligenceIcon_Medium.svg';
import BathIcon from '../../../assets/images/Bath.svg';
import ForeclosureFactore from '../../../assets/images/foreclosure_factore.svg';
import Exterior from '../../../assets/images/exterior.svg';
import Interior from '../../../assets/images/interior.svg';
import Kitchen from '../../../assets/images/kitchen.svg';
import ToolsIcon from '../../../assets/images/ToolsIcon.svg';

// Components related Intelligence Bar and Intelligence Tab
import IntelliProgressBar from '../../../components/IntelliProgressBar';
import IntelligenceInfoCell from './IntelligenceInfoCell';
import IntelligenceValueCell from './IntelligenceCell';
import data from './IntelligenceDetails.json'
import foreclosureData from './IntelligenceForeclosureDetails.json'
import { isNDAState } from '../../../utils/ndaStates';


const taxPageFields = {
  sections: [{
    code: 'TOP',
    fields: [
      { name: 'Parcel Number', path: 'apn' },
      { name: 'Situs County', path: 'countyName' },
      { name: 'Primary Owner Full Name', path: 'owner1FullName' },
      { name: 'Tax Bill Mailing Address', path: 'mailAddress', mask: '{address}' },
    ],
  }, {
    code: 'BOTTOM',
    fields: [
      { name: 'Tax Year', path: 'assessmentYear' },
      { name: 'Tax Amount', path: 'taxAmount', mask: '{currency}' },
      { name: 'Total Assessed Value', path: 'assessedValue', mask: '{currency}' },
      { name: 'Land Value', path: 'assessedLandValue', mask: '{currency}' },
      { name: 'Improvement Value', path: 'assessedImprovementValue', mask: '{currency}' },
    ]
  },
  ],
};

// Importing the intelligence score api
const {
  constants: {
    API: { INTELLIGENCE_URL },
  },
} = config;

const LinkedStats = [
  { field: 'quantity', label: 'Total Properties Owned' },
  { field: 'openMortgageBalance', label: 'Total Open Loan Amount' },
  { field: 'estimatedValue', label: 'Total Estimated Value' },
  { field: 'estimatedEquity', label: 'Portfolio Equity' },
];

const pageFields = 'PROPERTY_SALE:cashSale,MLS:mlsListing,MLS2:listhubMlsListing,FORECLOSURE,LIEN:liens,DIVORCE,BANKRUPTCY'
  .split(',').map(f => f.split(':')).reduce((m, f) => ({ ...m, [f[0]]: f[1] || f[0].toLowerCase() }), {});

let pageMap;

const getPage = (page, target) => {
  const { name, sections, code } = page;
  const preForeclosure = code === 'FORECLOSURE' && target && target.get('type') === 'P';
  const tab = {
    code,
    initialized: false,
    name: preForeclosure ? 'Pre-Foreclosure Details' : name,
    sections: [],
  };

  if (target) {
    const data = List.isList(target) && target.size === 1 ? target.get(0) : target;

    if (List.isList(data)) {
      tab.sections = data.reduce((m, d, i) => m.concat({
        // name: items.size > 1 ? name.replace(' Information', ` ${i + 1} Information`) : name,
        code: `${code}${i}`,
        caption: name.replace('Details', String(i + 1)),
        fieldQuantity: 1,
        fields: [{
          value: d,
          tab: getPage(page, d),
        }],
      }), []);
    } else {
      tab.sections = sections.map(({ name, code, fields }, si) => ({
        // name: items.size > 1 ? name.replace(' Information', ` ${i + 1} Information`) : name,
        name,
        code,
        caption: !si && preForeclosure ? `Current Pre-Foreclosure (${target.get('active') ? 'Active' : 'Inactive'})` : null,
        fieldQuantity: fields.length,
        fields: fields.map(({ name, path, mask, newLine }) => {
          let tab;
          let value = data.getIn(path.split('.'), null);
          const grid = mask === '{grid}';
          if (value != null && mask) {
            if (mask.startsWith('[')) tab = getPage(pageMap[mask.substr(1, mask.length - 2)], value);
            else if (mask.includes('{string}')) value = mask.replace('{string}', value);
            else if (mask.includes('{currency}')) value = mask.replace('{currency}', `${numberToPrice(value)}${data.get(`${path}Estimated`) ? '' : ''}`);
            else if (mask.includes('{date}')) value = mask.replace('{date}', formatDate(value, null, null, data.get(`${path}Format`)));
            else if (mask.includes('{yesno}')) value = mask.replace('{yesno}', value ? 'Yes' : 'No');
            else if (mask.includes('{number}')) value = mask.replace('{number}', numberFormat(value));
            else if (mask.includes('{percent}')) value = mask.replace('{percent}', percentFormat(value * 100));
            else if (mask.includes('{address}')) {
              value = mask.replace('{address}', formatFullAddressObject(value));

              const ownerPropertyId = data.get('ownerPropertyId');
              if (path === 'mailAddress' && ownerPropertyId && ownerPropertyId !== data.get('id')) value = <a href={`/search/${ownerPropertyId}`} target="_blank" rel="noreferrer">{value}</a>;
            } else if (grid) {
              // Give enough v-space for row detail expansion (used in PFC but not MLS currently).
              value = (
                <div className={classNames('ag-theme-balham', css.grid)} style={{ height: `${Math.min(300, 120 + (value.size * 30))}px` }}>
                  <AgGridReact {...GridProps[name]} rowData={value.toJS()} />
                </div>
              );
            }
          }

          return { name, value, tab, grid, newLine };
        }).filter(({ value, tab }) => (!tab && value != null) || (tab && tab.sections.length)),
      })).filter(section => section.fields.length);
    }
  }

  return tab;
};

const ownerNameFormatter = ({ value, data: { owner2FullName } }) => toProperCase(joinWith('\n', value, owner2FullName));
const stateCountyFormatter = ({ value, data: { countyName } }) => `${value || ''}/${countyName || ''}`;
const loanSequenceFormatter = d => `Loan ${d.value}`;
const yearMonthFormatter = d => formatYearMonth(d.value);
const estimatedAmountFormatter = (d, props) => {
  const ndaLabel = d.value && isNDAState(props.property.get('address').get('stateCode')) ? 'Est.' : '';
  return (d.value && `${numberToPrice(d.value)}${d.data.amountEstimated ? '' : ''} ${ndaLabel}`) || '';
}

const emptyFormatter = () => '';
let processingRows = false;

const MortgageGridProps = {
  getRowNodeId: d => d.id,
  onRowGroupOpened: ({ api, data: { id }, node: { expanded } }) => {
    if (!processingRows && expanded) {
      processingRows = true;
      api.forEachNode((n) => { if (n.expanded && n.data.id !== id) n.setExpanded(false); });
      processingRows = false;
    }
  },
  domLayout: 'autoHeight',
  suppressCellSelection: true,
  suppressContextMenu: true,
  enableCellTextSelection: true,
  defaultColDef: { resizable: true, filter: true, sortable: true, menuTabs: [] },
  onFirstDataRendered: ({ api }) => api.sizeColumnsToFit(),
  columnDefs: [
    { headerName: 'Loan Position', field: 'seq', width: 100, suppressSizeToFit: true, resizable: false, suppressMovable: true, valueFormatter: loanSequenceFormatter },
    { headerName: 'Recording Date', field: 'loanRecordingDate', width: 110, suppressSizeToFit: true, valueFormatter: dateFormatter },
    { headerName: 'Document Number', field: 'documentNumber', cellRenderer: 'documentImageCellRenderer' },
    { headerName: 'Loan Type', field: 'loanType', width: 110, suppressSizeToFit: true },
    { headerName: 'Lender Name', field: 'lenderName', width: 150, suppressSizeToFit: true },
    { headerName: 'Loan Amount', field: 'amount', valueFormatter: currencyFormatter, width: 90, suppressSizeToFit: true },
    { headerName: 'Est. Rate', field: 'loanInterestRate', valueFormatter: percentFormatter, width: 70, suppressSizeToFit: true },
    { headerName: 'Type', field: 'financingType', width: 80, suppressSizeToFit: true },
    { headerName: 'Loan Term', field: 'loanTerm', width: 80, suppressSizeToFit: true },
    { headerName: 'Loan Due Date', field: 'loanDueDate', width: 100, suppressSizeToFit: true, valueFormatter: dateFormatter },
    { headerName: 'Est. Loan Payment', field: 'estimatedLoanPayment', valueFormatter: currencyFormatter },
    { headerName: 'Est. Loan Balance', field: 'estimatedLoanBalance', valueFormatter: currencyFormatter },
  ],
  frameworkComponents: {
    documentImageCellRenderer: DocumentImageCellRenderer,
  },
};

const TransactionGridProps = (props) => ({
  ...MortgageGridProps,
  columnDefs: [
    { headerName: '', field: 'id', width: 22, suppressSizeToFit: true, cellRenderer: 'agGroupCellRenderer', sortable: false, resizable: false, suppressMovable: true },
    { headerName: 'Recording Date', field: 'recordingDate', width: 115, suppressSizeToFit: true, valueFormatter: dateFormatter },
    { headerName: 'Sale Date', field: 'saleDate', width: 84, suppressSizeToFit: true, valueFormatter: dateFormatter },
    { headerName: 'Purchase Method', field: 'purchaseMethod', width: 115, suppressSizeToFit: true },
    { headerName: 'Document Number', field: 'documentNumber', width: 130, cellRenderer: 'documentImageCellRenderer', suppressSizeToFit: true },
    { headerName: 'Document Type', field: 'documentType', width: 110, suppressSizeToFit: true },
    { headerName: 'Transaction Type', field: 'transactionType', width: 120 },
    { headerName: 'Buyer Name(s)', field: 'buyerNames' },
    { headerName: 'Seller Name(s)', field: 'sellerNames' },
    { headerName: 'Amount', field: 'amount', width: 100, suppressSizeToFit: true, valueFormatter: (d) => estimatedAmountFormatter(d, props) },
  ],
  detailCellRendererParams: {
    detailGridOptions: {
      ...MortgageGridProps,
      columnDefs: [
        { headerName: '', headerClass: css.noBorder, field: 'id', width: 5, sortable: false, resizable: false, suppressMovable: true, suppressSizeToFit: true, valueFormatter: emptyFormatter },
        { headerName: 'Recording Date', field: 'loanRecordingDate', width: 110, suppressSizeToFit: true, valueFormatter: dateFormatter },
        { headerName: 'Document Number', field: 'documentNumber', width: 135, cellRenderer: 'documentImageCellRenderer', suppressSizeToFit: true },
        { headerName: 'Loan Type', field: 'loanType', width: 120 },
        { headerName: 'Loan Detail', field: 'loanDetail', width: 100, suppressSizeToFit: true },
        { headerName: 'Lender Name', field: 'lenderName', width: 300 },
        { headerName: 'Borrower Name(s)', field: 'buyerNames' },
        { headerName: 'Loan Term', field: 'loanTerm', width: 90, suppressSizeToFit: true },
        { headerName: 'Loan Due Date', field: 'loanDueDate', width: 110, suppressSizeToFit: true, valueFormatter: dateFormatter },
        { headerName: 'Loan Amount', field: 'amount', width: 105, suppressSizeToFit: true, valueFormatter: currencyFormatter },
      ],
    },
    getDetailRowData: ({ successCallback, data: { loans } }) => successCallback(loans),
  },
  masterDetail: true,
  getRowHeight: ({ node, data = {} }) => (node && node.detail && data.loans ? (data.loans.length * 30) + 75 : 30),
  isRowMaster: d => !!d.loans,
});

const fixedColumns = [
  { headerName: '', field: 'selected', width: 40, pinned: 'left', cellRenderer: 'indexCellRenderer', headerComponent: 'indexHeaderRenderer', sortable: false },
];

const LinkedPropertySubjectGridProps = {
  getRowNodeId: d => d.id,
  // domLayout: 'autoHeight',
  suppressCellSelection: true,
  suppressContextMenu: true,
  enableCellTextSelection: true,
  defaultColDef: { resizable: true, filter: true, sortable: true, menuTabs: [] },
  onFirstDataRendered: ({ api }) => api.sizeColumnsToFit(),
  getRowHeight: () => 50,
  columnDefs: [
    { headerName: 'Address', field: 'streetAddress', width: 115, cellRenderer: 'addressLinkCellRenderer' },
    { headerName: 'Photo', field: 'imagePath', width: 50, suppressSizeToFit: true, cellRenderer: 'galleryCellRenderer' },
    { headerName: 'County', field: 'countyName', width: 115, suppressSizeToFit: true },
    { headerName: 'Beds', field: 'bedrooms', width: 40, suppressSizeToFit: true, valueFormatter: numberFormatter },
    { headerName: 'Baths', field: 'bathrooms', width: 46, suppressSizeToFit: true, valueFormatter: numberFormatter },
    { headerName: 'SqFt', field: 'squareFeet', width: 50, suppressSizeToFit: true, valueFormatter: numberFormatter },
    { headerName: 'Lot Size', field: 'lotSquareFeet', width: 80, suppressSizeToFit: true, valueFormatter: numberFormatter },
    { headerName: 'Property Type', field: 'landUse', width: 120, valueFormatter: properCaseFormatter },
    { headerName: 'Owner Names', field: 'owner1FullName', width: 120, valueFormatter: ownerNameFormatter },
    { headerName: 'Ownership Length', field: 'ownershipLength', width: 120, suppressSizeToFit: true, valueFormatter: yearMonthFormatter },
    { headerName: 'Current Loans', field: 'openMortgageBalance', width: 100, suppressSizeToFit: true, valueFormatter: currencyFormatter },
    { headerName: 'Est. Value', field: 'estimatedValue', width: 85, suppressSizeToFit: true, valueFormatter: currencyFormatter },
    { headerName: 'Occupancy', field: 'vacant', width: 70, suppressSizeToFit: true, valueFormatter: vacantFormatter },
  ],
  frameworkComponents: {
    indexCellRenderer: IndexCellRenderer,
    indexHeaderRenderer: IndexHeaderRenderer,
    addressLinkCellRenderer: AddressLinkCellRenderer,
    galleryCellRenderer: GalleryCellRenderer,
  },
};

export class PropertyDetail extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePrint = this.handlePrint.bind(this);
    this.handleGridReady = this.handleGridReady.bind(this);
    this.handleDownloadData = this.handleDownloadData.bind(this);
    this.handleLoanApplicationClick = this.handleLoanApplicationClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleOpenGallery = this.handleOpenGallery.bind(this);
    this.handleSaveLinkedList = this.handleSaveLinkedList.bind(this);
    this.handlePurchaseDocument = this.handlePurchaseDocument.bind(this);
    this.handleRehabCalculator = this.handleRehabCalculator.bind(this);
    this.handleOpenCompsTab = this.handleOpenCompsTab.bind(this);
    this.handlePSInfo = this.handlePSInfo.bind(this);
    this.closeDetailPopup = this.closeDetailPopup.bind(this);
    this.handleGetPropertyDetails = this.handleGetPropertyDetails.bind(this);
    if (!pageMap && props.pages.size) pageMap = props.pages.toJS().reduce((m, p) => ({ ...m, [p.code]: p }), {});

    this.state = {
      selectedTabIndex: 0,
      currentPropertyId: null,
      tabs: [],
      taxPage: { sections: [] },
      propertyDetails: null,  // intelligence score property details
      isPropExpanded: false,  // Flag for propperty condition expand
      isExpanded: false,      // Flag for foreclosure factor expand
      brandName: getBrandCode() === 'propstream' ? 'PropStream' : 'Property',
    };

    this.linkedPropertyGridProps = {
      ...LinkedPropertySubjectGridProps,
      onGridReady: this.handleGridReady,
      columnDefs: [
        ...fixedColumns,
        ...LinkedPropertySubjectGridProps.columnDefs.map(col => ({
          ...col,
          flex: 1,
          cellStyle: { 'white-space': 'nowrap', 'overflow': 'hidden' },
        })),
      ],
      context: {
        getSurroundingProps: () => ({
          ...this.props,
          context: SurroundingPropertyContexts.COMPARABLES,
          typeCode: 'LINKED',
          type: this.props.linkedType,
        }),
      },
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  togglePropDropdown = () => {
    this.setState((prevState) => ({
      isPropExpanded: !prevState.isPropExpanded,
    }));
  };


  componentWillMount() {
    const { loadProperty, match: { params: { id } }, property } = this.props;
    if (Number(id) !== property.get('id')) loadProperty(id);
    this.loadProperty(this.props);
    this.handleGetPropertyDetails();
  }

  componentWillReceiveProps(props) {
    this.loadProperty(props);
  }

  componentDidUpdate() {
    handleSurroundingTypeChange(this, this.props.linkedType);
  }

  handleGetPropertyDetails() {
    const urlPath = window.location.pathname;
    const parts = urlPath.split('/');
    const propertyId = parts[parts.length - 1];

    const url = `${INTELLIGENCE_URL}?property_id=${propertyId}`;

    fetch(url, {
      method: 'GET'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse JSON directly
      })
      .then(jsonData => {
        if (jsonData) {
          this.setState({ propertyDetails: jsonData }); // Update state with fetched data
        } else {
          console.error('Empty response received');
        }
      })
      .catch(error => {
        console.error('Error fetching property details:', error);
      });
  }


  loadProperty(props) {
    const { property, pages } = props;
    const currentPropertyId = property.get('id');

    if (!currentPropertyId) this.setState({ currentPropertyId: null, tabs: [{ name: 'Property Details', sections: [] }] });
    else if (pages.size && currentPropertyId !== this.state.currentPropertyId) {
      const taxPage = getPage(taxPageFields, property);
      if (!taxPage.sections.length) taxPage.sections.push({ fields: [] });
      taxPage.sections[0].name = 'Tax Details';

      this.setState({
        currentPropertyId,
        taxPage,
        tabs: pages.filter(p => !'LAST_SALE,LAST_TRANSFER'.includes(p.get('code'))).map(p => p.get('code')).map(code => getPage(pageMap[code], pageFields[code] ? property.get(pageFields[code]) : property)).filter(tab => tab.sections.length),
      });
    }
  }

  handleGridReady({ api }) {
    this.gridApi = api;
    this.dataVersion = 0;
    this.selectionVersion = 0;

    handleSurroundingTypeChange(this, this.props.linkedType);
  }

  handlePrint() {
    const { downloadReport, propertyId, checkSavedProperty } = this.props;

    checkSavedProperty(() => downloadReport(propertyId, Reports.comparativeMarketAnalysis));
  }

  handleDownloadData() {
    const { downloadPropertyData, propertyId } = this.props;
    downloadPropertyData(propertyId);
  }

  handleTabSelect(selectedTabIndex) {
    this.setState({ selectedTabIndex }, () => {
      // Perform tab initialization on first load. (Will need to run this somewhere else if first tab ever needs to init, but so far it's only MLS.)
      const tab = this.state.tabs?.get(selectedTabIndex);
      if (tab && !tab.initialized && tab.code === 'MLS2') {
        tab.initialized = true;

        const listingKey = this.props.property.getIn(['listhubMlsListing', 'listingKey']);
        if (listingKey && window.listHub) window.listHub('submit', 'DETAIL_PAGE_VIEWED', { lkey: listingKey });
      }
    });
  }

  handleOpenGallery() {
    const { property, openPropertyGallery } = this.props;
    openPropertyGallery({ id: property.get('id') });
  }

  handleLoanApplicationClick() {
    const { openLoanApplication, property } = this.props;

    openLoanApplication({ property });
  }

  handleSaveLinkedList() {
    const { lists, openAddToGroup, profile, linkedType } = this.props;

    openAddToGroup({
      selectLoading,
      groups: lists,
      addPropertyMode: true,
      size: linkedType.get('selection').size,
      limit: profile.get('marketingListAddLimit'),
      groupType: 'Marketing List',
      add: (name, onComplete) => this.saveLinkedProperties(PropertyGroupTypes.MARKETING, name, onComplete),
    });
  }

  saveLinkedProperties(groupType, groupName, onComplete) {
    const { linkedType, saveProperties } = this.props;
    const ids = linkedType.get('selection').map(p => [p.get('id')]).toJS();

    saveProperties(ids, groupName, groupType, null, ({ response: { quantity } }) => onComplete(quantity));
  }

  handlePurchaseDocument(transactions) {
    const { confirm, alert, report, checkSavedProperty, purchaseReportAndLoad } = this.props;
    if (transactions && transactions.length) {
      const { id, internalId } = transactions[0];
      checkSavedProperty(() => {
        confirm({
          question: `This report costs ${numberToPrice(report.get('price'))}. Click "Purchase" to accept the charge. 
           If there is not a document available, you will not be charged.`,
          onOk: () => purchaseReportAndLoad(this.props.savedPropertyId, report.get('code'), report.get('price'), internalId, internalId ? null : id.substr(1), () => alert('Purchase successful! You can retrieve this report in the future via the Documents & Reports tab.')),
          okLabel: 'Purchase',
        });
      });
    }
  }

  handleRehabCalculator() {
    const { checkSavedProperty } = this.props;
    const buttonElement = document.getElementById('rehab-calculator');
    if (buttonElement) {
      buttonElement.click();
    }
  }

  handleOpenCompsTab() {
    const openCompsTabIndex = this.state.tabs?.size;
    this.handleTabSelect(openCompsTabIndex);
  }

  handlePSInfo() {
    const openIntelligenceIndex = this.state.tabs?.size + 1;
    this.handleTabSelect(openIntelligenceIndex);
  }

  closeDetailPopup() {
    this.props.closePopup();
    this.props.history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/")));
  }

  getAduPath() {
    return location.pathname + '/adu';
  }

  determinePercentage = (value) => {
    const valueToFillMap = {
      1.0: 100, 1.1: 99, 1.2: 98, 1.3: 97, 1.4: 95, 1.5: 93, 1.6: 91, 1.7: 89, 1.8: 87, 1.9: 85,
      2.0: 83, 2.1: 81, 2.2: 79, 2.3: 77, 2.4: 75, 2.5: 73, 2.6: 71, 2.7: 69, 2.8: 67, 2.9: 65,
      3.0: 63, 3.1: 61, 3.2: 59, 3.3: 57, 3.4: 55, 3.5: 53, 3.6: 51, 3.7: 49, 3.8: 47, 3.9: 45,
      4.0: 43, 4.1: 41, 4.2: 39, 4.3: 37, 4.4: 35, 4.5: 33, 4.6: 31, 4.7: 29, 4.8: 27, 4.9: 25,
      5.0: 23, 5.1: 21, 5.2: 19, 5.3: 17, 5.4: 15, 5.5: 13, 5.6: 10, 5.7: 8, 5.8: 4, 5.9: 2, 6.0: 0
    };
    return valueToFillMap[value] || 0;
  }


  render() {
    const { closePopup, documentLoading, loading, saveProperty, match: { params }, location, currentRoute, property, profile, propertySaved, propertyId, savedPropertyId, checkSavedProperty, tags, users, statuses, linkedType } = this.props;
    const { selectedTabIndex, tabs, taxPage, propertyDetails, isExpanded, isPropExpanded, brandName } = this.state;
    const btn = { loading };
    const imageUrls = property.has('imageUrls') ? property.get('imageUrls').split(',') : [property.get('mapUrl')];
    const tabProps = { property, propertyId, savedPropertyId, checkSavedProperty, saveProperty };
    const transactions = property.get('transactions', List()).toJS();
    const loans = property.get('activeLoans', List()).toJS();
    const oneYearValue = (property.getIn(['estimatedValueGraph', 'points'], List()).find(v => v.get('label') === '1yr') || Map()).get('value');
    const lastSale = property.get('lastSale', Map()).toJS();
    const ownerProperty = property.get('ownerProperty');
    const { statusId, note, assignedTags, assignedUsers } = property.get('status', Map()).toJS();
    const linkedProperties = linkedType.get('properties').toJS();
    const prop = property.toJS();
    const members = (assignedUsers || []).map(id => users.find(r => r.get('id') === id)).filter(u => !!u);

    const percentage = propertyDetails ? this.determinePercentage(propertyDetails.propCondition_score) : 0;

    const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };

    const chunkedData = chunkArray(data, 3);
    const chunkedForeclosureData = chunkArray(foreclosureData, 3);

    const shouldHidePanel = !propertyDetails || [
      'propCondition',
      'interior',
      'exterior',
      'kitchen',
      'bathroom',
      'foreclosureFactor',
      'foreclosureFactorScore'
    ].every(field => !propertyDetails[field]);

    const linkedTotals = linkedProperties.reduce(({ quantity, openMortgageBalance, estimatedValue, estimatedEquity }, p) => ({
      quantity,
      openMortgageBalance: openMortgageBalance + (p.openMortgageBalance || 0),
      estimatedValue: estimatedValue + (p.estimatedValue || 0),
      estimatedEquity: estimatedEquity + ((p.estimatedValue || 0) - (p.openMortgageBalance || 0)),
    }), { quantity: linkedProperties.length, openMortgageBalance: 0, estimatedValue: 0, estimatedEquity: 0 });
    const ndaLabel = isNDAState(property.get('address').get('stateCode')) ? 'Est.' : '';
    return (
      <Modal
        isOpen
        onClose={this.closeDetailPopup}
        isCloseButton
        background="#F3F5F8"
        uniqId="propertyDetail"
        width="1340px"
        caption={(
          <div className={css.header}>
            <div className={css.headerTitle}>{formatFullAddressObject(property.get('address'), true)}</div>
            <div className={css.buttons}>
              <div className={css.dropDownToggle}>
                <DropdownToggle
                  buttonLabel={<div className={css.toolsButton}>
                    <img src={ToolsIcon} className={css.toolsIcon} />
                    Tools
                    {/* <span className={css.buttonLabel}>NEW</span> */}
                    <SVG icon="iconCaretDown" className={css.iconCaretDown} /></div>}
                >
                  <div className={css.dropdownList}>
                    <RestrictedContent permission={Permissions.rehabView}>
                      <a className={`${css.dropdownItem} ${css.topBorderItem}`} onClick={this.handleRehabCalculator}> Rehab Calculator </a>
                    </RestrictedContent>
                    {!isReiAutomator() ? null : <Link className={css.dropdownItem} to={`${location.pathname}/offer`}>Offer Automator</Link>}
                    <Link to={this.getAduPath()} className={`${css.dropdownItem} ${css.botBorderItem}`}>ADU Calculator</Link>
                  </div>
                </DropdownToggle>
              </div>
              <div className={css.panelButtons} style={{ display: 'none' }}>
                <MyBluebookButton savedPropertyId={savedPropertyId} checkSavedProperty={checkSavedProperty} />
              </div>
              {isElite() ? null : <Tags />}
              <Button {...btn} loading={documentLoading} onClick={this.handlePrint}>Print</Button>
              <AnalysisButton propertyId={propertyId} width="115px" params={params} location={location} />
              <Button {...btn} onClick={() => saveProperty()} disabled={propertySaved}>{propertySaved ? 'Saved' : 'Save'}</Button>
            </div>
          </div>
        )}
      >
        <div className={css.propertyDetail}>
          <div className={css.rowPanels}>
            <Panel loading={loading}>
              <div className={css.propertyInfo}>
                <div className={css.left}>
                  <div className={css.image} onClick={this.handleOpenGallery}>
                    <div className={css.pic} style={{ backgroundImage: `url('${imageUrls[0]}')` }} />
                    {imageUrls.length === 1 ? null : <div className={css.circle}>+{imageUrls.length - 1}</div>}
                  </div>
                  <div className={`${css.items} ${css.font600}`}>
                    <div className={`${css.item} ${css.bedBathItem}`}>
                      <div className={css.label}>
                        <img src={BedIcon} alt="Bed" />
                        <div className={css.span}>
                          <span>Beds</span>
                          <span>{property.get('bedrooms')}</span>
                        </div>
                      </div>
                      <div className={css.label}>
                        <img src={BathIcon} alt="Bath" />
                        <div className={css.span}>
                          <span>Baths</span>
                          <span>{property.get('bathrooms')}</span>
                        </div>
                      </div>
                    </div>
                    {writeFields(property, LEFT_FIELDS)}
                  </div>
                </div>
                <div className={classNames(css.right, css.items, css.font600)}>{writeFields(property, RIGHT_FIELDS)}</div>
              </div>
            </Panel>
            <Panel loading={loading}>
              <div className={css.valueGrid}>
                <div className={css.valueSection}>
                  <div className={css.label}>Value</div>
                  <div className={css.values}>
                    <ValueCell name="Estimated Value" value={numberToPrice(property.get('estimatedValue'))} />
                    <ValueCell name={`Last Year (${formatDateYearMonth(moment().subtract(1, 'Y'))})`} value={numberToPrice(oneYearValue)} />
                  </div>
                  <div className={css.graph}>
                    <Chart yTickFormat={Chart.yTickFormat.currency} chartWidth={282} chartHeight={225} graph={property.get('estimatedValueGraph')} tickCount={3} isHighlightedLastDot />
                  </div>
                </div>
              </div>
            </Panel>
            <Panel loading={loading}>
              <div className={css.valueGrid}>
                <div className={css.valueSection}>
                  {writeValues('Mortgage & Debt ', property, MORTGAGE_FIELDS)}
                  <div className={css.subLabel}>Last Sale</div>
                  <div className={css.values}>
                    <ValueCell name="Public Record" value={gridValue(lastSale, 'saleAmount')?.value ? `${currencyFormatter(gridValue(lastSale, 'saleAmount'))} ${ndaLabel}` : ''} description={dateFormatter(gridValue(lastSale, 'saleDate'))} />
                    <ValueCell name="MLS" value={currencyFormatter(gridValue(prop, 'mlsLastSaleAmount'))} description={dateFormatter(gridValue(prop, 'mlsLastSaleDate'))} />
                    <ValueCell addClass={`${css.flexBasis100} ${css.singleCell}`} name="Document Type" value={transactions && transactions.length ? trimAndAddEllipsis(transactions[0].documentType, 25) : 'N/A'} />
                  </div>
                </div>
                <button className={css.cardsButton} onClick={() => this.handlePurchaseDocument(transactions)}>Purchase Document</button>
              </div>
            </Panel>
            <Panel loading={loading}>
              <div className={css.valueGrid}>
                <div className={css.valueSection}>
                  <div className={css.label}>Opportunity</div>
                  <div className={css.circularBarDiv}>
                    <CircularProgressBar percent={property.get('equityPercentage')} radius={29} strokeWidth={7} />
                    <div>
                      <div className={css.equityTxt}>Equity (est.)</div>
                      <ul className={css.barData}>
                        <li>{isNaN(property.get('estimatedEquity')) ? 'N/A' : numberToPrice(property.get('estimatedEquity'))}</li>
                        <li>{isNaN(property.get('equityPercentage')) ? 'N/A' : `${property.get('equityPercentage')}%`}</li>
                        <li>{property.get('dynamicWord') || 'N/A'}</li>
                      </ul>
                      {/* <ProgressBar percent={property.get('equityPercentage')} />*/}
                    </div>
                  </div>
                  <div className={css.values}>
                    <ValueCell
                      addClass={`${css.flexBasis100} ${css.singleCell}`}
                      name="Linked Properties"
                      value={numberFormat((property.get('linkedProperties') || List()).size)}
                    />
                    <ValueCell
                      addClass={`${css.flexBasis100} ${css.singleCell}`}
                      name="Est. Wholesale Price"
                      value={numberToPrice(property.get('estimatedValue')*0.7)}
                    />
                  </div>
                  <div className={`${css.valuesWithLightBg} ${css.values}`}>
                    <ValueCell name="Monthly Rent" value={numberToPrice(property.get('rentAmount'))} />
                    <ValueCell
                      name="Gross yield"
                      value={percentFormat(Math.round(property.get('grossYield') * 10000) / 100)}
                    />
                  </div>
                  <div className={css.subLabel}>Comps at a Glance</div>
                  <div className={css.values}>
                    <ValueCell name="Avg. Sale Price" value={numberToPrice(property.get('compSaleAmount'))} />
                    <ValueCell
                      name="Days on Market"
                      value={property.get('compDaysOnMarket')}
                    />
                  </div>
                </div>

                <button className={css.cardsButton} onClick={this.handleOpenCompsTab}>Open Comps</button>
              </div>
            </Panel>
          </div>
          {/* Propstream Intelligence Tab */}
          {shouldHidePanel ? null : (
            <div className={css.intelligencePanel}>
              {propertyDetails ? (
                <Panel loading={loading}>
                  <div className={css.intelligencePanelDiv}>
                    <div className={css.intelligenceIconDiv}>
                      <div className={css.intelligenceImg}>
                        <img src={IntelligenceIconMedium} alt="Intelligence" />
                      </div>
                      <div>
                        <p className={css.intelligenceTitleTop}>{brandName}</p>
                        <p className={css.intelligenceTitleBottom}>Intelligence</p>
                      </div>
                    </div>
                    <div className={css.intelligenceDetaials}>
                      <div className={css.intelligenceInfo}>
                        <p className={css.intelligenceInfoText}>Property <span className={css.intelligenceInfoIcon} onClick={this.handlePSInfo}>i</span></p>
                        <p className={css.intelligenceInfoText}>Condition</p>
                      </div>
                      <hr className={css.intelligenceInfoHr} />
                      <IntelligenceValueCell name="Total Condition" value={propertyDetails.propCondition} icon={WholeHome} score={propertyDetails.propCondition_score} />
                      <hr className={css.intelligenceInfoHr} />
                      <IntelligenceValueCell name="Exterior" value={propertyDetails.exterior} icon={Exterior} score={propertyDetails.exterior_score} />
                      <hr className={css.intelligenceInfoHr} />
                      <IntelligenceValueCell name="Interior" value={propertyDetails.interior} icon={Interior} score={propertyDetails.interior_score} />
                      <hr className={css.intelligenceInfoHr} />
                      <IntelligenceValueCell name="Kitchen" value={propertyDetails.kitchen} icon={Kitchen} score={propertyDetails.kitchen_score} />
                      <hr className={css.intelligenceInfoHr} />
                      <IntelligenceValueCell name="Bathroom" value={propertyDetails.bathroom} icon={BathIcon} score={propertyDetails.bathroom_score} />
                    </div>
                    <div className={css.intelligenceDetaials}>
                      <IntelligenceValueCell name="Foreclosure Factor" value={propertyDetails.foreclosureFactor} icon={ForeclosureFactore} score_percent={propertyDetails.foreclosureFactorScore} />
                    </div>
                  </div>
                </Panel>
              ) : (
                <Panel loading={true}></Panel>
              )}
            </div>
          )}
          {!(statusId || note || assignedTags || assignedUsers) ? null : (
            <div className={css.status}>
              <div className={classNames(css.statusSide, css.statusLeft)}>
                {!assignedTags && !statusId ? null : (
                  <Panel className={classNames(css.blueOutline, { [css.hidden]: !assignedTags }, css.statusTag)}>
                    <span className={css.statusLabel}>Property Tags: </span>
                    <span className={css.statusValue}>{assignedTags && assignedTags.reduce((tagList, id) => `${tagList}${tagList === '' ? '' : ', '}${(tags.find(g => g.get('id') === id) || Map()).get('name')}`, '')}</span>
                  </Panel>
                )}
                {!note && !assignedUsers ? null : (
                  <Panel className={classNames(css.blue, { [css.hidden]: !note }, css.statusNote)}>
                    <div className={css.statusLabel}>Note:</div>
                    {note}
                  </Panel>
                )}
              </div>
              {!statusId && !assignedUsers ? null : (
                <div className={classNames(css.statusSide, css.statusRight)}>
                  {!statusId && !assignedTags ? null : (
                    <Panel className={classNames(css.blueOutline, { [css.hidden]: !statusId })}>
                      <span className={css.statusLabel}>Status: </span>
                      {(statuses.find(s => s.get('id') === statusId) || Map()).get('name')}
                    </Panel>
                  )}
                  {!assignedUsers && !note ? null : (
                    <Panel className={classNames(css.blue, { [css.hidden]: !assignedUsers })}>
                      <div className={css.statusLabel}>Assigned Team Members:</div>
                      {members.map((u) => {
                        const id = u.get('id');
                        const type = UserTypeMap[u.get('type')] || UserTypeMap.FULL;

                        return (
                          <div className={css.member} key={id}>
                            <div>
                              <UserLogo id={u.get('id')} size={28} borderColor={type.colorDark} />
                              {u.get('name')}
                            </div>
                            <div>
                              <div className={css.memberTypeIcon} style={{ borderColor: type.colorDark, color: type.colorDark, backgroundColor: type.colorLight }}>{`${u.get('firstName', '').trim().substr(0, 1)}${u.get('lastName', '').trim().substr(0, 1)}`.toUpperCase()}</div>
                              <div>{type.label}</div>
                            </div>
                          </div>
                        );
                      })}
                    </Panel>
                  )}
                </div>
              )}
            </div>
          )}
          <div className={css.tabs}>
            <CustomTabs kind="line">
              <Tabs selectedIndex={selectedTabIndex} onSelect={this.handleTabSelect}>
                <TabList>
                  {tabs.map(({ name }) => (
                    <Tab key={name}>
                      <div className={css.tab}>
                        {name}
                      </div>
                    </Tab>
                  ))}
                  <Tab>
                    <div className={css.tab}>
                      Comparables &amp; Nearby Listings
                    </div>
                  </Tab>
                  {/* Intelligence Tab Header */}
                  {!shouldHidePanel && (
                    <Tab>
                      <div className={css.tab}>
                        <span className={css.intelliTab}>
                          <img src={IntelligenceIconSmall} alt="Intelligence" className={css.inteliiIcon} />Intelligence
                        </span>
                      </div>
                    </Tab>
                  )}
                  <Tab>
                    <div className={css.tab}>
                      Demographics
                    </div>
                  </Tab>
                  <Tab><div className={css.tab}>Tax Information</div></Tab>
                  {!linkedProperties.length ? null : <Tab><div className={css.tab}>Linked Properties</div></Tab>}
                  {!transactions.length ? null : <Tab><div className={css.tab}>Mortgage &amp; Transaction History</div></Tab>}
                  <Tab><div className={css.tab}>Documents & Reports</div></Tab>
                </TabList>
                {tabs.map(({ name, sections }) => (
                  <TabPanel key={name}>
                    {writeSections(property, sections)}
                  </TabPanel>
                ))}
                <TabPanel>
                  <PropertyComparables {...tabProps} />
                </TabPanel>
                {/* Intelligence Tab Content */}
                {!shouldHidePanel && (
                  <TabPanel>
                    {propertyDetails ? (
                      <div>
                        <Panel>
                          <h3 className={css.intelliHeader}>
                            About {brandName} Intelligence
                          </h3>
                          <p className={css.intelliHeaderInfo}>
                            {brandName} Intelligence leverages AI data and predictive analytics to assess and analyze properties to help enhance your decision-making process. Look for the {brandName} Intelligence' <span><img src={IntelligenceIconSmall} alt="Intelligence" className={css.inteliiIcon} /></span>
                            icon to know you are working with intelligent data curated by AI to gain more leads and refine your search process.
                          </p>
                        </Panel>
                        <Panel className={css.intelliInfoSection}>
                          <div className={css.propConditionSection}>
                            <div>
                              <div className={css.propConditionTitle}>
                                <div> <img src={IntelligenceIconMedium} alt="Intelligence" /></div>
                                <div className={css.propConditionHeader}>Property Condition</div>
                              </div>
                              <div className={css.propConditionRating}>
                                <div className={css.propRatingHeader}>{propertyDetails.propCondition ? propertyDetails.propCondition : "N/A"}</div>
                                <div><IntelliProgressBar percent={percentage} size={'big'} /></div>
                              </div>
                            </div>
                            <div className={css.propSummary}>
                              Property condition is calculated using an AI algorithm that analyzes MLS photo data to estimate the overall quality of the property and its specific features (exterior, interior, kitchen, bathroom). The grading scale ranges from Disrepair (the lowest quality grade) to Luxury (the highest quality grade).
                            </div>
                          </div>
                          <hr />
                          <div>
                            <div className={css.intelliCol}>
                              <div className={css.intelliRow}>
                                <IntelligenceInfoCell name="Exterior Condition" value={propertyDetails.exterior} icon={Exterior} score={propertyDetails.exterior_score} />
                              </div>
                              <hr className={css.intelligenceInfoHr} />
                              <div className={css.intelliRow}>
                                <IntelligenceInfoCell name="Interior Condition" value={propertyDetails.interior} icon={Interior} score={propertyDetails.interior_score} />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <div className={css.intelliCol}>
                              <div className={css.intelliRow}>
                                <IntelligenceInfoCell name="Kitchen Condition" value={propertyDetails.kitchen} icon={Kitchen} score={propertyDetails.kitchen_score} />
                              </div>
                              <hr className={css.intelligenceInfoHr} />
                              <div className={css.intelliRow}>
                                <IntelligenceInfoCell name="Bathroom Condition" value={propertyDetails.bathroom} icon={BathIcon} score={propertyDetails.bathroom_score} />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <div className={css.intelliCol}>
                              <div className={css.intelliExpandRow}>
                                <div className={css.intelliExpandText}>How To Interpret This Data</div>
                                <div>
                                  <div
                                    onClick={this.togglePropDropdown}
                                    className={css.dropdownToggleBtn}
                                  >
                                    {isPropExpanded ? "Collapse" : "Expand"}
                                    <SVG
                                      icon={isPropExpanded ? "iconCaretUp" : "iconCaretDown"}
                                      className={css.dropdownCaretIcon}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isPropExpanded && chunkedData.map((chunk, index) => (
                            <div key={index} className={css.wordScoreSection}>
                              {chunk.map((item, idx) => (
                                <div key={idx} className={css.wordScoreCard}>
                                  <div className={css.wordScoreCardTop}>
                                    <div className={css.wordScoreLabel}>{item.label}</div>
                                    <div className={css.wordScoreBar}>
                                      <IntelliProgressBar percent={item.score} size={'medium'} />
                                    </div>
                                  </div>
                                  <div className={css.wordScorePoints}>
                                    <div className={css.custom_list}>
                                      <div className={css.custom_list_item}>{item.description.components}</div>
                                      <div className={css.custom_list_item}>{item.description.quality}</div>
                                      <div className={css.custom_list_item}>{item.description.rooms}</div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </Panel>
                        <Panel className={css.intelliInfoSection}>
                          <div className={css.propConditionSection}>
                            <div>
                              <div className={css.propConditionTitle}>
                                <div> <img src={IntelligenceIconMedium} alt="Intelligence" /></div>
                                <div className={css.propConditionHeader}>Foreclosure Factor</div>
                              </div>
                              <div className={css.propConditionRating}>
                                <div className={css.propRatingHeader}>{propertyDetails.foreclosureFactor || 'N/A'}</div>
                                <hr className={css.intelligenceDivHr} />
                                <div className={css.propRatingHeader}>{propertyDetails?.foreclosureFactorScore ?`${propertyDetails.foreclosureFactorScore}%`: 'N/A'}</div>
                                <div><IntelliProgressBar percent={propertyDetails.foreclosureFactorScore} size={'big'} /></div>
                              </div>
                            </div>
                            <div className={css.propSummary}>
                              Foreclosure Factor utilizes a variety of data and Al-powered algorithms to predict a property's likelihood of going into default. The grading scale ranges from Very Low to Very High (on a scale from 1-100); the higher the score, the more likely the property will default within the next 12 months.
                            </div>
                          </div>
                          <hr />
                          <div>
                            <div className={css.intelliCol}>
                              <div className={css.intelliExpandRow}>
                                <div className={css.intelliExpandText}>How To Interpret This Data</div>
                                <div>
                                  <div
                                    onClick={this.toggleDropdown}
                                    className={css.dropdownToggleBtn}
                                  >
                                    {isExpanded ? "Collapse" : "Expand"}
                                    <SVG
                                      icon={isExpanded ? "iconCaretUp" : "iconCaretDown"}
                                      className={css.dropdownCaretIcon}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isExpanded && (
                            <div className={css.foreclosureSummary}>
                              The propensity to default (pre-foreclosure), is the likelihood that a homeowner will fall behind on their mortgage payments based on several factors (without personal identifying information - PII) including over 200 tax return line items, sources of income, and thousands of other data points. The propensity model also predicts the probability of default in the next 12 months based on elements such as home values, loan structure, and homeowner behavior.
                            </div>
                          )}
                          {isExpanded && chunkedForeclosureData.map((chunk, index) => (
                            <div key={index} className={css.wordScoreSection}>
                              {chunk.map((item, idx) => (
                                <div key={idx} className={css.wordScoreCard}>
                                  <div className={css.wordScoreCardTop}>
                                    <div className={css.wordScoreLabel}>{item.label}</div>
                                    <div className={css.wordScoreBar}>
                                      <IntelliProgressBar percent={item.score} size={'medium'} />
                                    </div>
                                  </div>
                                  <div className={css.wordScorePoints}>
                                    <div className={css.custom_list}>
                                      {item.description.components && (
                                        <div className={css.custom_list_item}>{item.description.components}</div>
                                      )}
                                      {item.description.quality && (
                                        <div className={css.custom_list_item}>{item.description.quality}</div>
                                      )}
                                      {item.description.rooms && (
                                        <div className={css.custom_list_item}>{item.description.rooms}</div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </Panel>
                      </div>
                    ) : (
                      <Panel loading={true}></Panel>
                    )}
                  </TabPanel>
                )}
                <TabPanel>
                  <Demographics
                    property={property}
                    profile={profile}
                  />
                </TabPanel>
                <TabPanel className={css.tax}>
                  <Panel>
                    {writeSections(property, taxPage.sections)}
                    {writeTable(property.get('taxes'), TAX_COLUMNS, 'type_7')}
                  </Panel>
                </TabPanel>
                {!linkedProperties.length ? null : (
                  <TabPanel>
                    <div className={css.linkedProperties}>
                      <Panel>
                        <div className={css.summary}>
                          {LinkedStats.map((s, i) => (
                            <div className={css.summaryItem}>
                              <div className={css.summaryValue}>{(!i ? v => v : numberToPrice)(linkedTotals[s.field])}</div>
                              <div className={css.summaryLabel}>{s.label}</div>
                            </div>
                          ))}
                        </div>
                      </Panel>
                      {!ownerProperty ? null : (
                        <Panel>
                          <div className={css.heading}>Primary Property</div>
                          <div className="ag-theme-balham" style={{ height: 100 }}>
                            <AgGridReact {...LinkedPropertySubjectGridProps} rowData={[ownerProperty.toJS()]} />
                          </div>
                        </Panel>
                      )}
                      <Panel>
                        <div className={css.heading}>
                          <div>Current Linked Properties</div>
                          <Button {...btn} onClick={this.handleSaveLinkedList} disabled={!linkedType.get('selection').size}>Add to List</Button>
                        </div>
                        <div className="ag-theme-balham" style={{ height: `${Math.min(350, 120 + (linkedProperties.length * 50))}px` }}>
                          <AgGridReact {...this.linkedPropertyGridProps} />
                        </div>
                      </Panel>
                    </div>
                  </TabPanel>
                )}
                {!transactions.length ? null : (
                  <TabPanel>
                    {!loans.length ? null : (
                      <Panel>
                        <div className={css.heading}>Current Mortgages</div>
                        <div className="ag-theme-balham">
                          <AgGridReact {...MortgageGridProps} rowData={loans} />
                        </div>
                      </Panel>
                    )}
                    <Panel>
                      <div className={css.heading}>Transaction &amp; Mortgage History</div>
                      <div className={classNames('ag-theme-balham', css.transactions)}>
                        <AgGridReact {...TransactionGridProps(this.props)} rowData={transactions} />
                      </div>
                    </Panel>
                  </TabPanel>
                )}
                <TabPanel>
                  <PropertyDocuments {...tabProps} />
                </TabPanel>
              </Tabs>
            </CustomTabs>
          </div>
        </div>
      </Modal>
    );
  }
}

const PropertyDetailPopup = withProperty(PropertyDetail, state => ({
  documentLoading: selectDocumentLoading(state),
  pages: selectPropertyDetailLayouts(state),
  tags: selectTags(state),
  statuses: selectStatuses(state),
  users: selectUsers(state),
  profile: selectProfile(state),
  report: selectPremiumReports(state).find(r => r.get('code') === 'DOCUMENT_IMAGE'),
  linkedType: selectSurroundingPropertyType(state, SurroundingPropertyContexts.COMPARABLES, 'LINKED'),
  lists: selectOriginalLists(state),
}), {
  downloadReport,
  downloadPropertyData,
  selectLoading,
  saveProperties,
  updateSurroundingPropertySelection,
  openPropertyGallery: PropertyGallery.open,
  openLoanApplication: LoanApplication.open,
  openAddToGroup: AddToGroup.open,
  purchaseReportAndLoad,
  confirm: Confirm.open,
  alert: Confirm.alert,
});

const registrationId = getPopupRegistration(PropertyDetailPopup);

PropertyDetailPopup.open = props => openPopup(registrationId, props);
PropertyDetailPopup.close = () => closePopup({ popup: registrationId });

export default PropertyDetailPopup;
