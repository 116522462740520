

import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilterById,
  removeLocalFilter,
  selectNewFilter,
  selectShowAllEditOption,
  updateFilter,
  updateLocalFilter,
  selectLocalFilter
} from "../../../../../../data/search";
import newsearchfilter from "../../SearchFilterNew.scss";
import {
  PSScoreOptions,
  foreclosureFactorOptions,
  fieldTagsMap,
  formatDateShortWithUTC,
  getCategoryFromPCScore
} from "../../shared/ConstantsNew";
import IntelligenceIcon from '../../../../../../assets/images/IntelligenceIcon_Medium.svg';
import { useFilter } from "../../TagFilterContext";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import { getBrandCode } from 'utils/brand';
import moment from "moment";
import { generateMinMaxDateValue, generateMinMaxValue, getMinMaxDateToUpdate, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";


const PropstreamIntelligenceScreen = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);
  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);
  const refs = useRef({});

  const [maxOptionsForFilters, setMaxOptionsForFilters] = useState({
    propCondition: PSScoreOptions,
    bathCondition: PSScoreOptions,
    kitchenCondition: PSScoreOptions,
    intCondition: PSScoreOptions,
    extCondition: PSScoreOptions,
    foreclosureFactor: foreclosureFactorOptions
  });

  const [minOptionsForFilters, setMinOptionsForFilters] = useState({
    propCondition: PSScoreOptions,
    bathCondition: PSScoreOptions,
    kitchenCondition: PSScoreOptions,
    intCondition: PSScoreOptions,
    extCondition: PSScoreOptions,
    foreclosureFactor: foreclosureFactorOptions
  });

  // Define the order of severity order for property condition
  const severityOrderPC = [
    'Disrepair',   // 0
    'Poor',        // 1
    'Average',     // 2
    'Good',        // 3
    'Excellent',   // 4
    'Luxury'       // 5
  ];

  // Define the order of severity order for foreclosure factor
  const severityOrderFC = [
    'Very Low',   // 0
    'Low',        // 1
    'Medium Low',     // 2
    'Medium High',        // 3
    'High',   // 4
    'Very High'       // 5
  ];

  const updateDisabledMaxOptions = (minValue, filterKey) => {

    const minIndex = severityOrderPC.indexOf(minValue);

    const disabledOptions = PSScoreOptions.filter(option => {
      const optionIndex = severityOrderPC.indexOf(option.value);
      // Disable options that are less than or equal to the selected minValue
      return optionIndex < minIndex;
    });

    // Update maxOptions based on the disabled options
    setMaxOptionsForFilters(prevState => ({
      ...prevState,
      [filterKey]: PSScoreOptions.filter(option =>
        !disabledOptions.some(disabledOption => disabledOption.value === option.value)
      )
    }));
  };

  const updateDisabledMinOptions = (maxValue, filterKey) => {

    const maxIndex = severityOrderPC.indexOf(maxValue);

    const disabledOptions = PSScoreOptions.filter(option => {
      const optionIndex = severityOrderPC.indexOf(option.value);
      // Disable options that are less than or equal to the selected minValue
      return optionIndex > maxIndex;
    });

    // Update maxOptions based on the disabled options
    setMinOptionsForFilters(prevState => ({
      ...prevState,
      [filterKey]: PSScoreOptions.filter(option =>
        !disabledOptions.some(disabledOption => disabledOption.value === option.value)
      )
    }));
  };

  const updateDisabledFFMaxOptions = (minValue, filterKey) => {

    const minIndex = severityOrderFC.indexOf(minValue);

    const disabledOptions = foreclosureFactorOptions.filter(option => {
      const optionIndex = severityOrderFC.indexOf(option.value);
      // Disable options that are less than or equal to the selected minValue
      return optionIndex < minIndex;
    });

    // Update maxOptions based on the disabled options
    setMaxOptionsForFilters(prevState => ({
      ...prevState,
      [filterKey]: foreclosureFactorOptions.filter(option =>
        !disabledOptions.some(disabledOption => disabledOption.value === option.value)
      )
    }));
  };

  const updateDisabledFFMinOptions = (maxValue, filterKey) => {

    const maxIndex = severityOrderFC.indexOf(maxValue);
    const disabledOptions = foreclosureFactorOptions.filter(option => {
      const optionIndex = severityOrderFC.indexOf(option.value);
      // Disable options that are less than or equal to the selected minValue
      return optionIndex > maxIndex;
    });

    // Update maxOptions based on the disabled options
    setMinOptionsForFilters(prevState => ({
      ...prevState,
      [filterKey]: foreclosureFactorOptions.filter(option =>
        !disabledOptions.some(disabledOption => disabledOption.value === option.value)
      )
    }));
  };

  const [pi, setIntelligenceScore] = useState({
    propConditionMin: null,
    propConditionMax: null,
    bathConditionMin: null,
    bathConditionMax: null,
    kitchenConditionMin: null,
    kitchenConditionMax: null,
    intConditionMin: null,
    intConditionMax: null,
    extConditionMin: null,
    extConditionMax: null,
    foreclosureFactorMin: null,
    foreclosureFactorMax: null
  });

  const targetKeysPC = [
    'propConditionMin',
    'propConditionMax',
    'bathConditionMin',
    'bathConditionMax',
    'kitchenConditionMin',
    'kitchenConditionMax',
    'intConditionMin',
    'intConditionMax',
    'extConditionMin',
    'extConditionMax'
  ];

  const targetKeysFF = [
    'foreclosureFactorMin',
    'foreclosureFactorMax'
  ];

  const getCategoryFromPCScore = (score) => {
    if (score >= 5.6 && score <= 6.0) return "Disrepair";
    if (score >= 4.6 && score <= 5.5) return "Poor";
    if (score >= 3.6 && score <= 4.5) return "Average";
    if (score >= 2.6 && score <= 3.5) return "Good";
    if (score >= 1.6 && score <= 2.5) return "Excellent";
    if (score >= 1.0 && score <= 1.5) return "Luxury";
    return null; // Return null for scores outside defined ranges
  };

  const getCategoryFromFFScore = (score) => {
    if (score >= 1 && score <= 10) {
      return "Very Low";
    } else if (score >= 11 && score <= 30) {
      return "Low";
    } else if (score >= 31 && score <= 50) {
      return "Medium Low";
    } else if (score >= 51 && score <= 70) {
      return "Medium High";
    } else if (score >= 71 && score <= 90) {
      return "High";
    } else if (score >= 91 && score <= 100) {
      return "Very High";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (filter) {
      const updatedScores = {};

      // Iterate over each key-value pair in the filter object for property conditions
      targetKeysPC.forEach((key) => {
        const value = filter[key];
        updatedScores[key] = getCategoryFromPCScore(value);
      });

      // Iterate over each key-value pair in the filter object for foreclosure factors
      targetKeysFF.forEach((key) => {
        const value = filter[key];
        updatedScores[key] = getCategoryFromFFScore(value);
      });

      setIntelligenceScore(updatedScores);
      setFilterApplied(true);
    }
  }, [filter]);

  useEffect(() => {
    const clearMaxOptionsIfMinCleared = () => {
      let updatedMaxOptions = { ...maxOptionsForFilters };

      Object.keys(pi).forEach(key => {
        const minKey = key.replace("Max", "Min");

        if (pi[minKey] === null || pi[minKey] === "") {
          const filterKey = minKey.replace('Min', '');
          // Reset max options to the full list based on the filter
          updatedMaxOptions[filterKey] = filterKey === 'foreclosureFactor'
            ? foreclosureFactorOptions
            : PSScoreOptions; // Reset to specific options for foreclosureFactor
        }
      });

      setMaxOptionsForFilters(updatedMaxOptions);
    };

    const clearMinOptionsIfMaxCleared = () => {
      let updatedMaxOptions = { ...minOptionsForFilters };

      Object.keys(pi).forEach(key => {
        const maxKey = key.replace("Min", "Max");

        if (pi[maxKey] === null || pi[maxKey] === "") {
          const filterKey = maxKey.replace('Max', '');
          // Reset max options to the full list based on the filter
          updatedMaxOptions[filterKey] = filterKey === 'foreclosureFactor'
            ? foreclosureFactorOptions
            : PSScoreOptions; // Reset to specific options for foreclosureFactor
        }
      });

      setMinOptionsForFilters(updatedMaxOptions);
    };

    clearMaxOptionsIfMinCleared();
    clearMinOptionsIfMaxCleared();
  }, [pi]);

  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnPS();
    }
  }, [isFilterApplied]);

  const getBooleanOption = (value, options) => {
    return options.find(option => option.value === value) || null;
  };

  const applyFiltersBasedOnPS = () => {
    const fieldsToCheck = [
      // Range fields
      { label: "Overall Property Condition", min: pi.propConditionMin, max: pi.propConditionMax, key: "propCondition" },
      { label: "Bathroom Condition", min: pi.bathConditionMin, max: pi.bathConditionMax, key: "bathCondition" },
      { label: "Kitchen Condition", min: pi.kitchenConditionMin, max: pi.kitchenConditionMax, key: "kitchenCondition" },
      { label: "Interior Condition", min: pi.intConditionMin, max: pi.intConditionMax, key: "intCondition" },
      { label: "Exterior Condition", min: pi.extConditionMin, max: pi.extConditionMax, key: "extCondition" },
      { label: "Foreclosure Factor", min: pi.foreclosureFactorMin, max: pi.foreclosureFactorMax, key: "foreclosureFactor" },
    ];

    fieldsToCheck.forEach(({ label, min, max, key, isBoolean, isText, isDate, value, options }) => {
      if (isBoolean) {
        if (value !== null && value !== '' && value !== undefined) {
          // handleBooleanSelectPCNew({value:value}, key, label);
          const option = getBooleanOption(value, options);
          if (option) {
            handleBooleanSelectPCNew(option, key, label);
          } else {
            // Handle the case where no valid option is found
            handleOldRemoveFilter(key);
            handleClearFilter(label);
          }
        }
      }
      else if (isText) {
        if (value !== null && value !== '' && value !== undefined) {
          inputValueChange(value, label, key);
        }
      }
      else if (isDate) {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboDateValueChange(min, max, label, key);
        }
      }
      else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          setLeftFilter(min, max, label, key);
        }
      }
    });
  };

  const getEditOptions = useSelector(selectShowAllEditOption)
  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'pi') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);

  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };

  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    updateFilterOldStore(key, option.value);
    updateFilterStore(option, key, leftLabel);
  };

  const handleSelectAllClick = (selectedOptions, leftValue, key) => {
    let name = "";
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }

      const selectedIds = selectedOptions.map(element => element.id).join(",");
      const fullSeletedNamesList = selectedOptions
        .map(element => element.name)
        .join(",");
      updateFilterOldStore(key, selectedIds);
      // meed to work morning
      updateFilterStore(
        { value: key, label: name },
        key,
        leftValue,
        fullSeletedNamesList
      );
    } else {
      handleOldRemoveFilter(key);
      handleClearFilter(leftValue);
    }
  };

  const inputValueChange = (value, lable, key) => {
    updateFilterOldStore(key, value);
    updateFilterStoreCombox(value, key, lable);
  };

  const getFloatRangeFromCategory = (category) => {
    switch (category) {
      case "Disrepair":
        return { min: 6.0, max: 5.6 };
      case "Poor":
        return { min: 5.5, max: 4.6 };
      case "Average":
        return { min: 4.5, max: 3.6 };
      case "Good":
        return { min: 3.5, max: 2.6 };
      case "Excellent":
        return { min: 2.5, max: 1.6 };
      case "Luxury":
        return { min: 1.5, max: 1.0 };
      default:
        return { min: null, max: null };
    }
  };

  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {

    if (minValue !== null) {
      updateDisabledMaxOptions(minValue, key);
    }

    if (maxValue !== null) {
      updateDisabledMinOptions(maxValue, key);
    }
    const name = generateMinMaxValue(minValue, maxValue);

    const minRange = getFloatRangeFromCategory(minValue);
    const maxRange = getFloatRangeFromCategory(maxValue);

    // Initialize minFloat and maxFloat
    let minFloat = minRange.min;
    let maxFloat = maxRange.max;

    const valuesToUpdate = getMinMaxValuesToUpdate(minFloat, maxFloat, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };


  const setLeftFilter = (minValue, maxValue, leftLabel, key) => {
    let name;
    const isMinValid = minValue != null && minValue !== '';
    const isMaxValid = maxValue != null && maxValue !== '';

    if (!isMaxValid && isMinValid) {
      name = `${minValue} or more`;
    } else if (!isMinValid && isMaxValid) {
      name = `less than ${maxValue}`;
    } else if (isMinValid && isMaxValid) {
      name = `${minValue} to ${maxValue}`;
    } else {
      name = null
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel);
      return
    }
    updateFilterStoreCombox(name, key, leftLabel);
  };

  const getRangeFromFFScoreCategory = (category) => {
    switch (category) {
      case "Very Low":
        return { min: 1, max: 10 };
      case "Low":
        return { min: 11, max: 30 };
      case "Medium Low":
        return { min: 31, max: 50 };
      case "Medium High":
        return { min: 51, max: 70 };
      case "High":
        return { min: 71, max: 90 };
      case "Very High":
        return { min: 91, max: 100 };
      default:
        return { min: null, max: null }; // Return null for invalid categories
    }
  };

  const comboBoxValueChangeForFF = (minValue, maxValue, leftLabel, key) => {
    if (minValue !== null) {
      updateDisabledFFMaxOptions(minValue, key);
    }

    if (maxValue !== null) {
      updateDisabledFFMinOptions(maxValue, key);
    }
    let name;

    const minRange = getRangeFromFFScoreCategory(minValue);
    const maxRange = getRangeFromFFScoreCategory(maxValue);

    // Initialize minFloat and maxFloat
    let minFloat = minRange.min;
    let maxFloat = maxRange.max;

    // Validate the range: min should not exceed max
    if ((minValue && maxValue) && (minFloat > maxFloat)) {
      console.warn("Minimum value exceeds maximum value.");
      name = null;
      handleOldRemoveFilter(key);
      handleClearFilter(leftLabel);
      return; // Early return if invalid range
    }

    // Determine the name based on valid min and max
    if (minValue && !maxValue) {
      name = `${minValue} or more`;
    } else if (!minValue && maxValue) {
      name = `less than ${maxValue}`;
    } else if (minValue && maxValue) {
      name = `${minValue} to ${maxValue}`;
    } else {
      name = null;
      handleOldRemoveFilter(key);
      handleClearFilter(leftLabel);
      return;
    }

    const valuesToUpdate = [
      { key: `${key}Min`, value: minFloat },
      { key: `${key}Max`, value: maxFloat },
    ];

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };

  // combo date
  const comboDateValueChange = (minValue, maxValue, leftLabel, key) => {

    const name = generateMinMaxDateValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxDateToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };

  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };

  //  store
  // store
  const updateFilterOldStore = (key, id) => {
    // if (id) {
    dispatch(updateFilter({ [key]: id }));
    // }
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "pi",
          id: leftLabel,
          name: option.label,
          apiKey: key,
          [key]: option.value,
          removeID: key,
          showALLDisplayValue: fullSeletedNamesList
        }
      ])
    );
    // }
  };

  const updateFilterStoreCombox = (name, key, leftLabel) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "pi", id: leftLabel, name: name, apiKey: key,
          removeID: key,
          showALLDisplayValue: name
        }
      ])
    );
    // }
  };

  const handleClearFilter = id => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = id => {
    dispatch(removeFilterById(id));
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const psFilters = getLocalFilter.filter(filter => filter.key === 'pi');
    return `${newsearchfilter.clsShowOnTag}`;
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  const brandCode = getBrandCode();

  return (
    <div className={classNames(
      getClassNames('Overall Property Condition'),
      getClassNames('Bathroom Condition'),
      getClassNames('Kitchen Condition'),
      getClassNames('Interior Condition'),
      getClassNames('Exterior Condition'),
      getClassNames('Foreclosure Factor')
    )}>
      <div className={newsearchfilter.clsContentBody}>
        <div className={newsearchfilter.headerBg}>
          <h2 className={newsearchfilter.clsHeading}>
            {brandCode === 'propstream' ? 'PropStream Intelligence' : 'Property Intelligence'}
          </h2>
        </div>

        <h2 className={newsearchfilter.clsSubSecHeading}>
          Property Condition
        </h2>

        <div id="propCondition" className={classNames(newsearchfilter.pb_28, getClassNames("Overall Property Condition"), getClassNames("Bathroom Condition"))}
          ref={el => refs.current['Overall Property Condition'] = el}>
          <Row className={newsearchfilter.flexRow}>
            <Col md={6} xs={12} className={getClassNames("Overall Property Condition")}>
              <FilterComboBox
                key={JSON.stringify({
                  minOptions: minOptionsForFilters.propCondition,
                  maxOptions: maxOptionsForFilters.propCondition
                })}
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Overall Property Condition<span style={{ color: '#128292' }}>*</span></div>}
                minOptions={minOptionsForFilters.propCondition}
                maxOptions={maxOptionsForFilters.propCondition}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Overall Property Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Overall Property Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: pi.propConditionMin,
                  max: pi.propConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Overall Property Condition",
                    "propCondition"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Bathroom Condition")}>
              <div className={newsearchfilter.propConditionInfoText}><span style={{ color: '#128292' }}>*</span>Overall Property Condition is an average of the four settings below and setting opposite extremes may cause a dramatic undesired reduction in results.</div>
            </Col>
          </Row>
        </div>
        <div id="bathCondition" className={classNames(newsearchfilter.pb_28, getClassNames("Bathroom Condition"), getClassNames("Kitchen Condition"))}
          ref={el => refs.current['Bathroom Condition'] = el}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Bathroom Condition")}>
              <FilterComboBox
                key={JSON.stringify({
                  minOptions: minOptionsForFilters.bathCondition,
                  maxOptions: maxOptionsForFilters.bathCondition
                })}
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Bathroom Condition</div>}
                minOptions={minOptionsForFilters.bathCondition}
                maxOptions={maxOptionsForFilters.bathCondition}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Bathroom Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Bathroom Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: pi.bathConditionMin,
                  max: pi.bathConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Bathroom Condition",
                    "bathCondition"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Kitchen Condition")}>
              <div id="kitchenCondition" ref={el => refs.current['Kitchen Condition'] = el}></div>
              <FilterComboBox
                key={JSON.stringify({
                  minOptions: minOptionsForFilters.kitchenCondition,
                  maxOptions: maxOptionsForFilters.kitchenCondition
                })}
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Kitchen Condition</div>}
                minOptions={minOptionsForFilters.kitchenCondition}
                maxOptions={maxOptionsForFilters.kitchenCondition}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Kitchen Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Kitchen Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: pi.kitchenConditionMin,
                  max: pi.kitchenConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Kitchen Condition",
                    "kitchenCondition"
                  )
                }
              />
            </Col>
          </Row>
        </div>
        <div id="intCondition" className={classNames(newsearchfilter.pb_28, getClassNames("Interior Condition"), getClassNames("Exterior Condition"))}
          ref={el => refs.current['Interior Condition'] = el}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Interior Condition")}>
              <FilterComboBox
                key={JSON.stringify({
                  minOptions: minOptionsForFilters.intCondition,
                  maxOptions: maxOptionsForFilters.intCondition
                })}
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Interior Condition</div>}
                minOptions={minOptionsForFilters.intCondition}
                maxOptions={maxOptionsForFilters.intCondition}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Interior Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Interior Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: pi.intConditionMin,
                  max: pi.intConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Interior Condition",
                    "intCondition"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Exterior Condition")}>
              <div id="extCondition" ref={el => refs.current['Exterior Condition'] = el}></div>
              <FilterComboBox
                key={JSON.stringify({
                  minOptions: minOptionsForFilters.extCondition,
                  maxOptions: maxOptionsForFilters.extCondition
                })}
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Exterior Condition</div>}
                minOptions={minOptionsForFilters.extCondition}
                maxOptions={maxOptionsForFilters.extCondition}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Exterior Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Exterior Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: pi.extConditionMin,
                  max: pi.extConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Exterior Condition",
                    "extCondition"
                  )
                }
              />
            </Col>
          </Row>
        </div>

        <div
          className={classNames(
            newsearchfilter.clsBorderTop
          )}
        ></div>

        <h2 className={newsearchfilter.clsSubSecHeading}>
          Foreclosure Factor
        </h2>
        <div id="foreclosureFactor" className={classNames(newsearchfilter.pb_28, getClassNames("Foreclosure Factor"))}
          ref={el => refs.current['Foreclosure Factor'] = el}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Foreclosure Factor")}>
              <FilterComboBox
                key={JSON.stringify({
                  minOptions: minOptionsForFilters.foreclosureFactor,
                  maxOptions: maxOptionsForFilters.foreclosureFactor
                })}
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Foreclosure Factor</div>}
                minOptions={minOptionsForFilters.foreclosureFactor}
                maxOptions={maxOptionsForFilters.foreclosureFactor}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Foreclosure Factor'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Foreclosure Factor") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: pi.foreclosureFactorMin,
                  max: pi.foreclosureFactorMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChangeForFF(
                    minValue,
                    maxValue,
                    "Foreclosure Factor",
                    "foreclosureFactor"
                  )
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PropstreamIntelligenceScreen;

