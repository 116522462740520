import React from "react";
import classNames from "classnames";
import { fromJS, List } from "immutable";
import Checkbox from "components/base/Checkbox";
import SVG from "components/base/SVG";
import GroupInfo from "components/GroupInfo";
import Table, { Column } from "components/base/Table";
import percentFormat from "utils/percent/formatPercent";
import numberToPrice from "utils/currency/numberToPrice";
import { SaleSituationMap } from "data/property";
import numberFormat, { formatYearMonth } from "utils/number/format";
import { toProperCase } from "utils/string";
import { isNDAState } from '../../../utils/ndaStates';
import {
  numberFormatter,
  currencyFormatter,
  dateFormatter,
  yesNoFormatter,
  percentFormatter,
  properCaseFormatter,
  displayEstLabel,
  purchaseMethodFormatter,
  naFormatter
} from "utils/grid";

import Panel from "./Panel";
import ValueCell from "./valueCell";
import css from "./style.scss";
import DocumentImageCellRenderer from "./documentImageCellRenderer";

export const currencyRenderer = val => (!Number(val) ? "" : numberToPrice(val));
const saleAmountFormatter = ({
  value,
  data: { amountEstimated, listingAmountEstimated, nonDisclosure } = {}
}) =>
  `${numberToPrice(value) || ""}${
    amountEstimated || listingAmountEstimated || nonDisclosure ? "" : ""
  }`;
const changeRenderer = val =>
  !Number(val) ? (
    "--"
  ) : (
    <span className={css[val < 0 ? "minus" : "plus"]}>
      {percentFormat(val * 100)}
    </span>
  );
const yesNoFormat = v => (v ? "Yes" : "No");
const noFormat = v => v || "No";

export const TAX_COLUMNS = [
  { field: "year", title: "YEAR" },
  { field: "amount", title: "PROPERTY TAXES", renderer: currencyRenderer },
  { field: "amountChange", title: "CHANGE", renderer: changeRenderer },
  {
    field: "assessedValue",
    title: "TAX ASSESSMENT",
    renderer: currencyRenderer
  },
  { field: "assessedValueChange", title: "CHANGE", renderer: changeRenderer }
];

// export const MORTGAGE_COLUMNS = [
//   { field: 'recordingDate', title: 'Recording Date', renderer: dateRenderer },
//   { field: 'granteeName', title: 'Grantee' },
//   { field: 'lenderName', title: 'Lender Name' },
//   { field: 'description', title: 'Loan Description' },
//   { field: 'amount', title: 'Loan Amount', renderer: currencyRenderer },
// ];
//
// export const TRANSACTION_COLUMNS = [
//   { field: 'recordingDate', title: 'Recording Date', renderer: dateRenderer },
//   { field: 'sellerNames', title: 'Grantor' },
//   { field: 'ownerNames', title: 'Grantee' },
//   { field: 'documentType', title: 'Document Type' },
//   { field: 'documentNumber', title: 'Document #' },
//   { field: 'saleAmount', title: 'Sale Amount', renderer: currencyRenderer },
// ];

export const LEFT_FIELDS = [
  { label: "SqFt", value: "squareFeet", format: numberFormat },
  { label: "Lot Size", value: "lotSquareFeet", format: numberFormat },
  { label: "Year Built", value: "yearBuilt" },
  { label: "APN", value: "apn" }
];

export const RIGHT_FIELDS = [
  { label: "Property Type", value: "landUse" },
  { label: "Status", value: "marketStatus" },
  { label: "Distressed", value: "distressStatus", format: noFormat },
  { label: "Short Sale", value: "shortSale", format: yesNoFormat },
  { label: "HOA/COA", value: "hoa1Fee", format: yesNoFormat },
  { label: "Owner Type", value: "ownership" },
  { label: "Owner Status", value: "ownerOccupancy" },
  {
    label: "Occupancy",
    value: "vacant",
    format: v => (v ? "Vacant" : "Occupied")
  },
  {
    label: "Length of Ownership",
    value: "ownershipLength",
    format: formatYearMonth
  },
  { label: "Purchase Method", value: "purchaseMethod" },
  { label: "County", value: "countyName" }
];

export const GALLERY_FIELDS = [
  { label: "Estimated Value", value: "estimatedValue", format: numberToPrice },
  { label: "Owner Type", value: "ownership" },
  { label: "SqFt", value: "squareFeet", format: numberFormat },
  { label: "Property Type", value: "landUse" },
  { label: "Owner Status", value: "ownerOccupancy" },
  { label: "Lot Size", value: "lotSquareFeet", format: numberFormat },
  { label: "Status", value: "marketStatus" },
  {
    label: "Occupancy",
    value: "vacant",
    format: v => (v ? "Vacant" : "Occupied")
  },
  { label: "Year Built", value: "yearBuilt" },
  { label: "Distressed", value: "distressed", format: yesNoFormat },
  {
    label: "Length of Ownership",
    value: "ownershipLength",
    format: formatYearMonth
  },
  { label: "APN", value: "apn" },
  { label: "HOA/COA", value: "hoa1Fee", format: yesNoFormat },
  { label: "County", value: "countyName" }
];

export const COMP_FIELDS = [
  { name: "Properties", field: "comps" },
  { name: "Avg. Sale Price", field: "compSaleAmount", format: numberToPrice },
  { name: "Days on Market", field: "compDaysOnMarket" }
];

export const MORTGAGE_FIELDS = [
  {
    name: "Open Mortgages",
    field: "openMortgageQuantity",
    format: numberFormat
  },
  {
    name: "Estimated Balance",
    field: "openMortgageBalance",
    format: numberToPrice
  },
  {
    name: "Involuntary Liens",
    field: "involuntaryLienQuantity",
    format: numberFormat
  },
  {
    name: "Involuntary Amount",
    field: "involuntaryLienAmount",
    format: numberToPrice
  }
];

export const OPPORTUNITY_FIELDS = [
  { name: "Est. Equity", field: "estimatedEquity", format: numberToPrice },
  {
    name: "Linked Properties",
    field: p => numberFormat((p.get("linkedProperties") || List()).size)
  },
  { name: "Monthly Rent", field: "rentAmount", format: numberToPrice },
  {
    name: "Gross Yield",
    field: p => percentFormat(Math.round(p.get("grossYield") * 10000) / 100)
  }
];

let processingRows = false;
const baseGridProps = {
  getRowNodeId: d => d.id,
  suppressCellSelection: true,
  suppressContextMenu: true,
  enableCellTextSelection: true,
  // groupDefaultExpanded: 1,
  // groupUseEntireRow: true,
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
    menuTabs: []
  },
  onFirstDataRendered: ({ api }) => api.sizeColumnsToFit(),
  onRowGroupOpened: ({ api, data: { id }, node: { expanded } }) => {
    if (!processingRows && expanded) {
      processingRows = true;
      api.forEachNode(n => {
        if (n.expanded && n.data.id !== id) n.setExpanded(false);
      });
      processingRows = false;
    }
  }
};

export const GridProps = {
  historicMlsListings: {
    ...baseGridProps,
    columnDefs: [
      // { headerName: 'MLS Name', field: 'mlsName', rowGroup: true, hide: true, valueFormatter: properCaseFormatter },
      {
        headerName: "Date",
        field: "listingDate",
        valueFormatter: dateFormatter,
        width: 110,
        suppressSizeToFit: true
      },
      {
        headerName: "Action",
        field: "status",
        valueFormatter: properCaseFormatter
      },
      {
        headerName: "Price",
        field: "amount",
        valueFormatter: currencyFormatter,
        width: 100,
        suppressSizeToFit: true
      },
      {
        headerName: "PPSF",
        field: "pricePerSquareFoot",
        valueFormatter: currencyFormatter,
        width: 100,
        suppressSizeToFit: true
      },
      {
        headerName: "DOM",
        field: "daysOnMarket",
        width: 100,
        valueFormatter: naFormatter
      },
      {
        headerName: "Agent",
        field: "agentName",
        valueFormatter: properCaseFormatter
      },
      {
        headerName: "Brokerage",
        field: "brokerageName",
        valueFormatter: properCaseFormatter
      },
      { headerName: "MLS/Listing #", field: "mlsNumber" }
    ]
    // autoGroupColumnDef: { headerName: 'MLS Name', valueFormatter: properCaseFormatter },
  },
  historicPreForeclosures: {
    ...baseGridProps,
    columnDefs: [
      {
        headerName: "",
        field: "id",
        width: 22,
        suppressSizeToFit: true,
        cellRenderer: "agGroupCellRenderer",
        sortable: false,
        resizable: false,
        suppressMovable: true
      },
      {
        headerName: "Recording Date",
        field: "recordingDate",
        width: 110,
        suppressSizeToFit: true,
        valueFormatter: dateFormatter
      },
      {
        headerName: "Doc # / Doc Image",
        field: "documentNumber",
        cellRenderer: "documentImageCellRenderer"
      },
      {
        headerName: "Document Type",
        field: "documentType",
        valueFormatter: properCaseFormatter
      },
      { headerName: "Trustee # / Case #", field: "trusteeCaseNumber" },
      {
        headerName: "Borrower 1 Name",
        field: "borrower1Name",
        valueFormatter: properCaseFormatter
      },
      {
        headerName: "Borrower 2 Name",
        field: "borrower2Name",
        valueFormatter: properCaseFormatter
      },
      {
        headerName: "Lender Name",
        field: "lenderName",
        valueFormatter: properCaseFormatter
      }
    ],
    detailCellRendererParams: {
      detailGridOptions: {
        ...baseGridProps,
        columnDefs: [
          {
            headerName: "",
            headerClass: css.noBorder,
            field: "id",
            width: 5,
            sortable: false,
            resizable: false,
            suppressMovable: true,
            suppressSizeToFit: true,
            valueFormatter: () => ""
          },
          {
            headerName: "Default Amount",
            field: "defaultAmount",
            width: 125,
            suppressSizeToFit: true,
            valueFormatter: currencyFormatter
          },
          {
            headerName: "Unpaid Balance",
            field: "unpaidBalance",
            width: 125,
            suppressSizeToFit: true,
            valueFormatter: currencyFormatter
          },
          {
            headerName: "Auction Date",
            field: "auctionDate",
            width: 120,
            suppressSizeToFit: true,
            valueFormatter: dateFormatter
          },
          {
            headerName: "Opening Bid",
            field: "openingBidAmount",
            width: 125,
            suppressSizeToFit: true,
            valueFormatter: currencyFormatter
          },
          {
            headerName: "Trustee Attorney Name",
            field: "trusteeName",
            width: 135
          },
          {
            headerName: "Trustee Attorney Phone",
            field: "trusteePhone",
            width: 135
          }
        ]
      },
      getDetailRowData: ({ successCallback, data }) => successCallback([data])
    },
    masterDetail: true,
    getRowHeight: ({ node }) => (node && node.detail ? 105 : 30),
    isRowMaster: d =>
      d &&
      (d.defaultAmount ||
        d.unpaidBalance ||
        d.auctionDate ||
        d.openingBidAmount ||
        d.trusteeName ||
        d.trusteePhone),
    frameworkComponents: {
      documentImageCellRenderer: DocumentImageCellRenderer
    }
  },
  addresses: {
    getRowNodeId: d => d.id,
    suppressCellSelection: true,
    suppressContextMenu: true,
    enableCellTextSelection: true,
    defaultColDef: {
      resizable: true,
      filter: true,
      sortable: true,
      menuTabs: []
    },
    onFirstDataRendered: ({ api }) => api.sizeColumnsToFit(),
    columnDefs: [
      { headerName: "Address", field: "streetAddress", width: 200 },
      { headerName: "Unit #", field: "unitNumber", width: 80 },
      {
        headerName: "City",
        field: "cityName",
        valueFormatter: properCaseFormatter,
        width: 150
      },
      { headerName: "State", field: "stateCode", width: 80 },
      { headerName: "Zip Code", field: "zip", width: 80 },
      {
        headerName: "Currently Vacant",
        field: "vacant",
        width: 80,
        valueFormatter: yesNoFormatter
      }
    ]
  }
};

const saleSituationFormatter = d =>
  (d.value || "")
    .split("")
    .reduce(
      (l, v) =>
        `${(l && `${l}, `) || ""}${(SaleSituationMap[v] || {}).name || ""}`,
      null
    );

const compStatusFormatter = ({ value, data: { type, mlsStatus } = {} }) => {
  if (type === "U") return "Public Record Sold";

  // Handle the "mlsStatus" for the pinned subject property row at the top of the MLS results grid. The actual MLS result records will have the "status" field set. (Passed in here as "value".)
  const status = value || mlsStatus;
  if (status)
    return `${status === "Off Market" ? "" : "MLS "}${toProperCase(status)}`;

  return "";
};

const streetAddress = {
  field: "streetAddress",
  name: "Address",
  renderer: "addressCellRenderer",
  width: 150,
  pinned: "left"
};
const bedrooms = { field: "bedrooms", name: "Beds", width: 55 };
const bathrooms = { field: "bathrooms", name: "Baths", width: 55 };
const fullBathrooms = { field: "fullBathrooms", name: "Full Baths", width: 55 };
const partialBathrooms = {
  field: "partialBathrooms",
  name: "Partial Baths",
  width: 55
};
const rooms = { field: "rooms", name: "Total Rooms", width: 55 };
const squareFeet = {
  field: "squareFeet",
  name: "SqFt",
  formatter: numberFormatter,
  width: 55
};
const lotSquareFeet = {
  field: "lotSquareFeet",
  name: "Lot SqFt",
  formatter: numberFormatter,
  width: 55
};
const lotAcres = {
  field: "lotAcres",
  name: "Lot Acres",
  formatter: numberFormatter,
  width: 55
};
const multiParcel = {
  field: "multiParcel",
  name: "Multi-Parcel",
  formatter: yesNoFormatter,
  width: 60
};
const ownerOccupied = {
  field: "ownerOccupied",
  name: "Owner Occupied",
  formatter: yesNoFormatter,
  width: 65
};
const apn = { field: "apn", name: "APN", width: 80 };
const countyName = { field: "countyName", name: "County", width: 80 };
const landUse = {
  field: "landUse",
  name: "Property Type",
  width: 150,
  pinned: "left"
};
const saleSituationCode = {
  field: "saleSituationCode",
  name: "Sale Situation",
  formatter: saleSituationFormatter,
  width: 100
};
const subdivision = { field: "subdivision", name: "Subdivision", width: 100 };
const yearBuilt = { field: "yearBuilt", name: "Year Built", width: 55 };
const effectiveYearBuilt = {
  field: "effectiveYearBuilt",
  name: "Eff Year Built",
  width: 55
};
const bankruptcyChapter = {
  field: "bankruptcyChapter",
  name: "Bankruptcy Chapter",
  width: 70
};
const saleDate = {
  field: "saleDate",
  name: "Sale Date",
  formatter: dateFormatter,
  width: 80
};
const saleAmount = {
  field: "saleAmount",
  name: "Sale Amount",
  formatter: displayEstLabel,
  width: 100
};
const distanceFromSubject = {
  field: "distanceFromSubject",
  name: "Distance",
  width: 80
};
const estimatedEquity = {
  field: "estimatedEquity",
  name: "Estimated Equity",
  formatter: currencyFormatter,
  width: 80
};
const estimatedValue = {
  field: "estimatedValue",
  name: "Estimated Value",
  formatter: currencyFormatter,
  width: 80
};
const pricePerSquareFoot = {
  field: "pricePerSquareFoot",
  name: "PPSF",
  formatter: currencyFormatter,
  width: 80
};
const listingDate = {
  field: "listingDate",
  name: "Date",
  formatter: dateFormatter,
  width: 80
};
const auctionDate = {
  field: "auctionDate",
  name: "Date",
  formatter: dateFormatter,
  width: 80
};
const listingAmount = {
  field: "listingAmount",
  name: "Amount",
  formatter: currencyFormatter,
  width: 80
};
const daysOnMarket = {
  field: "daysOnMarket",
  name: "Days On Market",
  width: 65
};
const poolAvailable = {
  field: "poolAvailable",
  name: "Pool Present",
  formatter: yesNoFormatter,
  width: 65
};
const unpaidBalance = {
  field: "unpaidBalance",
  name: "Unpaid Balance",
  formatter: currencyFormatter,
  width: 80
};
const ltvRatio = {
  field: "ltvRatio",
  name: "LTV",
  formatter: percentFormatter,
  width: 80
};
const status = {
  field: "status",
  name: "MLS Status",
  formatter: properCaseFormatter,
  width: 80
};
const compStatus = {
  field: "status",
  name: "Status",
  formatter: compStatusFormatter,
  width: 120
};
// const foreclosureStatus = { field: 'foreclosureStatus', name: 'Status', width: 80 };
const lienDocumentType = {
  field: "lienDocumentType",
  name: "Lien Type",
  width: 80
};
const documentType = {
  field: "documentType",
  name: "Document Type",
  width: 100
};
const openingBidAmount = {
  field: "openingBidAmount",
  name: "Opening Bid",
  width: 80,
  formatter: currencyFormatter
};
const imageUrl = {
  field: "imageUrl",
  name: "Photo",
  width: 60,
  renderer: "galleryCellRenderer"
};

const initColumns = (columns, defaultColumns) => {
  const defs = defaultColumns.split(",");
  return columns.map((c, seq) => ({
    ...c,
    seq,
    hidden: !defs.includes(c.field)
  }));
};

/*
square_feet
fips => county
acqMultiParcel
disPurchaseMethod
 */

const acqRecordingDate = {
  field: "acqRecordingDate",
  name: "Purchase Date",
  formatter: dateFormatter,
  width: 80
};
const acqSaleAmount = {
  field: "acqSaleAmount",
  name: "Purchase Amount",
  formatter: currencyFormatter,
  width: 80
};
const acqMultiParcel = {
  field: "acqMultiParcel",
  name: "Purch Multi Parcel",
  formatter: yesNoFormatter,
  width: 65
};
const acqPurchaseMethod = {
  field: "acqCashSale",
  name: "Purch Method",
  width: 80,
  formatter: purchaseMethodFormatter
};
const acqForeclosure = {
  field: "acqForeclosure",
  name: "Purch Foreclosure Sale",
  formatter: yesNoFormatter,
  width: 65
};
const acqReoSale = {
  field: "acqReoSale",
  name: "Purch REO Sale",
  formatter: yesNoFormatter,
  width: 65
};
const acqNewConstruction = {
  field: "acqNewConstruction",
  name: "Purch New Const Sale",
  formatter: yesNoFormatter,
  width: 65
};
const acqInterFamily = {
  field: "acqInterFamily",
  name: "Purch Inter Family",
  formatter: yesNoFormatter,
  width: 65
};
const acqMortgage1Amount = {
  field: "acqMortgage1Amount",
  name: "Purch Mtg 1 Amount",
  formatter: currencyFormatter,
  width: 80
};
const acqMortgage1LenderName = {
  field: "acqMortgage1LenderName",
  name: "Purch Mtg 1 Lender Name",
  width: 80
};
const acqMortgage1SellerCarried = {
  field: "acqMortgage1SellerCarried",
  name: "Purch Mtg 1 Seller Carry Back",
  formatter: yesNoFormatter,
  width: 65
};
const acqMortgage1Construction = {
  field: "acqMortgage1Construction",
  name: "Purch Mtg 1 Const Loan",
  formatter: yesNoFormatter,
  width: 65
};
const acqMortgage2Amount = {
  field: "acqMortgage2Amount",
  name: "Purch Mtg 2 Amount",
  formatter: currencyFormatter,
  width: 80
};
const acqMortgage2LenderName = {
  field: "acqMortgage2LenderName",
  name: "Purch Mtg 2 Lender Name",
  width: 80
};
const acqOwner1FullName = {
  field: "acqOwner1FullName",
  name: "Purch Buyer 1 Full Name",
  width: 80
};
const acqOwnerType = {
  field: "acqOwnerType",
  name: "Purch Buyer Type",
  width: 80
};
const acqOwner1FirstName = {
  field: "acqOwner1FirstName",
  name: "Purch Buyer 1 First Name",
  width: 80
};
const acqOwner1LastName = {
  field: "acqOwner1LastName",
  name: "Purch Buyer 1 Last Name",
  width: 80
};
const acqMailStreetAddress = {
  field: "acqMailStreetAddress",
  name: "Purch Mailing Address",
  width: 80
};
const acqMailCity = {
  field: "acqMailCity",
  name: "Purch Mailing City",
  width: 80
};
const acqMailState = {
  field: "acqMailState",
  name: "Purch Mailing State",
  width: 80
};
const acqMailZip = {
  field: "acqMailZip",
  name: "Purch Mailing Zip Code",
  width: 80
};
const acqSeller1FullName = {
  field: "acqSeller1FullName",
  name: "Purch Seller 1 Full Name",
  width: 80
};
const acqSeller2FullName = {
  field: "acqSeller2FullName",
  name: "Purch Seller 2 Full Name",
  width: 80
};
const disRecordingDate = {
  field: "disRecordingDate",
  name: "Flip Date",
  formatter: dateFormatter,
  width: 80
};
const disSaleAmount = {
  field: "disSaleAmount",
  name: "Flip Amount",
  formatter: currencyFormatter,
  width: 80
};
const disMultiParcel = {
  field: "disMultiParcel",
  name: "Flip Multi Parcel",
  formatter: yesNoFormatter,
  width: 65
};
const disSeller1FullName = {
  field: "disSeller1FullName",
  name: "Flip Seller 1 Full Name",
  width: 80
};
const disSeller2FullName = {
  field: "disSeller2FullName",
  name: "Flip Seller 2 Full Name",
  width: 80
};
const disSellerStreetAddress = {
  field: "disSellerStreetAddress",
  name: "Flip Seller Mailing Address",
  width: 80
};
const disSellerCity = {
  field: "disSellerCity",
  name: "Flip Seller Mailing City",
  width: 80
};
const disSellerState = {
  field: "disSellerState",
  name: "Flip Seller Mailing State",
  width: 80
};
const disSellerZip = {
  field: "disSellerZip",
  name: "Flip Seller Zip Code",
  width: 80
};
const flipAmount = {
  field: "flipAmount",
  name: "Gross Profit",
  formatter: currencyFormatter,
  width: 80
};
const flipDays = {
  field: "flipDays",
  name: "Flip Length (Days)",
  formatter: numberFormatter,
  width: 55
};
const disOwner1FullName = {
  field: "disOwner1FullName",
  name: "Flip Buyer 1 Full Name",
  width: 80
};
const disOwner2FullName = {
  field: "disOwner2FullName",
  name: "Flip Buyer 2 Full Name",
  width: 80
};
const acqSalePpsf = {
  field: "acqSalePpsf",
  name: "Purch PPSF",
  formatter: currencyFormatter,
  width: 80
};
const disSalePpsf = {
  field: "disSalePpsf",
  name: "Flip PPSF",
  formatter: currencyFormatter,
  width: 80
};
const flipPpsf = {
  field: "flipPpsf",
  name: "Gross Profit PPSF",
  formatter: currencyFormatter,
  width: 80
};
// ID23-1168 add SUBDIVISION
export const SurroundingTypes = {
  COMP: {
    name: "Comps",
    fields:
      "saleDate,squareFeet,distanceFromSubject,bedrooms,lotSquareFeet,propertyClassCode,bathrooms,mlsListingStatus,landUseCode,yearBuilt,saleSituationCode,subdivision",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        landUse,
        compStatus,
        { ...saleDate, title: "Date" },
        { ...saleAmount, title: "Amount" },
        { ...daysOnMarket, title: "(MLS) Days On Market" },
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        pricePerSquareFoot,
        poolAvailable,
        saleSituationCode,
        subdivision,
        multiParcel,
        distanceFromSubject
      ],
      "streetAddress,imageUrl,apn,landUse,status,saleDate,saleAmount,daysOnMarket,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,pricePerSquareFoot,poolAvailable,saleSituationCode,subdivision,multiParcel,distanceFromSubject"
    )
  },
  FLIP: {
    name: "Flip Comps",
    fields:
      "saleDate,squareFeet,distanceFromSubject,bedrooms,flipLength,purchaseMethod,bathrooms,propertyClassCode,excludeMultiParcel,yearBuilt,landUseCode",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        countyName,
        landUse,
        rooms,
        bedrooms,
        bathrooms,
        fullBathrooms,
        partialBathrooms,
        squareFeet,
        yearBuilt,
        effectiveYearBuilt,
        lotSquareFeet,
        lotAcres,
        acqRecordingDate,
        acqSaleAmount,
        acqMultiParcel,
        acqPurchaseMethod,
        acqForeclosure,
        acqReoSale,
        acqNewConstruction,
        acqInterFamily,
        acqMortgage1Amount,
        acqMortgage1LenderName,
        acqMortgage1SellerCarried,
        acqMortgage1Construction,
        acqMortgage2Amount,
        acqMortgage2LenderName,
        acqOwner1FullName,
        acqOwnerType,
        acqOwner1FirstName,
        acqOwner1LastName,
        acqMailStreetAddress,
        acqMailCity,
        acqMailState,
        acqMailZip,
        acqSeller1FullName,
        acqSeller2FullName,
        disRecordingDate,
        disSaleAmount,
        disMultiParcel,
        disSeller1FullName,
        disSeller2FullName,
        disSellerStreetAddress,
        disSellerCity,
        disSellerState,
        disSellerZip,
        flipAmount,
        flipDays,
        disOwner1FullName,
        disOwner2FullName,
        acqSalePpsf,
        disSalePpsf,
        flipPpsf,
        distanceFromSubject
      ],
      "streetAddress,imageUrl,landUse,bedrooms,bathrooms,squareFeet,acqRecordingDate,acqSaleAmount,disRecordingDate,disSaleAmount,flipAmount,flipDays,distanceFromSubject"
    )
  },
  NEIGHBOR: {
    name: "Neighbors",
    fields:
      "saleDate,squareFeet,distanceFromSubject,bedrooms,propertyClassCode,_blank,bathrooms,landUseCode,_blank2,yearBuilt",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedValue,
        distanceFromSubject,
        status,
        ltvRatio,
        estimatedEquity,
        ownerOccupied,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  U: {
    name: "Public Record Comps",
    fields:
      "saleDate,squareFeet,distanceFromSubject,bedrooms,mlsListingStatus,propertyClassCode,bathrooms,saleSituationCode,landUseCode,yearBuilt,excludeMultiParcel",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        landUse,
        compStatus,
        { ...saleDate, title: "Date" },
        { ...saleAmount, title: "Amount" },
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        pricePerSquareFoot,
        poolAvailable,
        saleSituationCode,
        multiParcel,
        distanceFromSubject
      ],
      "streetAddress,imageUrl,apn,landUse,status,saleDate,saleAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,pricePerSquareFoot,poolAvailable,saleSituationCode,multiParcel,distanceFromSubject"
    )
  },
  M: {
    name: "MLS Comps",
    fields:
      "saleDate,squareFeet,distanceFromSubject,bedrooms,mlsListingStatus,propertyClassCode,bathrooms,saleSituationCode,landUseCode,yearBuilt,excludeMultiParcel",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        landUse,
        compStatus,
        { ...saleDate, title: "Date" },
        { ...saleAmount, title: "Amount" },
        { ...daysOnMarket, title: "(MLS) Days On Market" },
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        pricePerSquareFoot,
        poolAvailable,
        multiParcel,
        distanceFromSubject
      ],
      "streetAddress,imageUrl,apn,landUse,status,saleDate,saleAmount,daysOnMarket,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,pricePerSquareFoot,poolAvailable,multiParcel,distanceFromSubject"
    )
  },
  // M: {
  //   fields: 'recordingDate,squareFeet,distanceFromSubject,bedrooms,mlsListingStatus,subdivision,bathrooms,propertyClassCode,_blank,yearBuilt,landUseCode',
  //   columns: initColumns([streetAddress, imageUrl, apn, landUse, { ...saleDate, title: 'Last Public Record Sale Date' }, { ...saleAmount, title: 'Last Public Record Sale Amount' }, status, { ...listingDate, title: 'MLS Date' }, { ...listingAmount, title: 'MLS Amount' }, daysOnMarket, bedrooms, bathrooms, squareFeet, lotSquareFeet, pricePerSquareFoot, yearBuilt, subdivision, poolAvailable, distanceFromSubject], 'streetAddress,imageUrl,apn,landUse,status,listingDate,listingAmount,daysOnMarket,bedrooms,bathrooms,squareFeet,lotSquareFeet,pricePerSquareFoot,yearBuilt,subdivision,poolAvailable,distanceFromSubject'),
  // },
  P: {
    name: "Pre-Foreclosures",
    fields:
      "recordingDate,squareFeet,distanceFromSubject,bedrooms,yearBuilt,foreclosureStatus,bathrooms,propertyClassCode,auctionDate,landUseCode",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        { ...listingDate, title: "Recording Date" },
        documentType,
        { ...listingAmount, title: "Default Amount" },
        unpaidBalance,
        auctionDate,
        openingBidAmount,
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        status,
        lotSquareFeet,
        ltvRatio,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,listingDate,documentType,defaultAmount,unpaidBalance,auctionDate,openingBidAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  L: {
    name: "Liens",
    fields:
      "recordingDate,squareFeet,distanceFromSubject,bedrooms,propertyClassCode,bathrooms,landUseCode,_blank,yearBuilt,lienTypeCode",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        lienDocumentType,
        { ...listingDate, title: "Lien Date" },
        { ...listingAmount, title: "Lien Amount" },
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        status,
        ltvRatio,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,lienDocumentType,listingDate,listingAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  C: {
    name: "Cash Buyers",
    fields:
      "saleDate,squareFeet,distanceFromSubject,bedrooms,propertyClassCode,excludeMultiParcel,bathrooms,landUseCode,yearBuilt,ownerOccupied",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        status,
        saleSituationCode,
        ltvRatio,
        multiParcel,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  E: {
    name: "High Equity",
    fields:
      "bedrooms,squareFeet,ownershipLength,bathrooms,yearBuilt,distanceFromSubject,ltvRatio,propertyClassCode,estimatedEquity,landUseCode",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        status,
        ltvRatio,
        multiParcel,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  V: {
    name: "Vacant",
    fields:
      "bedrooms,squareFeet,distanceFromSubject,bathrooms,propertyClassCode,ownershipLength,yearBuilt,landUseCode",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        status,
        ltvRatio,
        saleSituationCode,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  B: {
    name: "Bankruptcy",
    fields:
      "recordingDate,squareFeet,distanceFromSubject,bedrooms,propertyClassCode,bathrooms,landUseCode,_blank,yearBuilt,bankruptcyChapter",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        { ...listingDate, title: "Recording Date" },
        bankruptcyChapter,
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        status,
        ltvRatio,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,listingDate,bankruptcyChapter,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  F: {
    name: "Bank Owned",
    fields:
      "recordingDate,squareFeet,distanceFromSubject,bedrooms,yearBuilt,bathrooms,propertyClassCode,_blank,auctionDate,landUseCode",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        unpaidBalance,
        ltvRatio,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  },
  R: {
    name: "Free & Clear"
  },
  D: {
    name: "Divorce",
    columns: initColumns(
      [
        streetAddress,
        imageUrl,
        apn,
        landUse,
        saleDate,
        saleAmount,
        { ...listingDate, title: "Filing Date" },
        bedrooms,
        bathrooms,
        squareFeet,
        lotSquareFeet,
        yearBuilt,
        estimatedEquity,
        estimatedValue,
        distanceFromSubject,
        ltvRatio,
        poolAvailable
      ],
      "streetAddress,imageUrl,apn,landUse,saleDate,saleAmount,listingDate,bedrooms,bathrooms,squareFeet,lotSquareFeet,yearBuilt,estimatedEquity,estimatedValue,poolAvailable,distanceFromSubject"
    )
  }
};

SurroundingTypes.R.fields = SurroundingTypes.V.fields;
SurroundingTypes.D.fields = SurroundingTypes.P.fields;

SurroundingTypes.R.columns = SurroundingTypes.V.columns.map(c => ({ ...c }));

export const writeValues = (label, property, fields) => (
  <div>
    <div className={css.label}>{label}</div>
    <div className={css.values}>
      {fields.map(f => (
        <ValueCell
          {...{
            ...f,
            value: (f.format || (v => v))(
              typeof f.field === "function"
                ? f.field(property)
                : property.get(f.field)
            )
          }}
          key={f.name}
        />
      ))}
    </div>
  </div>
);

export const writeFields = (property, fields) =>
  fields.map(({ label, value, format = v => v, valueExtractor }) => (
    <div className={css.item} key={label}>
      <div className={css.label}>{label}</div>
      <div className={css.value}>{format(property.get(value))}</div>
    </div>
  ));

export const writeSections = (property, sections) =>
  sections.map(({ caption, name, code, fieldQuantity, fields }) => (
    <div className={css.page} key={`${code}`}>
      <Panel padding="20px">
        {!caption ? null : <div className={css.caption}>{caption}</div>}
        {!name ? null : (
          <div className={css.heading}>
            <div>{name}</div>
            {!name.startsWith("Additional Linked Addresses") ||
              !property.get("totalAddressUnits") ? null : (
              <div className={css.details}>
                <div className={css.detail}>
                  <span className={css.label}>Total Units:</span>{" "}
                  {numberFormat(property.get("totalAddressUnits"))}
                </div>
                <div className={css.detail}>
                  <span className={css.label}>Vacant Units:</span>{" "}
                  {numberFormat(property.get("vacantAddressUnits"))}
                </div>
              </div>
            )}
          </div>
        )}
        <div className={css.row}>
          {fields.map(({ name, value, tab, grid, newLine }) => {
            const ndaLabel = value && isNDAState(property.get('address').get('stateCode')) ? 'Est.' : '';
            return tab
              ? writeSections(property, tab.sections)
              : [
                !newLine ? null : (
                  <div className={css.break} key={`${name}-break`} />
                ),
                <div
                  className={classNames(css.cell, {
                    [css.single]: fieldQuantity === 1
                  })}
                  key={`${name}`}
                >
                  {grid ? (
                    value
                  ) : (
                    <GroupInfo label={fieldQuantity > 1 ? name : null}>
                      <div>{name === 'Sale Amount' ? `${value} ${ndaLabel}` : value}</div>
                    </GroupInfo>
                  )}
                </div>
              ]
            }
          )}
        </div>
      </Panel>
    </div>
  ));

const selectRenderer = onSelect => (value, { row }) => (
  <Checkbox checked={value} onClick={ev => onSelect(row, ev.target.checked)} />
);
const indexRenderer = (value, { row }, data) => (
  <div
    className={classNames(css.indexCell, {
      [css.cashBuyer]: !!data.get("cashBuyer")
    })}
  >
    <span>{row + 1}.</span>
  </div>
);

export const writeTable = (
  data,
  columns,
  style = "type_8",
  { onRowClick, onSelect, onSort, sort, expandComponent, save } = {}
) => {
  let caretIcon;
  let sortFields;
  let sortableFields;

  if (onSort) {
    sortableFields = columns.map(c => c.field);
    if (sort) {
      const desc = sort.startsWith("!");
      caretIcon = <SVG icon={desc ? "iconCaretDown" : "iconCaretUp"} />;
      sortFields = fromJS([desc ? sort.substr(1) : sort]);
    }
  }

  return (
    <div className={css.trendTable}>
      <Table
        data={data}
        keyField="id"
        className={style}
        sortFields={sortFields}
        sortableFields={sortableFields}
        onColumnSort={onSort}
        onRowClick={onRowClick}
        isSortable={!!sortableFields}
        expandComponent={expandComponent}
        expandableRow={() => !!expandComponent}
        save={save}
        isHoverable
      >
        {!onSelect ? null : (
          <Column
            key="selected"
            field="selected"
            renderCell={selectRenderer(onSelect)}
          >
            <Checkbox
              checked={data.size && !data.find(d => !d.get("selected"))}
              onClick={ev => onSelect(null, ev.target.checked)}
            />
          </Column>
        )}
        {style !== "type_11" ? null : (
          <Column key="index" field="index" renderCell={indexRenderer}>
            #
          </Column>
        )}
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            renderCell={col.renderer}
            sortIcon={caretIcon}
          >
            {col.title}
          </Column>
        ))}
      </Table>
    </div>
  );
};
