import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';
import { withRouter } from 'react-router-dom';
import Slider from 'rc-slider';
import classNames from 'classnames';
import Button, { ButtonLink } from 'components/base/Button';
import ColorDot from 'components/ColorDot';
import Confirm from 'app/components/Confirm';
import FroalaEditor from 'components/FroalaEditor';
import { froalaMinimalConfig } from 'config/constants/wysiwyg';
import { campaignPath, campaignWebsitePath } from 'app/routes';
import ContentTile from 'app/components/GraphicEditor/ContentTile';
import { formatDocumentUrl, campaignWebsiteUrl } from 'utils/URL';
import { selectProfile } from 'data/user';

import {
  selectCampaign,
  selectLoading,
  selectWebsite,
  loadWebsite,
  saveWebsite,
  updateWebsite,
  ElementTypes,
} from 'data/campaigns';

import css from './style.scss';


class Website extends PureComponent {

  constructor(props) {
    super(props);

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleToggleStatusClick = this.handleToggleStatusClick.bind(this);
    this.handleEditorInitialized = this.handleEditorInitialized.bind(this);
    this.handleHeaderChange = this.handleHeaderChange.bind(this);
    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handleSelectElement = this.handleSelectElement.bind(this);
    this.handleLogoWidthChange = this.handleLogoWidthChange.bind(this);
    this.handleMoveBackClick = this.handleMoveBackClick.bind(this);
    this.handleMoveForwardClick = this.handleMoveForwardClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);

    this.formRef = null;
    this.editors = [];
    this.headerConfig = { ...froalaMinimalConfig, fontSizeDefaultSelection: '44', events: { 'froalaEditor.initialized': this.handleEditorInitialized }, placeholderText: 'We Buy Houses, Fast & Fair Offer Today!' };
    this.bodyConfig = { ...this.headerConfig, fontSizeDefaultSelection: '18', placeholderText: 'We Pay All Cash For Your Home! Any price range, any location, any condition! Contact us today for an ALL CASH offer on your home, AS IS!' };
    this.initElements(props);

    this.state = { loaded: false, selectedIndex: null, path: null };
  }

  componentWillMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(props) {
    const { loading } = props;

    this.editors.forEach(editor => editor.edit[loading ? 'off' : 'on']());
    this.handleProps(props);
    this.initElements(props);
  }

  initElements(props) {
    const { website } = props;
    const elements = website.get('elements');
    const images = elements.filter(e => e.get('type') === ElementTypes.IMAGE);

    this.elements = fromJS([
      (elements.find(e => e.get('type') === ElementTypes.BANNER) || fromJS({ type: ElementTypes.BANNER })).set('title', 'Banner Image'),
      (elements.find(e => e.get('type') === ElementTypes.LOGO) || fromJS({ type: ElementTypes.LOGO })).set('title', 'Logo'),
    ]).concat(images.concat(images.size < 5 ? fromJS([{ type: ElementTypes.IMAGE }]) : List()).map((m, i) => m.set('title', `Image ${i + 1}`)))
      .map((el, i) => el.merge({ i }));
  }

  handleEditorInitialized(e, editor) {
    const { loading } = this.props;
    this.editors.push(editor);
    editor.edit[loading ? 'off' : 'on']();
  }

  handleProps(props) {
    const { campaign, loading, loadWebsite, updateWebsite, history, location: { pathname } } = props;
    if (!loading && !this.state.loaded && campaign.get('id')) {
      this.setState({ loaded: true }, () => {
        loadWebsite();

        const { website, profile } = this.props;
        if (website.get('id')) this.setState({ path: website.get('path') });
        else {
          updateWebsite(website.merge({ contactEmail: profile.get('username'), path: campaign.get('id') }));
          if (!pathname.includes('settings')) history.push(campaignWebsitePath(campaign, 'settings'));
        }
      });
    }
  }

  save(website) {
    const { campaign, saveWebsite } = this.props;
    saveWebsite(campaign.get('id'), website, ({ response: { website: { path } } }) => this.setState({ path }));
  }

  handleSaveClick() {
    this.save(this.props.website);
  }

  handleMoveBackClick() {
    this.moveElement(-1);
  }

  handleMoveForwardClick() {
    this.moveElement(1);
  }

  moveElement(delta) {
    const { selectedIndex } = this.state;
    this.updateElements(this.elements.delete(selectedIndex).insert(selectedIndex + delta, this.elements.get(selectedIndex)));
    this.setState({ selectedIndex: selectedIndex + delta });
  }

  handleRemoveClick() {
    const { selectedIndex } = this.state;
    this.setState({ selectedIndex: null }, () => this.updateElements(this.elements.delete(selectedIndex)));
  }

  handleToggleStatusClick() {
    const { website, confirm } = this.props;
    const active = !website.get('active');
    const toggle = () => this.save(website.set('active', active));

    if (active) toggle();
    else {
      confirm({
        question: 'Are you sure you want to deactivate this website? It will no longer be publicly accessible after deactivation.',
        onOk: () => toggle(),
        okLabel: 'Deactivate',
      });
    }
  }

  handlePreviewClick() {
    const { website } = this.props;
    const websiteData = website.toJS();
    websiteData.active = true; // load website even for inactive status.
    this.formRef.data.value = JSON.stringify(websiteData);
    this.formRef.submit();
  }

  handleHeaderChange(text) {
    if (text === '') this.editors[0].fontSize.apply('32px');
    this.handleChange({ headerText: text || '' });
  }

  handleBodyChange(text) {
    if (text === '') this.editors[1].fontSize.apply('32px');
    this.handleChange({ bodyText: text || '' });
  }

  handleImageChange(image, element) {
    this.updateElements(this.elements.set(element.i, fromJS({ ...element, ...image, id: null })));
  }

  updateElements(els) {
    const elements = els.filter(el => !!el.get('image'));
    this.handleChange({ elements });
  }

  handleSelectElement(selectedElement) {
    this.setState({ selectedIndex: selectedElement.image ? selectedElement.i : null });
  }

  handleChange(change) {
    const { website, updateWebsite } = this.props;
    updateWebsite(website.merge(change));
  }

  handleLogoWidthChange(width) {
    const { website, updateWebsite } = this.props;
    updateWebsite(website.setIn(['elements', website.get('elements').findIndex(e => e.get('type') === ElementTypes.LOGO), 'width'], width));
  }

  render() {
    const { campaign, website, children, loading } = this.props;
    const { selectedIndex, path } = this.state;

    const id = campaign.get('id');
    const url = campaignWebsiteUrl(website.get('path')) || '';
    const displayUrl = url.substr(url.indexOf('www.'));
    const target = `website${id}`;
    const active = website.get('active');
    const color = active ? '#0fb474' : '#d0021b';

    const btn = { kind: Button.kind.grayGhost, size: Button.size.large, className: css.btn, isLoading: loading };
    const smallBtn = { ...btn, size: Button.size.small };
    const blueBtn = { ...btn, kind: Button.kind.blue };

    const elements = this.elements.toJS();
    const banner = elements[0];
    const logo = elements[1];
    const images = elements.slice(2);
    const selectedElement = selectedIndex == null ? null : elements[selectedIndex];
    const tileProps = { onChange: this.handleImageChange, onSelectElement: this.handleSelectElement, className: css.tile, selectedElement };

    if (!logo.width) logo.width = 172;

    return (
      <div className={classNames(css.campaign, css.website)}>
        <div className={css.header}>
          <div className={css.left}>
            <div>
              <div className={css.title}>
                Website
                {loading ? null : <ButtonLink kind={Button.kind.blueLink} className={css.blueLink} to={campaignWebsitePath(campaign, 'settings')}>EDIT</ButtonLink>}
              </div>
              <div className={css.url}>{active && path === website.get('path') ? <a href={url} target={target}>{displayUrl}</a> : displayUrl}</div>
            </div>
          </div>
          <div className={css.websiteStatus}>
            <ColorDot color={color} isBig={false} />
            <span>Website {active ? 'Active' : 'Inactive'}</span>
          </div>
          <div className={css.right}>
            <ButtonLink {...btn} to={campaignPath(id)}>Back</ButtonLink>
            <Button {...btn} onClick={this.handlePreviewClick}>Preview</Button>
            {!active ? null : <Button {...btn} kind={Button.kind.redGhost} onClick={this.handleToggleStatusClick}>Deactivate</Button>}
            <Button {...blueBtn} onClick={this.handleSaveClick}>Save</Button>
            {active ? null : <Button {...blueBtn} onClick={this.handleToggleStatusClick}>Publish</Button>}
          </div>
        </div>
        <div className={css.body}>
          <div className={css.entry}>
            <div className={css.raisedPanel}>
              <div className={css.label}>Website Images</div>
              <div className={css.images}>
                {elements.map(el => <ContentTile key={el.title} title={el.title} element={el} {...tileProps} />)}
              </div>
              {!logo.image ? null : (
                <div className={css.slider}>
                  <div className={css.fieldLabel}>Logo Size</div>
                  <Slider min={25} max={1000} value={logo.width} onChange={this.handleLogoWidthChange} />
                </div>
              )}
              <div className={css.buttons}>
                <Button {...smallBtn} onClick={this.handleMoveBackClick} disabled={!(selectedIndex > 2)}>Move Back</Button>
                <Button {...smallBtn} onClick={this.handleMoveForwardClick} disabled={!(selectedIndex > 1 && selectedIndex < (2 + elements.slice(2).filter(el => el.image).length) - 1)}>Move Forward</Button>
                <Button {...smallBtn} kind={Button.kind.redGhost} onClick={this.handleRemoveClick} disabled={selectedIndex == null}>Remove</Button>
              </div>
            </div>
            <div className={css.label}>Header Text</div>
            <div className={css.headerText}>
              <FroalaEditor config={this.headerConfig} model={website.get('headerText')} onModelChange={this.handleHeaderChange} />
            </div>
            <div className={css.label}>Body Text</div>
            <div className={css.bodyText}>
              <FroalaEditor config={this.bodyConfig} model={website.get('bodyText')} onModelChange={this.handleBodyChange} />
            </div>
          </div>
          <div className={css.previewOuter}>
            <div className={css.previewContainer}>
              <div className={classNames(css.preview, css.template)}>
                <div className={css.banner} style={{ backgroundImage: formatDocumentUrl(banner.image, true) }}>
                  {!logo.image ? null : <img className={css.logo} src={formatDocumentUrl(logo.image)} alt="" style={{ width: `${logo.width}px` }} />}
                  <div className={css.interested}>
                    <div>Are You Interested?</div>
                    <a className={css.btn} href="#contact-form"> Contact Us </a>
                  </div>
                </div>
                <div className={css.content}>
                  <div className={css.title} dangerouslySetInnerHTML={{ __html: website.get('headerText') }} />
                  <div className={css.text} dangerouslySetInnerHTML={{ __html: website.get('bodyText') }} />
                  {!images.filter(i => i.image).length ? null : (
                    <div className={css.gallery}>
                      <div className={css.items}>
                        <a className={css.item} style={{ backgroundImage: formatDocumentUrl(images[0].image, true) }}>&nbsp;</a>
                        <div className={css.items}>
                          {[1, 2, 3, 4].map(i => (
                            <a key={i} className={css.item} style={{ backgroundImage: formatDocumentUrl(images[i] && images[i].i ? images[i].image : null, true) }}>&nbsp;</a>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={css.contact}>
                  <div className={css.title}>
                    CONTACT <span>US</span>
                  </div>
                  <input type="text" name="name" placeholder="NAME" />
                  <input type="text" name="email" placeholder="EMAIL" />
                  <input type="text" name="phone" placeholder="PHONE" />
                  <textarea name="message" placeholder="MESSAGE" />
                  <input type="submit" value="SEND" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <form method="post" target={target} action={campaignWebsiteUrl(website.get('path'))} ref={el => (this.formRef = el)}>
          <input type="hidden" name="data" />
        </form>
        {children}
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  campaign: selectCampaign(state),
  loading: selectLoading(state),
  website: selectWebsite(state),
  profile: selectProfile(state),
}), {
  loadWebsite,
  saveWebsite,
  updateWebsite,
  confirm: Confirm.open,
})(Website));
