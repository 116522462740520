import React, { PureComponent } from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import noop from 'utils/noop';
import * as onOuterClick from 'utils/DOM/onOuterClick';
import * as onKeyPress from 'utils/DOM/onKeyPress';
import { initScrollElement, destructScrollElement, run as runScrollCallbacks } from 'utils/DOM/scrollSpy';
import Button from 'components/base/Button';
import SVG from 'components/base/SVG';
import loadable from 'components/hoc/loadable';

import css from './style.scss';


const BODY_CLASS_NAME = 'bodyModal';

/* TODO: needs to use PopupHolder */
const PopupHeader = pure((props) => {
  const { children = '', isCloseButton, onClose, captionClassName, forceCaptionClass, customCloseBtnCls } = props;
  const closeButton = isCloseButton ? (
    <Button kind="link-default" name="close" className={`${css.btnClose} ${customCloseBtnCls}`} onClick={onClose}>
      <SVG icon="iconClose" className={css.iconClose} />
    </Button>)
    :
    '';
  const captionClass = classNames([
    { [css.defaultCaption]: typeof children === 'string' || forceCaptionClass },
  ]);

  if (!isCloseButton && !children) return null;

  return (
    <div className={css.modalHeader}>
      {closeButton}
      <div className={classNames(captionClass, captionClassName)}>{children}</div>
    </div>
  );
});

const ModalContent = pure(loadable(({ children }) => children));

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
    };
    this.mounted = false;
    this.handleClose = this.handleClose.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleModalRef = this.handleModalRef.bind(this);
    this.handleBackDropClick = this.handleBackDropClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.tryClose = this.tryClose.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (this.state.isOpen) document.body.classList.add(BODY_CLASS_NAME);
    const { ignoreEsc = false } = this.props;
    onOuterClick.addCatcher(this.parent);
    onOuterClick.addListener(this.tryClose);
    if (ignoreEsc === false) onKeyPress.addListener('Escape', this.handleClose);

    initScrollElement(this.props.uniqId);
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen } = nextProps;
    this.setState({ isOpen });
  }

  componentDidUpdate(_, oldState) {
    if (this.state.isOpen !== oldState.isOpen) {
      if (this.state.isOpen) document.body.classList.add(BODY_CLASS_NAME);
      else document.body.classList.remove(BODY_CLASS_NAME);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    onOuterClick.removeCatcher(this.parent);
    onKeyPress.removeListener('Escape', this.handleClose);
    onOuterClick.removeListener(this.tryClose);

    destructScrollElement(this.props.uniqId);
  }

  handleRef(ref) {
    this.parent = ref;
    const { onModalRef: onRef } = this.context;
    const { onModalRef = onRef } = this.props;
    if (onModalRef) onModalRef(ref);
  }

  handleModalRef(ref) {
    this.modal = ref;
    const { onModalRef: onRef } = this.context;
    const { onModalRef = onRef } = this.props;
    if (onModalRef) onModalRef(ref);
  }

  tryClose(event) {
    if (!onOuterClick.isOnElement(event, this.parent)) {
      this.handleClose();
    }
  }

  close() {
    const { onClose = noop } = this.props;
    const { closePopup = noop } = this.context;
    onClose();
    closePopup();
  }

  handleClose() {
    if (this.state.isOpen === false) return;
    if (this.props.isOpen === false) return;
    if (this.mounted) {
      this.setState({ isOpen: false }, () => {
        this.close();
      });
    } else {
      this.close();
    }
  }

  handleBackDropClick(ev) {
    const el = ev.target;
    if (this.modal && (this.modal.contains(ev.target) || ev.clientX > (el.offsetWidth - 18) || ev.clientY > (el.offsetHeight - 18))) return;
    this.handleClose();
  }

  handleScroll(event) {
    const { target } = event;
    runScrollCallbacks(this.props.uniqId, target);
    return this;
  }

  render() {
    // TODO: rewrite this into css
    const {
      children,
      width,
      height,
      maxHeight,
      maxWidth,
      background,
      padding,
      border,
      uniqId,
      isCloseButton,
      caption,
      className,
      outerClassName,
      isLoading,
      modalClassName,
      forceCaptionClass,
      customCloseBtnCls,
      style = {}
    } = this.props;
    const { isOpen } = this.state;
    const modalClass = classNames(
      css.modal,
      { [css.open]: isOpen },
      outerClassName,
    );
    const modalScrollClass = classNames(
      css.modalScroll,
      className,
    );
    const inlineStyles = {
      width,
      height,
      maxHeight,
      maxWidth,
      background,
      padding,
      border,
    };
    return (
      <div id={uniqId} style={style} className={classNames(css.clsSearchFilterModal, modalClass)} ref={this.handleRef} onMouseDown={this.handleBackDropClick}>
        <div className={modalScrollClass} onScroll={this.handleScroll}>
          <div className={classNames(css.modalContent, modalClassName)} style={inlineStyles} ref={this.handleModalRef}>
            <PopupHeader isCloseButton={isCloseButton} customCloseBtnCls={customCloseBtnCls} onClose={this.handleClose} forceCaptionClass={forceCaptionClass}>{caption}</PopupHeader>
            <ModalContent isLoading={isLoading}>
              <div style={{ height: '100%', maxHeight: '100%' }}>
                {children}
              </div>
            </ModalContent>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  uniqId: PropTypes.string.isRequired,
  caption: PropTypes.node,
  className: PropTypes.string,
  modalClassName: PropTypes.string,
  captionClassName: PropTypes.string,
};

Modal.defaultProps = {
  uniqId: `modal${new Date().getTime()}`,
  isCloseButton: false,
  isOpen: false,
  width: '435px',
  height: 'auto',
  maxHeight: 'auto',
  maxWidth: 'auto',
  background: '#fff',
  padding: '25px 20px',
  caption: '',
  forceCaptionClass: false,
  customCloseBtnCls: '',
};

Modal.contextTypes = {
  closePopup: PropTypes.func,
};

export default Modal;
