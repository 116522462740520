// Capitalizes the first letter of each word in a given string.
export const capitalizeFirstLetter = (str) => {
    if (!str) return str;

    // Split by spaces only, keep hyphenated parts intact
    return str.split(' ').map(word => {
        if (word.includes('-')) {
            return word.split('-').map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1)).join('-');
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
};

// Convert the string to lowercase and remove all non-alphanumeric characters.
export const normalizeString = (str) => {
    return str.toLowerCase().replace(/[^a-z0-9]/g, '');
};