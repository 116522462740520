import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilterById,
  removeLocalFilter,
  selectLocalFilter,
  selectNewFilter,
  selectShowAllEditOption,
  updateFilter,
  updateLocalFilter
} from "../../../../../../data/search";
import newsearchfilter from "../../SearchFilterNew.scss"; // Import the SCSS file
import { useFilter } from "../../TagFilterContext";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import { AgriculturalPropertyTypesOptions, BathroomOptions, BedroomOptions, BuildingSizeOptions, CommercialPropertyTypesOptions, ExemptPropertyTypesOptions, FilterExtraTextDisplay, IncludeExcludeOptions, IndustrialPropertyTypesOptions, LotSizeOptions, MultiFamilyPropertyTypesOptions, NumberStoryOptions, NumberUnitOptions, OfficePropertyTypesOptions, PropertyBasementTypesList, PropertyCharacteristicsScreen, PropertyGarageTypesList, PropertyPoolTypeList, RecreationalPropertyTypesOptions, ResidentialPropertyTypeOptions, TransportationPropertyTypesOptions, VacantLandPropertyTypesOptions, YearBuiltOptions, fieldTagsMap } from "../../shared/ConstantsNew";
import FilterInputComponent from "../../shared/Input";
import ToggleSection from "../../shared/ToggleSection";
import ToggleList from "../../shared/ToggleSection/ToggleList";
import isEqual from 'lodash/isEqual';
import { generateMinMaxValue, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";

const PropertyDetails = () => {
  const dispatch = useDispatch();
  // screen details
  const propertyCharacteristicsScreen = PropertyCharacteristicsScreen;
  const propertyClassCode = propertyCharacteristicsScreen.propertyClassCode;
  const filter = useSelector(selectNewFilter);
  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const refs = useRef({});
  const [isFilterApplied, setFilterApplied] = useState(false);

  const getEditOptions = useSelector(selectShowAllEditOption)

  const maxLength = 10

  const ResidentialChildList = [
    { label: 'Residential Property Types', id: "residentialPropCode", options: ResidentialPropertyTypeOptions },
    { label: 'Multi-Family Property Types', id: "multiFamilyPropCode", options: MultiFamilyPropertyTypesOptions },
  ]

  const CommercialChildList = [
    { label: 'Commercial Property Types', id: "commercialPropCode", options: CommercialPropertyTypesOptions },
  ]

  const VacantChildList = [
    { label: 'Vacant Land Property Types', id: "vacantPropCode", options: VacantLandPropertyTypesOptions },
  ]

  const OthersChildList = [
    { label: 'Office Property Types', id: "officePropCode", options: OfficePropertyTypesOptions },
    { label: 'Industrial Property Types', id: "industrialPropCode", options: IndustrialPropertyTypesOptions },
    { label: 'Agricultural Property Types', id: "agriculturalPropCode", options: AgriculturalPropertyTypesOptions },
    { label: 'Recreational Property Types', id: "recreationalPropCode", options: RecreationalPropertyTypesOptions },
    { label: 'Transportation Property Types', id: "transportationPropCode", options: TransportationPropertyTypesOptions },
    { label: 'Exempt Property Types', id: "exemptPropCode", options: ExemptPropertyTypesOptions }
  ]


  const [propertyBValue, setPBValues] = useState({

    residentialPropClass: '',
    commercialPropClass: '',
    vaccantPropClass: '',
    vacantPropCode: '',
    otherPropClass: '',
    otherPropCode: '',

    residentialPropCode: '',
    multiFamilyPropCode: '',
    commercialPropCode: '',
    vacantPropCode: '',
    officePropCode: '',
    industrialPropCode: '',
    agriculturalPropCode: '',
    recreationalPropCode: '',
    transportationPropCode: '',
    exemptPropCode: '',


    propertyClassCode: '',
    landUseCode: null,
    multiFamilyPropCode: null,
    bedroomsMin: null,
    bedroomsMax: null,
    bathroomsMin: null,
    bathroomsMax: null,
    yearBuiltMin: null,
    yearBuiltMax: null,
    schoolDistrict: null,
    unitsMin: null,
    unitsMax: null,
    storiesMin: null,
    storiesMax: null,
    squareFeetMin: null,
    squareFeetMax: null,
    lotSquareFeetMin: null,
    lotSquareFeetMax: null,
    hoaPresent: '',

    poolType: '',
    garageType: '',
    basementType: '',

    poolAvailable: '',
    garageAvailable: '',
    basementAvailable: '',
    atticAvailable: ''

  });


  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'pb') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);


  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };


  useEffect(() => {
     if (filter) {
    setPBValues(filter);
    setFilterApplied(true);
     }
  }, [filter]);
  
  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnPropertyBValue();
    }
  }, [isFilterApplied]);

  const stringToBoolean = (str) => {
    // Convert "true" to true (case insensitive)
    if (str === true) {
      return true;
    }
    // Convert "false" to false (case insensitive)
    else if (str === false) {
      return false;
    }
    // For any other string, return undefined or handle accordingly
    else {
      return str;
    }
  }

  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    // add again
    // this will come for Property Classification(s) header section
    if (option?.label === 'All') {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    } else {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    }

  };


  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };


  // const getValueByPropertyTypesOptions = (value) => {
  //   if (!value) return [];
  //   let options = []
  //   const selectedValues = value.split(",");
  //   if (selectedValues.length > 1) {
  //     options = selectedValues.flatMap(id =>
  //       PropertyTypesNew.filter(item => item?.classCode === id)
  //     );
  //   } else {
  //     options = value === "all" ? [] : PropertyTypesNew.filter(item => item?.classCode === value);
  //   }
  //   options

  //   setPCharacterChildTittle(PropertyClassOptionsList?.find((item) => item?.value === value)?.label)
  //   setPCharacterChildOption(options)
  // };

  const handleSelectAllClick = (selectedOptions, leftValue, key, delimiter = ',') => {
    let name = ''
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }
      const selectedIds = selectedOptions.map(element => element.id).join(delimiter)
      const fullSeletedNamesList = selectedOptions.map(element => element.name).join(delimiter)
      updateFilterOldStore(key, selectedIds, fullSeletedNamesList)
      // meed to work morning
      updateFilterStore({ value: key, label: name }, key, leftValue, fullSeletedNamesList);
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftValue)
    }
  };


  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {
  
    const name = generateMinMaxValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxValuesToUpdate(minValue, maxValue, key);
  
    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel)
    
  };

  const inputValueChange = (value, lable, key) => {
    updateFilterOldStore(key, value)
    updateFilterStoreCombox(value, key, lable);
  };


  // store 
  const updateFilterOldStore = (key, id) => {
    // if (id) {
    dispatch(updateFilter({ [key]: id }));
    // }
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
    option.value = stringToBoolean(option.value)
    dispatch(updateLocalFilter([{
      key: 'pb', id: leftLabel, name: option.label, apiKey: key, [key]: option.value, removeID: key,
      showALLDisplayValue: fullSeletedNamesList
    }]));
    // }
  };

  const updateFilterStoreCombox = (name, key, leftLabel) => {
    dispatch(updateLocalFilter([{
      key: 'pb', id: leftLabel, name: name, apiKey: key, removeID: key,
      showALLDisplayValue: name
    }]));
  };

  const handleClearFilter = (id) => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = (id) => {
    dispatch(removeFilterById(id));
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const propertyFilters = getLocalFilter.filter(filter => filter.key === 'pb');
    return `${newsearchfilter.clsShowOnTag}`;
  };

  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  const getBooleanOption = (value, options) => {
    return options.find(option => option.value === value) || null;
  };

  const applyFiltersBasedOnPropertyBValue = () => {
    const fieldsToCheck = [
      // Range fields
      { label: "Bedrooms", min: propertyBValue.bedroomsMin, max: propertyBValue.bedroomsMax, key: "bedrooms" },
      { label: "Bathrooms", min: propertyBValue.bathroomsMin, max: propertyBValue.bathroomsMax, key: "bathrooms" },
      { label: "Year Built", min: propertyBValue.yearBuiltMin, max: propertyBValue.yearBuiltMax, key: "yearBuilt" },
      { label: "Square Feet", min: propertyBValue.squareFeetMin, max: propertyBValue.squareFeetMax, key: "squareFeet" },
      { label: "Lot Square Feet", min: propertyBValue.lotSquareFeetMin, max: propertyBValue.lotSquareFeetMax, key: "lotSquareFeet" },
      { label: "Number of Units", min: propertyBValue.unitsMin, max: propertyBValue.unitsMax, key: "units" },
      { label: "Stories", min: propertyBValue.storiesMin, max: propertyBValue.storiesMax, key: "stories" },
      // Boolean fields
      { label: "Association (HOA/COA)", value: propertyBValue.hoaPresent, key: "hoaPresent", isBoolean: true, options: IncludeExcludeOptions },
      { label: "Pool", value: propertyBValue.poolAvailable, key: "poolAvailable", isBoolean: true, options: IncludeExcludeOptions },
      { label: "Garage", value: propertyBValue.garageAvailable, key: "garageAvailable", isBoolean: true, options: IncludeExcludeOptions },
      { label: "Basement", value: propertyBValue.basementAvailable, key: "basementAvailable", isBoolean: true, options: IncludeExcludeOptions },
      { label: "Attic", value: propertyBValue.atticAvailable, key: "atticAvailable", isBoolean: true, options: IncludeExcludeOptions },
      // Text fields
      { label: "School District", value: propertyBValue.schoolDistrict, key: "schoolDistrict", isText: true },
      // Fields using handleOptionSelectPCNew
      { label: "Property Type", value: propertyBValue.propertyType, key: "propertyType", isOptionSelect: true },
      { label: "Listing Status", value: propertyBValue.listingStatus, key: "listingStatus", isOptionSelect: true },
      // Fields using handleSelectAllClick
      { label: "Pool Type", value: propertyBValue.poolType, key: "poolType", isSelectAll: true },
      { label: "Garage Type", value: propertyBValue.garageType, key: "garageType", isSelectAll: true },
      { label: "Basement Type", value: propertyBValue.basementType, key: "basementType", isSelectAll: true },


    ];
  
    fieldsToCheck.forEach(({ label, min, max, key, isBoolean, isText, isOptionSelect, isSelectAll, value, options }) => {
      if (isBoolean) {
        if (value !== null && value !== '' && value !== undefined) {
          // handleBooleanSelectPCNew({value:value}, key, label);
          const option = getBooleanOption(value, options);
          if (option) {
            handleBooleanSelectPCNew(option, key, label);
          } else {
            // Handle the case where no valid option is found
            handleOldRemoveFilter(key);
            handleClearFilter(label);
          }
        }
      } else if (isText) {
        if (value !== null && value !== '' && value !== undefined) {
          inputValueChange(value, label, key);
        }
      } else if (isOptionSelect) {
        if (value !== null && value !== '' && value !== undefined) {
          handleOptionSelectPCNew({ value }, key, label);
        }
      } else if (isSelectAll) {
        if (value !== null && value !== '' && value !== undefined) {
          handleSelectAllClick({ value }, key, label);
        }
      } else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboBoxValueChange(min, max, label, key);
        }
      }
    });
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  const getHeaderClassName = isFilteredSection("Property Classification(s)") ? newsearchfilter.filteredSubTitle : "";
  const isHighlightedIndex = filteredFields['Property Classification(s)'] === navIndex ? true : false;

  return (
    <div className={classNames(
      getClassNames('Property Classification(s)'),
      getClassNames('Bedrooms'),
      getClassNames('Bathrooms'),
      getClassNames('Building Size (SqFt)'),
      getClassNames('Lot Size (SqFt)'),
      getClassNames('Year Built'),
      getClassNames('Number of Units'),
      getClassNames('Number of Stories'),
      getClassNames('School District'),
      getClassNames('Association (HOA/COA)'),
      getClassNames('Pool'),
      getClassNames('Pool Type'),
      getClassNames('Garage'),
      getClassNames('Garage Type'),
      getClassNames('Basement'),
      getClassNames('Basement Type'),
      getClassNames('Attic')
    )}>    
      {/* <div className={newsearchfilter.clsRightBodyScroll}> */}
      <div className={newsearchfilter.clsContentBody}>
        <div className={newsearchfilter.headerBg}>
          <h2 className={newsearchfilter.clsHeading}>Property Details</h2>
        </div>
        {/* <h4 className={classNames(newsearchfilter.clsSubHeading, getClassNames('Property Classification(s)'))}>{propertyClassCode.label}</h4> */}
        <h4 className={classNames((getHeaderClassName === "" ? newsearchfilter.clsSubHeading: getHeaderClassName), (isHighlightedIndex ? newsearchfilter.highlightOrange : "") )}>{propertyClassCode.label}</h4>
        <div className={classNames(newsearchfilter.pb_28, getClassNames('Property Classification(s)'))}>
          <div id='propertyClassCode' className={newsearchfilter.clsBgGray} ref={el => refs.current['Property Classification(s)'] = el}> 
            <div className={propertyBValue.propertyClassCode ? newsearchfilter.pb_28 : ''}>
              <ToggleList
                headerTitleShow={false}
                headerTitle={propertyClassCode.label}
                backGroudGray={true}
                selected={propertyBValue.propertyClassCode ? propertyBValue.propertyClassCode : ''}
                options={propertyClassCode.options}
                isHighlightIndex={filteredFields['Property Classification(s)'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Property Classification(s)") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={(selectedOption) => {
                  // reset sub types on tab update
                  [...ResidentialChildList, ...CommercialChildList, ...VacantChildList, ...OthersChildList].forEach(itm => {
                    handleOldRemoveFilter(itm.key);
                    handleClearFilter(itm.label);
                  })
                  handleOptionSelectPCNew(
                    selectedOption,
                    propertyClassCode.name,
                    propertyClassCode.label
                  )
                  // getValueByPropertyTypesOptions(propertyClassCode.name)
                }
                }
              />
            </div>
            {!propertyBValue.propertyClassCode && <div className={newsearchfilter.filterExtraText}>Select Residential, Commercial, Vacant Land or Other to see more options</div>}
            {propertyBValue.propertyClassCode && propertyBValue.propertyClassCode === "R" && (
              <div className={newsearchfilter.clsBorderTopNoneLst}>
                {ResidentialChildList.map(({ label, id, options }) => (
                  // <div key={id}>
                  <div>
                    <div key={id} id={id} className={newsearchfilter.pb_28}>
                      <ToggleSection
                        headerTitle={label}
                        options={options}
                        selectedIds={propertyBValue[id]}
                        onSelectClickAction={(selectedOptions) => {
                          handleSelectAllClick(selectedOptions, label, id);
                        }}
                      />
                    </div>
                    <div className={classNames(
                      newsearchfilter.pb_28,
                      newsearchfilter.clsBorderTop
                    )}
                    ></div>
                  </div>
                ))}
              </div>
            )}
            {propertyBValue.propertyClassCode && propertyBValue.propertyClassCode === "C" && (
              <div>
                {CommercialChildList.map(({ label, id, options }) => (
                  <div key={id} id={id} className={newsearchfilter.pb_28}>
                    <ToggleSection
                      headerTitle={label}
                      options={options}
                      selectedIds={propertyBValue[id]}
                      onSelectClickAction={(selectedOptions) => {
                        handleSelectAllClick(selectedOptions, label, id);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
            {propertyBValue.propertyClassCode && propertyBValue.propertyClassCode === "V" && (
              <div>
                {VacantChildList.map(({ label, id, options }) => (
                  <div key={id} id={id} className={newsearchfilter.pb_28}>
                    <ToggleSection
                      headerTitle={label}
                      options={options}
                      selectedIds={propertyBValue[id]}
                      onSelectClickAction={(selectedOptions) => {
                        handleSelectAllClick(selectedOptions, label, id);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
            {propertyBValue.propertyClassCode && propertyBValue.propertyClassCode === "O,I,A,F,T,E" && (
              <div className={newsearchfilter.clsBorderTopNoneLst}>
                {OthersChildList.map(({ label, id, options }) => (
                  <div>
                  <div key={id} id={id} className={newsearchfilter.pb_28}>
                    <ToggleSection
                      headerTitle={label}
                      options={options}
                      selectedIds={propertyBValue[id]}
                      onSelectClickAction={(selectedOptions) => {
                        handleSelectAllClick(selectedOptions, label, id);
                      }}
                    />
                  </div>
                  <div className={classNames(
                      newsearchfilter.pb_28,
                      newsearchfilter.clsBorderTop
                    )}
                    ></div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>


        {/*  */}

        <div className={classNames(newsearchfilter.pb_28, getClassNames('Bedrooms'), getClassNames('Bathrooms'))}>
          <div id='bedrooms' />
          <Row>
            <div ref={el => refs.current['Bedrooms'] = el}></div>
            <Col md={6} xs={12} className={getClassNames('Bedrooms')}>
              <FilterComboBox
                boxTittle={"Bedrooms"}
                minOptions={BedroomOptions}
                maxOptions={BedroomOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxLength={maxLength}
                isHighlightIndex={filteredFields['Bedrooms'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Bedrooms") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: propertyBValue.bedroomsMin, max: propertyBValue.bedroomsMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, 'Bedrooms', "bedrooms")
                }
              />
            </Col>
            <div ref={el => refs.current['Bathrooms'] = el}></div>
            <Col id="bathrooms" md={6} xs={12} className={getClassNames('Bathrooms')}>
              <FilterComboBox
                boxTittle={"Bathrooms	"}
                minOptions={BathroomOptions}
                maxOptions={BathroomOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Bathrooms'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Bathrooms") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: propertyBValue.bathroomsMin, max: propertyBValue.bathroomsMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Bathrooms", "bathrooms")
                }
              />
            </Col>
          </Row>
        </div>

        <div className={classNames(newsearchfilter.pb_28, getClassNames('Building Size (SqFt)'), getClassNames('Lot Size (SqFt)'))}>
          <div id='squareFeet' />
          <Row>
            <div ref={el => refs.current['Building Size (SqFt)'] = el}></div>
            <Col md={6} xs={12} className={getClassNames('Building Size (SqFt)')}>
              <FilterComboBox
                boxTittle={"Building Size (SqFt)"}
                minOptions={BuildingSizeOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxLength={maxLength}
                isHighlightIndex={filteredFields['Building Size (SqFt)'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Building Size (SqFt)") ? newsearchfilter.filteredSubTitle : ""}
                maxOptions={BuildingSizeOptions}
                defaultValues={{ min: (propertyBValue.squareFeetMin || ''), max: (propertyBValue.squareFeetMax || '') }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Building Size (SqFt)", "squareFeet")
                }
              />
            </Col>
            <div ref={el => refs.current['Lot Size (SqFt)'] = el}></div>
            <Col id="lotSquareFeet" md={6} xs={12} className={getClassNames('Lot Size (SqFt)')}>
              <FilterComboBox
                boxTittle={"Lot Size (SqFt)"}
                minOptions={LotSizeOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxOptions={LotSizeOptions}
                isHighlightIndex={filteredFields['Lot Size (SqFt)'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Lot Size (SqFt)") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: propertyBValue.lotSquareFeetMin, max: propertyBValue.lotSquareFeetMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Lot Size (SqFt)", "lotSquareFeet")
                }
              />
            </Col>
          </Row>
        </div>

        <div className={classNames(newsearchfilter.pb_28, getClassNames('Year Built'), getClassNames('School District'))}>
          <div id='yearBuilt' />
          <Row>
            <div ref={el => refs.current['Year Built'] = el}></div>
            <Col md={6} xs={12} className={getClassNames('Year Built')}>
              <FilterComboBox
                boxTittle={"Year Built"}
                minOptions={YearBuiltOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxOptions={YearBuiltOptions}
                isHighlightIndex={filteredFields['Year Built'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Year Built") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: propertyBValue.yearBuiltMin, max: propertyBValue.yearBuiltMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Year Built", "yearBuilt")
                }
              />
            </Col>
            <div ref={el => refs.current['School District'] = el}></div>
            <Col id="schoolDistrict" md={6} xs={12} className={getClassNames('School District')}>
              <FilterInputComponent
                boxTittle={"School District"}
                isHighlightIndex={filteredFields['School District'] === navIndex ? true : false}
                headerClassName={isFilteredSection("School District") ? newsearchfilter.filteredSubTitle : ""}
                defaultValue={propertyBValue.schoolDistrict ? propertyBValue.schoolDistrict : ''}
                onChange={value => inputValueChange(value, "School District", 'schoolDistrict')}
              />
            </Col>
          </Row>
        </div>



        <div className={classNames(newsearchfilter.pb_28, getClassNames('Number of Units'), getClassNames('Number of Stories'))}>
          <div id='units' />
          <Row>
            <div ref={el => refs.current['Number of Units'] = el}></div>
            <Col md={6} xs={12} className={getClassNames('Number of Units')}>
              <FilterComboBox
                boxTittle={"Number of Units "}
                minOptions={NumberUnitOptions}
                maxOptions={NumberUnitOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxLength={maxLength}
                isHighlightIndex={filteredFields['Number of Units'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Number of Units") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: propertyBValue.unitsMin, max: propertyBValue.unitsMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Number of Units", 'units')
                }
              />
            </Col>
            <div ref={el => refs.current['Number of Stories'] = el}></div>
            <Col id="stories" md={6} xs={12} className={getClassNames('Number of Stories')}>
              <FilterComboBox
                boxTittle={"Number of Stories"}
                minOptions={NumberStoryOptions}
                minLabel={"Min"}
                maxOptions={NumberStoryOptions}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Number of Stories'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Number of Stories") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: propertyBValue.storiesMin, max: propertyBValue.storiesMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Number of Stories", 'stories')
                }
              />
            </Col>
          </Row>
        </div>


        <div className={classNames(newsearchfilter.pb_28, getClassNames('Association (HOA/COA)'))}>
          <div id='hoaPresent' ref={el => refs.current['Association (HOA/COA)'] = el}></div>
          <ToggleList
            headerTitle={'Association (HOA/COA)'}
            backGroudGray={false}
            selected={propertyBValue.hoaPresent}
            options={IncludeExcludeOptions}
            isHighlightIndex={filteredFields['Association (HOA/COA)'] === navIndex ? true : false}
            headerClassName={isFilteredSection("Association (HOA/COA)") ? newsearchfilter.filteredSubTitle : ""}
            onSelectOption={(selectedOption) =>
              handleBooleanSelectPCNew(
                selectedOption,
                'hoaPresent',
                'Association (HOA/COA)'
              )
            }
          />
        </div>

        <div className={classNames(newsearchfilter.pb_28, getClassNames('Pool'), getClassNames('Pool Type'))}>
          <h4 className={newsearchfilter.clsSubHeading}>Pool</h4>
          {/* <div className={propertyBValue.poolAvailable === true ? newsearchfilter.clsBgGray : newsearchfilter.clsBgGray}>  */}
          <div className={newsearchfilter.clsBgGray}> 
              <div id='poolAvailable' ref={el => refs.current['Pool'] = el}></div>
              <div className={propertyBValue.poolAvailable === true || propertyBValue.poolAvailable === false ? newsearchfilter.pb_28 : ''}>
              <ToggleList
                headerTitle={'Pool'}
                headerTitleShow={false}
                backGroudGray={true}
                selected={propertyBValue.poolAvailable}
                options={IncludeExcludeOptions}
                isHighlightIndex={filteredFields['Pool'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Pool") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={(selectedOption) => {
                  // reset sub types on tab update
                  handleOldRemoveFilter('poolType');
                  handleClearFilter('Pool Type');
                  handleBooleanSelectPCNew(
                    selectedOption,
                    'poolAvailable',
                    'Pool'
                  );
                }
                }
              />
              </div>

              { (propertyBValue.poolAvailable === true || propertyBValue.poolAvailable === false) ? (
                <>
                  <div id='poolType' ref={el => refs.current['Pool Type'] = el}></div>
                  <div className={propertyBValue.poolType === true ? newsearchfilter.pb_28 : newsearchfilter.pb_28}>
                  <ToggleSection
                    headerTitle={'Pool Type'}
                    options={PropertyPoolTypeList}
                    selectedIds={propertyBValue.poolType}
                    isHighlightIndex={filteredFields['Pool Type'] === navIndex ? true : false}
                    headerClassName={isFilteredSection("Pool Type") ? newsearchfilter.filteredSubTitle : ""}
                    onSelectClickAction={(selectedOptions) => {
                      handleSelectAllClick(
                        selectedOptions,
                        'Pool Type',
                        "poolType"
                      )
                    }
                    }
                  />
                  </div>
                </>
              ) : <FilterExtraTextDisplay />}
          </div>
        </div>

        <div className={classNames(newsearchfilter.pb_28, getClassNames('Garage'), getClassNames('Garage Type'))}>
          <h4 className={newsearchfilter.clsSubHeading}>Garage</h4>
          <div className={propertyBValue.garageAvailable ? newsearchfilter.clsBgGray : newsearchfilter.clsBgGray}> 
              <div id='garageAvailable' ref={el => refs.current['Garage'] = el} />
              <div className={propertyBValue.garageAvailable === true || propertyBValue.garageAvailable === false ? newsearchfilter.pb_28 : ''}>
              <ToggleList
                headerTitle={'Garage'}
                headerTitleShow={false}
                backGroudGray={false}
                selected={propertyBValue.garageAvailable}
                options={IncludeExcludeOptions}
                isHighlightIndex={filteredFields['Garage'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Garage") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={(selectedOption) => {
                  // reset sub types on tab update
                  handleOldRemoveFilter('garageType');
                  handleClearFilter('Garage Type');
                  handleBooleanSelectPCNew(
                    selectedOption,
                    'garageAvailable',
                    'Garage'
                  );
                }
                }
              />
              </div>

              { (propertyBValue.garageAvailable === true || propertyBValue.garageAvailable === false) ? (
                <>
                  <div id='garageType' ref={el => refs.current['Garage Type'] = el} ></div>
                  <div className={newsearchfilter.pb_28}>
                  <ToggleSection
                    headerTitle={'Garage Type'}
                    options={PropertyGarageTypesList}
                    selectedIds={propertyBValue.garageType}
                    isHighlightIndex={filteredFields['Garage Type'] === navIndex ? true : false}
                    headerClassName={isFilteredSection("Garage Type") ? newsearchfilter.filteredSubTitle : ""}
                    onSelectClickAction={(selectedOptions) => {
                      handleSelectAllClick(
                        selectedOptions,
                        'Garage Type',
                        "garageType"
                      )
                    }
                    }
                  />
                  </div>
                </>
              ): <FilterExtraTextDisplay />} 
          </div>
        </div>
        <div className={classNames(newsearchfilter.pb_28, getClassNames('Basement'), getClassNames('Basement Type'))}>
          <h4 className={newsearchfilter.clsSubHeading}>Basement</h4>
          <div className={propertyBValue.basementAvailable ? newsearchfilter.clsBgGray : newsearchfilter.clsBgGray}> 
              <div id='basementAvailable' ref={el => refs.current['Basement'] = el} />
              <div className={propertyBValue.basementAvailable === true || propertyBValue.basementAvailable === false ? newsearchfilter.pb_28 : ''}>
              <ToggleList
                headerTitle={'Basement'}
                backGroudGray={false}
                headerTitleShow={false}
                selected={propertyBValue.basementAvailable}
                options={IncludeExcludeOptions}
                isHighlightIndex={filteredFields['Basement'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Basement") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={(selectedOption) => {
                  // reset sub types on tab update
                  handleOldRemoveFilter('basementType');
                  handleClearFilter('Basement Type');
                  handleBooleanSelectPCNew(
                    selectedOption,
                    'basementAvailable',
                    'Basement'
                  )
                }
                }
              />
              </div>

              {(propertyBValue.basementAvailable === true || propertyBValue.basementAvailable === false) ? (
                <>
                  <div id='basementType' ref={el => refs.current['Basement Type'] = el} />
                  <div className={newsearchfilter.pb_28}>
                  <ToggleSection
                    headerTitle={'Basement Type'}
                    options={PropertyBasementTypesList}
                    splitByColon={true}
                    selectedIds={propertyBValue.basementType}
                    isHighlightIndex={filteredFields['Basement Type'] === navIndex ? true : false}
                    headerClassName={isFilteredSection("Basement Type") ? newsearchfilter.filteredSubTitle : ""}
                    onSelectClickAction={(selectedOptions) => {
                      // : for separated value
                      handleSelectAllClick(
                        selectedOptions,
                        'Basement Type',
                        "basementType",
                        ':'
                      )
                    }
                    }
                  />
                  </div>
                </>
              ): <FilterExtraTextDisplay />}

   </div>
   </div>


        <div id='atticAvailable' className={classNames(newsearchfilter.pb_28, getClassNames('Attic'))} ref={el => refs.current['Attic'] = el}>
          <ToggleList
            headerTitle={'Attic'}
            backGroudGray={false}
            selected={propertyBValue.atticAvailable}
            options={IncludeExcludeOptions}
            isHighlightIndex={filteredFields['Attic'] === navIndex ? true : false}
            headerClassName={isFilteredSection("Attic") ? newsearchfilter.filteredSubTitle : ""}
            onSelectOption={(selectedOption) =>
              handleBooleanSelectPCNew(
                selectedOption,
                'atticAvailable',
                'Attic'
              )
            }
          />
        </div>

        {/* </div> */}
      </div>
    </div>
    // </div>
  );
};

export default PropertyDetails;
