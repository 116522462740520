import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilterById,
  removeLocalFilter,
  selectNewFilter,
  selectShowAllEditOption,
  updateFilter,
  updateLocalFilter,
  selectLocalFilter,
  selectLocation,
  selectSelectedsavesearch
} from "../../../../../../data/search";
import newsearchfilter from "../../SearchFilterNew.scss";
import {
  FinancingTypesOptions,
  FistLoanCurrencyOptions,
  IncludeExcludeOptions,
  InterestRateOptions,
  LBDRecordDateOptions,
  ListingAmountOptionsList,
  LoanTypesOptions,
  MonthlyPaymentMaxLength,
  MonthlyPaymentOptions,
  OpenMortgageOptionsList,
  PercentMinMaxOptions,
  YesNoOptions,
  fieldTagsMap,
  formatDateShortWithUTC
} from "../../shared/ConstantsNew";
import ToggleSection from "../../shared/ToggleSection";
import ToggleList from "../../shared/ToggleSection/ToggleList";
// import CustomDatePicker from "../../shared/DateRanger";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import { useFilter } from "../../TagFilterContext";
import SelectAnyoneByMinMax from "../../shared/SelectAnyoneDate/SelectAnyoneByMinMax";
import moment from "moment";
import { generateMinMaxDateValue, generateMinMaxValue, getMinMaxDateToUpdate, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";

const MortgageInfo = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);
  const getLocalFilter = useSelector(selectLocalFilter);

  const getSelectedSaveSearchName = useSelector(selectSelectedsavesearch)

  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);
  const refs = useRef({});

  const [m, setMortgageInfoValues] = useState({
    mortgageCountMin: null,
    mortgageCountMax: null,
    openMortgageBalanceMin: null,
    openMortgageBalanceMax: null,
    mortgageRecDateMin: null,
    mortgageRecDateMax: null,
    mortgage1LtvRatioMin:null,
    mortgage1LtvRatioMax:null,
    mortgageInterestRateMin:null,
    mortgageInterestRateMax:null
  });

  useEffect(() => {
     if (filter) {
      // only for %
      setMortgageInfoValues(filter);
      setFilterApplied(true);
     }
  }, [filter]);

  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnMortgage(m);
    }
  }, [isFilterApplied]);


  const applyFiltersBasedOnMortgage = (m) => {
    const fieldsToCheck = [
      // Range fields
      { label: "Number of Open Mortgages", min: m.mortgageCountMin, max: m.mortgageCountMax, key: "mortgageCount" },
      { label: "Open Mortgage Remaining Balance", min: m.openMortgageBalanceMin, max: m.openMortgageBalanceMax, key: "openMortgageBalance" },
      
      // Date fields
      { label: "Mortgage Recording Date", min: m.mortgageRecDateMin, max: m.mortgageRecDateMax, key: "mortgageRecDate", isDate: true },
      
      // Select fields
      { label: "Loan Types", value: m.mortgageLoanCode, key: "mortgageLoanCode", options: LoanTypesOptions },
      { label: "Interest Rate Types", value: m.mortgageFinancingCode, key: "mortgageFinancingCode", options: FinancingTypesOptions },
      
      // ComboBox fields
      { label: "Total Monthly Payments", min: m.mortgageTotalPaymentMin, max: m.mortgageTotalPaymentMax, key: "mortgageTotalPayment" },
      { label: "Interest Rate % (Est.)", min: m.mortgageInterestRateMin, max: m.mortgageInterestRateMax, key: "mortgageInterestRate" , isPercent: true },
      { label: "1st Loan to Current Value (1LTV) %", min: m.mortgage1LtvRatioMin, max: m.mortgage1LtvRatioMax, key: "mortgage1LtvRatio", isPercent: true },
      { label: "1st Loan to Current Value (1LTV) Equity $", min: m.mortgage1EstimatedEquityMin, max: m.mortgage1EstimatedEquityMax, key: "mortgage1EstimatedEquity" },
      
      // Boolean fields
      { label: "Cash Buyer", value: m.cashBuyer, key: "cashBuyer", options: IncludeExcludeOptions, isBool: true },
      { label: "Free & Clear", value: m.freeClear, key: "freeClear", options: IncludeExcludeOptions, isBool: true },
      { label: "Seller Carry Back", value: m.mortgageSellerCarried, key: "mortgageSellerCarried", options: IncludeExcludeOptions, isBool: true },
    ];
  
    fieldsToCheck.forEach(({ label, min, max, key, isBool, isDate, value, options , isPercent = false  }) => {
      if (isBool) {
        const option = options.find(option => option.value === value);
        if (option) {
          handleBooleanSelectPCNew(option, key, label);
        } else {
          handleClearFilter(label);
        }
      } 
      else if (isDate) {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboDateValueChange(min, max, label, key);
        }
      } 
      else if (Array.isArray(options) && options.length) {
        const selectedValues = value ? value.split(',') : [];
        const selectedOptions = options.filter(option => selectedValues.includes(option.id));
        if (selectedOptions.length) {
          handleSelectAllClick(selectedOptions, label, key);
        } else {
          handleClearFilter(label);
        }
      } 
      else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboBoxValueChange(min, max, label, key);
        } else if (value !== null && value !== '' && value !== undefined) {
          handleOptionSelectPCNew({ label: value, value }, key, label);
        } else {
          handleClearFilter(label);
        }
      }
    });
  };




  const getEditOptions = useSelector(selectShowAllEditOption)

  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'm') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);


  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };

  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    updateFilterOldStore(key, option.value);
    updateFilterStore(option, key, leftLabel);
  };

  const handleSelectAllClick = (selectedOptions, leftValue, key) => {
    let name = "";
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }

      const selectedIds = selectedOptions.map(element => element.id).join(",");
      const fullSeletedNamesList = selectedOptions
        .map(element => element.name)
        .join(",");
      updateFilterOldStore(key, selectedIds);
      // meed to work morning
      updateFilterStore(
        { value: key, label: name },
        key,
        leftValue,
        fullSeletedNamesList
      );
    } else {
      handleOldRemoveFilter(key);
      handleClearFilter(leftValue);
    }
  };

  const inputValueChange = (value, lable, key) => {
    updateFilterOldStore(key, value);
    updateFilterStoreCombox(value, key, lable);
  };

  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {
    const name = generateMinMaxValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxValuesToUpdate(minValue, maxValue, key);
    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };


  // combo date
  const comboDateValueChange = (minValue, maxValue, leftLabel, key) => {
   
    const name = generateMinMaxDateValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxDateToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };

  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };

  // store
  const updateFilterOldStore = (key, id) => {
// only for % fileds
dispatch(updateFilter({ [key]: id }));
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
      dispatch(
        updateLocalFilter([
          {
            key: "m",
            id: leftLabel,
            name: option.label,
            apiKey: key,
            [key]: option.value,
            removeID: key,
            showALLDisplayValue: fullSeletedNamesList
          }
        ])
      );
    // }
  };

  const updateFilterStoreCombox = (name, key, leftLabel) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([{ key: "m", id: leftLabel, name: name, apiKey: key,
      removeID: key,
      showALLDisplayValue: name
    }])
    );
    // }
  };

  const handleClearFilter = id => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = id => {
    dispatch(removeFilterById(id));
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (date) => {
    setSelectedDate(date);
    console.log('Selected date:', date);
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const mortgageFilters = getLocalFilter.filter(filter => filter.key === 'm');
    return `${newsearchfilter.clsShowOnTag}`;
  };

  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  return (
    <div className={classNames(
      getClassNames('Seller Carry Back'),
      getClassNames('Free & Clear'),
      getClassNames('Cash Buyer'),
      getClassNames('1st Loan to Current Value (1LTV) Equity $'),
      getClassNames('1st Loan to Current Value (1LTV) %'),
      getClassNames('Interest Rate Types'),
      getClassNames('Interest Rate % (Est.)'),
      getClassNames('Total Monthly Payments'),
      getClassNames('Loan Types'),
      getClassNames('Mortgage Recording Date'),
      getClassNames('Open Mortgage Remaining Balance'),
      getClassNames('Number of Open Mortgages')
    )}>
      {/* <div className={newsearchfilter.clsRightBodyScroll}> */}
        <div className={newsearchfilter.clsContentBody}>
          <div className={newsearchfilter.headerBg}>
            <h2 className={newsearchfilter.clsHeading}>Mortgage Info</h2>
          </div>

          <div id="openMortgageQuantity" className={classNames(newsearchfilter.pb_28, getClassNames("Number of Open Mortgages"), getClassNames("Open Mortgage Remaining Balance"))}>
            <Row>
              <div ref={el => refs.current['Number of Open Mortgages'] = el}></div>
              <Col md={6} xs={12} className={getClassNames("Number of Open Mortgages")}>
                <FilterComboBox
                  boxTittle={"Number of Open Mortgages"}
                  minOptions={OpenMortgageOptionsList}
                  maxOptions={OpenMortgageOptionsList}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Number of Open Mortgages'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Number of Open Mortgages") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{
                    min: m.mortgageCountMin,
                    max: m.mortgageCountMax
                  }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(
                      minValue,
                      maxValue,
                      "Number of Open Mortgages",
                      "mortgageCount"
                    )
                  }
                />
              </Col>
              <Col md={6} xs={12} className={getClassNames("Open Mortgage Remaining Balance")}>
                <div id="openMortgageBalance" className={getClassNames("Open Mortgage Remaining Balance")} ref={el => refs.current['Open Mortgage Remaining Balance'] = el}></div>
                <FilterComboBox
                  boxTittle={"Open Mortgage Remaining Balance"}
                  minOptions={ListingAmountOptionsList}
                  maxOptions={ListingAmountOptionsList}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Open Mortgage Remaining Balance'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Open Mortgage Remaining Balance") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{
                    min: m.openMortgageBalanceMin,
                    max: m.openMortgageBalanceMax
                  }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(
                      minValue,
                      maxValue,
                      "Open Mortgage Remaining Balance",
                      "openMortgageBalance"
                    )
                  }
                />
              </Col>
            </Row>
          </div>

          <div id="mortgageRecDate" className={classNames(newsearchfilter.pb_28, getClassNames("Mortgage Recording Date"))} ref={el => refs.current['Mortgage Recording Date'] = el}>

          
            <SelectAnyoneByMinMax
              headerTitle={'Mortgage Recording Date'}
              box2Placeholder={'Select Range'}
              isHighlightIndex={filteredFields['Mortgage Recording Date'] === navIndex ? true : false}
              headerClassName={isFilteredSection("Mortgage Recording Date") ? newsearchfilter.filteredSubTitle : ""}
              defaultValues={{
                min: m.mortgageRecDateMin,
                max: m.mortgageRecDateMax
              }}
              onValueChange={(fromDate, toDate) => {
                if (fromDate || toDate) {
                  comboDateValueChange(fromDate, toDate, 'Mortgage Recording Date', 'mortgageRecDate')
                } else {
                  handleOldRemoveFilter('mortgageRecDate')
                  handleClearFilter('Mortgage Recording Date')
                }
              }}
              orText={'or'}
              options={LBDRecordDateOptions}
            />

          </div>

          <div
            className={classNames(
              newsearchfilter.pb_28,
              newsearchfilter.clsBorderTop,
              getClassNames('Loan Types')
            )}
          ></div>

          <div id="mortgageLoanCode" className={classNames(newsearchfilter.pb_28, getClassNames('Loan Types'))} ref={el => refs.current['Loan Types'] = el}>
            <div className={newsearchfilter.clsToggleMarginMins}>
              <ToggleSection
                headerTitle={"Loan Types"}
                options={LoanTypesOptions}
                selectedIds={m.mortgageLoanCode}
                isHighlightIndex={filteredFields['Loan Types'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Loan Types") ? newsearchfilter.filteredSubTitle : ""}
                onSelectClickAction={selectedOptions =>
                  handleSelectAllClick(
                    selectedOptions,
                    "Loan Types",
                    "mortgageLoanCode"
                  )
                }
              />
            </div>
          </div>

          <div
            className={classNames(
              newsearchfilter.pb_28,
              newsearchfilter.clsBorderTop,
              getClassNames('Total Monthly Payments')
            )}
          ></div>

          <div id="mortgageTotalPayment" className={classNames(newsearchfilter.pb_28, getClassNames('Total Monthly Payments'), getClassNames('Interest Rate % (Est.)'))}>
            <Row>
              <div ref={el => refs.current['Total Monthly Payments'] = el}></div>
              <Col md={6} xs={12} className={getClassNames('Total Monthly Payments')}>
                <FilterComboBox
                  boxTittle={"Total Monthly Payments"}
                  minOptions={MonthlyPaymentOptions}
                  maxOptions={MonthlyPaymentOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  maxLength={MonthlyPaymentMaxLength}
                  isHighlightIndex={filteredFields['Total Monthly Payments'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Total Monthly Payments") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{
                    min: m.mortgageTotalPaymentMin,
                    max: m.mortgageTotalPaymentMax
                  }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(
                      minValue,
                      maxValue,
                      "Total Monthly Payments",
                      "mortgageTotalPayment"
                    )
                  }
                />
              </Col>
              <Col md={6} xs={12} className={getClassNames('Interest Rate % (Est.)')}>
                <div id="mortgageInterestRate" ref={el => refs.current['Interest Rate % (Est.)'] = el} className={getClassNames('Interest Rate % (Est.)')}></div>
                <FilterComboBox
                  boxTittle={"Interest Rate % (Est.)"}
                  minOptions={InterestRateOptions}
                  maxOptions={InterestRateOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Interest Rate % (Est.)'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Interest Rate % (Est.)") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{
                    min: m.mortgageInterestRateMin,
                    max: m.mortgageInterestRateMax
                  }}
                  isPercent={true}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(
                      minValue,
                      maxValue,
                      "Interest Rate % (Est.)",
                      "mortgageInterestRate"
                    )
                  }
                />
              </Col>
            </Row>
          </div>

          <div
            className={classNames(
              newsearchfilter.pb_28,
              newsearchfilter.clsBorderTop,
              getClassNames('Interest Rate Types')
            )}
          ></div>
          <div id="mortgageFinancingCode" ref={el => refs.current['Interest Rate Types'] = el} className={classNames(newsearchfilter.pb_28, getClassNames('Interest Rate Types'))}>
            <div className={newsearchfilter.clsToggleMarginMins}> 
              <ToggleSection
                headerTitle={"Interest Rate Types"}
                options={FinancingTypesOptions}
                selectedIds={m.mortgageFinancingCode}
                isHighlightIndex={filteredFields['Interest Rate Types'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Interest Rate Types") ? newsearchfilter.filteredSubTitle : ""}
                onSelectClickAction={selectedOptions =>
                  handleSelectAllClick(
                    selectedOptions,
                    "Interest Rate Types",
                    "mortgageFinancingCode"
                  )
                }
              />
            </div>
          </div>

          <div
            className={classNames(
              newsearchfilter.pb_28,
              newsearchfilter.clsBorderTop,
              getClassNames('1st Loan to Current Value (1LTV) %')
            )}
          ></div>

          <div id="mortgage1LtvRatio" className={classNames(newsearchfilter.pb_28, getClassNames('1st Loan to Current Value (1LTV) %'), getClassNames('1st Loan to Current Value (1LTV) Equity $'))}>
            <Row>
              <div ref={el => refs.current['1st Loan to Current Value (1LTV) %'] = el}></div>
              <Col md={6} xs={12} className={getClassNames('1st Loan to Current Value (1LTV) %')}>
                <FilterComboBox
                  boxTittle={"1st Loan to Current Value (1LTV) %"}
                  minOptions={PercentMinMaxOptions}
                  maxOptions={PercentMinMaxOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['1st Loan to Current Value (1LTV) %'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("1st Loan to Current Value (1LTV) %") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{
                    min: m.mortgage1LtvRatioMin,
                    max: m.mortgage1LtvRatioMax
                  }}
                  isPercent={true}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(
                      minValue,
                      maxValue,
                      "1st Loan to Current Value (1LTV) %",
                      "mortgage1LtvRatio"
                    )
                  }
                />
              </Col>
              <Col md={6} xs={12} className={getClassNames('1st Loan to Current Value (1LTV) Equity $')}>
                <div id="mortgage1EstimatedEquity" ref={el => refs.current['1st Loan to Current Value (1LTV) Equity $'] = el} className={getClassNames('1st Loan to Current Value (1LTV) Equity $')}></div>
                <FilterComboBox
                  boxTittle={"1st Loan to Current Value (1LTV) Equity $"}
                  minOptions={FistLoanCurrencyOptions}
                  maxOptions={FistLoanCurrencyOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['1st Loan to Current Value (1LTV) Equity $'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("1st Loan to Current Value (1LTV) Equity $") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{
                    min: m.mortgage1EstimatedEquityMin,
                    max: m.mortgage1EstimatedEquityMax
                  }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(
                      minValue,
                      maxValue,
                      "1st Loan to Current Value (1LTV) Equity $",
                      "mortgage1EstimatedEquity"
                    )
                  }
                />
              </Col>
            </Row>
          </div>

          <div id="cashBuyer" className={classNames(newsearchfilter.pb_28, getClassNames('Cash Buyer'), getClassNames('Free & Clear'))}>
            <Row>
              <div ref={el => refs.current['Cash Buyer'] = el}></div>
              <Col md={6} xs={12} className={getClassNames('Cash Buyer')}>
                <ToggleList
                  headerTitle={"Cash Buyer"}
                  backGroudGray={false}
                  selected={m.cashBuyer}
                  options={YesNoOptions}
                  isHighlightIndex={filteredFields['Cash Buyer'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Cash Buyer") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={selectedOption =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      "cashBuyer",
                      "Cash Buyer"
                    )
                  }
                />
              </Col>
              <div ref={el => refs.current['Free & Clear'] = el}></div>
              <Col md={6} xs={12} className={getClassNames('Free & Clear')}>
                <ToggleList
                  headerTitle={"Free & Clear"}
                  backGroudGray={false}
                  selected={m.freeClear}
                  options={IncludeExcludeOptions}
                  isHighlightIndex={filteredFields['Free & Clear'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Free & Clear") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={selectedOption =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      "freeClear",
                      "Free & Clear"
                    )
                  }
                />
              </Col>
            </Row>
          </div>

          <div className={classNames(newsearchfilter.pb_28, getClassNames('Seller Carry Back'))}>
            <Row>
              <div ref={el => refs.current['Seller Carry Back'] = el}></div>
              <Col md={6} xs={12} className={getClassNames('Seller Carry Back')}>
                <ToggleList
                  headerTitle={"Seller Carry Back"}
                  backGroudGray={false}
                  selected={m.mortgageSellerCarried}
                  options={IncludeExcludeOptions}
                  isHighlightIndex={filteredFields['Seller Carry Back'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Seller Carry Back") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={selectedOption =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      "mortgageSellerCarried",
                      "Seller Carry Back"
                    )
                  }
                />
              </Col>

              <Col md={6} xs={12}></Col>
            </Row>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default MortgageInfo;
