import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from 'react-redux';
import { removeFilterById, removeLocalFilter, selectNewFilter, selectShowAllEditOption, updateFilter, updateLocalFilter, selectLocalFilter } from '../../../../../../data/search';
import newsearchfilter from "../../SearchFilterNew.scss";
import { IncludeExcludeOptions, LastSaleDateOptions, LastSalePriceOptions, OwnerLocationOptions, OwnershipOptions, PropertyOwnedOptions, TaxExemptionOptions, YearOwnershipOptions, YesNoOptions, fieldTagsMap, formatDateShortWithUTC } from '../../shared/ConstantsNew';
import ToggleSection from "../../shared/ToggleSection";
import ToggleList from "../../shared/ToggleSection/ToggleList";

import SelectAnyoneByMinMax from "../../shared/SelectAnyoneDate/SelectAnyoneByMinMax";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import { useFilter } from "../../TagFilterContext";
import moment from "moment";
import { generateMinMaxDateValue, generateMinMaxValue, getMinMaxDateToUpdate, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";


const OwnerInformationOccupancy = () => {

  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);
  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);
  const refs = useRef({});
  // console.log(getLocalFilter)
  const [oio, setOIOValues] = useState({
    // forSale: null,
    // rental: null,
    // mlsListingStatus: null,
    // mlsListingDate: null,
    // daysOnMarketMin: null,
    // daysOnMarketMax: null,
    // mlsListingAmountMin: null,
    // mlsListingAmountMax: null,
    // belowMarketPrice: null,
    // mlsListingKeyword: null

    ownershipLengthMin: null,
    ownershipLengthMax: null,
    ownerType: null,
    // date pending
    saleAmount: null,
    propertiesOwnedMin: null,
    propertiesOwnedMax: null,
    exemptions: null,
    ownerLocation: null,
    ownerDeceased: null,
    interFamilyTransfer: null,
    missingSaleIncluded: null,
    saleDateMin: null,
    saleDateMax: null,

  })

  useEffect(() => {
    if (filter) {
      setOIOValues(filter);
      setFilterApplied(true);
    }
  }, [filter]);

  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnOIO(oio);
    }
  }, [isFilterApplied]);

  const getEditOptions = useSelector(selectShowAllEditOption)

  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'oio') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);


  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };

  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    updateFilterOldStore(key, option.value)
    updateFilterStore(option, key, leftLabel)
  };

  const handleSelectAllClick = (selectedOptions, leftValue, key) => {
    let name = ''
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }
      const selectedIds = selectedOptions.map(element => element.id).join(',')
      const fullSeletedNamesList = selectedOptions.map(element => element.name).join(',')
      updateFilterOldStore(key, selectedIds)
      // meed to work morning
      updateFilterStore({ value: key, label: name }, key, leftValue, fullSeletedNamesList);
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftValue)
    }
  };

  const inputValueChange = (value, lable, key) => {
    updateFilterOldStore(key, value)
    updateFilterStoreCombox(value, key, lable);
  };

  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {

    const name = generateMinMaxValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxValuesToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel)
  };

  // Boolean
  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };


    // combo date
    const comboDateValueChange = (minValue, maxValue, leftLabel, key) => {
    
      const name = generateMinMaxDateValue(minValue, maxValue);
      const valuesToUpdate = getMinMaxDateToUpdate(minValue, maxValue, key);
  
      valuesToUpdate.forEach(({ key, value }) => {
        updateFilterOldStore(key, value);
      });
  
      updateFilterStoreCombox(name, key, leftLabel);
    };

  //  store
  // store 
  const updateFilterOldStore = (key, id) => {
    // if (id) {
      dispatch(updateFilter({ [key]: id }));
    // }
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
      dispatch(updateLocalFilter([{ key: 'oio', id: leftLabel, name: option.label, apiKey: key, [key]: option.value, removeID: key,
      showALLDisplayValue: fullSeletedNamesList
    }]));
    // }
  };

  const updateFilterStoreCombox = (name, key, leftLabel) => {
    // if (option.value) {
    dispatch(updateLocalFilter([{ key: 'oio', id: leftLabel, name: name, apiKey: key,
    removeID: key,
      showALLDisplayValue: name
  }]));
    // }
  };

  const handleClearFilter = (id) => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = (id) => {
    dispatch(removeFilterById(id));
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const ownerInfoFilters = getLocalFilter.filter(filter => filter.key === 'oio');
    return `${newsearchfilter.clsShowOnTag}`;
  };
  
  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const applyFiltersBasedOnOIO = (oio) => {
    const fieldsToCheck = [
      // Boolean fields
      { label: "Owner Occupied", value: oio.ownerOccupied, key: "ownerOccupied", options: [{ label: 'Yes', value: true }, { label: 'No', value: false }], isBool: true },
      { label: "Vacant", value: oio.vacant, key: "vacant", options: [{ label: 'Yes', value: true }, { label: 'No', value: false }], isBool: true },
      { label: "Include Unknown Sales Dates", value: oio.missingSaleIncluded, key: "missingSaleIncluded", options: [{ label: 'Yes', value: true }, { label: 'No', value: false }], isBool: true },
      { label: "Pre-Probate (Deceased Owners)", value: oio.ownerDeceased, key: "ownerDeceased", options: [{ label: 'Yes', value: true }, { label: 'No', value: false }], isBool: true },
      { label: "Intra-Family Transfer", value: oio.interFamilyTransfer, key: "interFamilyTransfer", options: [{ label: 'Yes', value: true }, { label: 'No', value: false }], isBool: true },
      
      // Range fields
      { label: "Years of Ownership", min: oio.ownershipLengthMin, max: oio.ownershipLengthMax, key: "ownershipLength" },
      { label: "Last Sale Price", min: oio.saleAmountMin, max: oio.saleAmountMax, key: "saleAmount" },
      { label: "Number of Properties Owned", min: oio.propertiesOwnedMin, max: oio.propertiesOwnedMax, key: "propertiesOwned" },
      
      // Date fields
      { label: "MLS Status Date", min: oio.saleDateMin, max: oio.saleDateMax, key: "saleDate", isDate: true },
      
      // Select fields
      { label: "Owner Type", value: oio.ownerType, key: "ownerType", options: OwnershipOptions },
      { label: "Absentee Owner Location", value: oio.ownerLocation, key: "ownerLocation", options: OwnerLocationOptions },
      { label: "Tax Exemption Status", value: oio.exemptions, key: "exemptions", options: TaxExemptionOptions },
    ];
  
    fieldsToCheck.forEach(({ label, min, max, key, isBool, isDate, value, options }) => {
      if (isBool) {
        const option = options.find(option => option.value === value);
        if (option) {
          handleBooleanSelectPCNew(option, key, label);
        } else {
          handleClearFilter(label);
        }
      } 
      else if (isDate) {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboDateValueChange(min, max, label, key);
        }
      } 
      else if (Array.isArray(options) && options.length) {
        const selectedValues = value ? value.split(',') : [];
        const selectedOptions = options.filter(option => selectedValues.includes(option.id));
        if (selectedOptions.length) {
          handleSelectAllClick(selectedOptions, label, key);
        } else {
          handleClearFilter(label);
        }
      } 
      else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboBoxValueChange(min, max, label, key);
        } else if (value !== null && value !== '' && value !== undefined) {
          handleOptionSelectPCNew({ label: value, value }, key, label);
        } else {
          handleClearFilter(label);
        }
      }
    });
  };
  

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  return (
    <div className={classNames(
      getClassNames('Owner Occupied'),
      getClassNames('Vacant'),
      getClassNames('Years of Ownership'),
      getClassNames('Owner Type'),
      getClassNames('Absentee Owner Location'),
      getClassNames('Last Sale Price'),
      getClassNames('Number of Properties Owned'),
      getClassNames('MLS Status Date'),
      getClassNames('Include Unknown Sales Dates'),
      getClassNames('Pre-Probate (Deceased Owners)'),
      getClassNames('Intra-Family Transfer'),
      getClassNames('Tax Exemption Status')
    )}>
      {/* <div className={newsearchfilter.clsRightBodyScroll}> */}
        <div className={newsearchfilter.clsContentBody}>

          <div className={newsearchfilter.headerBg}>
            <h2 className={newsearchfilter.clsHeading}>Owner Information & Occupancy</h2>
          </div>

          <div className={classNames(newsearchfilter.pb_28, getClassNames("Owner Occupied"), getClassNames("Vacant"))}>
            <Row>
              <div ref={el => refs.current['Owner Occupied'] = el}></div>
              <Col id="ownerOccupied" md={6} xs={12} className={getClassNames("Owner Occupied")}>
                <ToggleList
                  headerTitle={'Owner Occupied'}
                  backGroudGray={false}
                  selected={oio.ownerOccupied}
                  options={YesNoOptions}
                  isHighlightIndex={filteredFields['Owner Occupied'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Owner Occupied") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={(selectedOption) =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      'ownerOccupied',
                      'Owner Occupied',
                    )
                  }
                />
              </Col>
              <div ref={el => refs.current['Vacant'] = el}>
              <Col id="vacant" md={6} xs={12} className={getClassNames("Vacant")}>
                <ToggleList
                  headerTitle={'Vacant'}
                  backGroudGray={false}
                  selected={oio.vacant}
                  options={YesNoOptions}
                  isHighlightIndex={filteredFields['Vacant'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Vacant") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={(selectedOption) =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      'vacant',
                      'Vacant',
                    )
                  }
                />
              </Col>
              </div>
            </Row>
          </div>

          <div className={classNames(newsearchfilter.pb_28, newsearchfilter.clsBorderTop, getClassNames("Years of Ownership"))}></div>


          <div className={classNames(newsearchfilter.pb_28, getClassNames("Years of Ownership"))} ref={el => refs.current['Years of Ownership'] = el}>
            <Row>
              <Col id="ownershipLength" md={6} xs={12} className={getClassNames("Years of Ownership")}>
                <FilterComboBox
                  boxTittle={"Years of Ownership"}
                  minOptions={YearOwnershipOptions}
                  maxOptions={YearOwnershipOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Years of Ownership'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Years of Ownership") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: oio.ownershipLengthMin, max: oio.ownershipLengthMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, 'Years of Ownership', "ownershipLength")
                  }
                />
              </Col>
              <Col md={6} xs={12}></Col>
            </Row>

          </div>

          <div className={classNames(newsearchfilter.pb_28, newsearchfilter.clsBorderTop, getClassNames("Owner Type"))} ref={el => refs.current['Owner Type'] = el}></div>


          <div id="ownerType" className={classNames(newsearchfilter.pb_28, getClassNames("Owner Type"))}>
            <div className={newsearchfilter.clsToggleMarginMins}> 
              <ToggleSection
                headerTitle={'Owner Type'}
                options={OwnershipOptions}
                selectedIds={oio.ownerType}
                isHighlightIndex={filteredFields['Owner Type'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Owner Type") ? newsearchfilter.filteredSubTitle : ""}
                onSelectClickAction={(selectedOptions) => {
                  handleSelectAllClick(
                    selectedOptions,
                    'Owner Type',
                    "ownerType"
                  )
                }
                }
              />
            </div>
          </div>

          <div className={classNames(newsearchfilter.pb_28, newsearchfilter.clsBorderTop, getClassNames("Absentee Owner Location"))} ref={el => refs.current['Absentee Owner Location'] = el}></div>
          <div id="ownerLocation" className={classNames(newsearchfilter.pb_28, getClassNames("Absentee Owner Location"))}>
            <div className={newsearchfilter.clsToggleMarginMins}> 
              <ToggleSection
                headerTitle={'Absentee Owner Location'}
                options={OwnerLocationOptions}
                selectedIds={oio.ownerLocation}
                isHighlightIndex={filteredFields['Absentee Owner Location'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Absentee Owner Location") ? newsearchfilter.filteredSubTitle : ""}
                onSelectClickAction={(selectedOptions) => {
                  handleSelectAllClick(
                    selectedOptions,
                    'Absentee Owner Location',
                    "ownerLocation"
                  )
                }
                }
              />
            </div>
          </div>

          <div className={classNames(newsearchfilter.pb_28, newsearchfilter.clsBorderTop, getClassNames("Last Sale Price"))}></div>


          <div className={classNames(newsearchfilter.pb_28, getClassNames("Last Sale Price"), getClassNames("Number of Properties Owned"))} ref={el => refs.current['Last Sale Price'] = el}>
            <Row>
              <Col id="saleAmount" md={6} xs={12} className={getClassNames("Last Sale Price")}>
                <FilterComboBox
                  boxTittle={"Last Sale Price"}
                  minOptions={LastSalePriceOptions}
                  maxOptions={LastSalePriceOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Last Sale Price'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Last Sale Price") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: oio.saleAmountMin, max: oio.saleAmountMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, 'Last Sale Price', "saleAmount")
                  }
                />

              </Col>
              <div ref={el => refs.current['Number of Properties Owned'] = el}></div>
              <Col id="propertiesOwned" md={6} xs={12} className={getClassNames("Number of Properties Owned")}>

                <FilterComboBox
                  boxTittle={"Number of Properties Owned"}
                  minOptions={PropertyOwnedOptions}
                  maxOptions={PropertyOwnedOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Number of Properties Owned'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Number of Properties Owned") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: oio.propertiesOwnedMin, max: oio.propertiesOwnedMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, 'Number of Properties Owned', "propertiesOwned")
                  }
                />

              </Col>
            </Row>
          </div>


       <div id="saleDate" className={classNames(newsearchfilter.pb_28, getClassNames("MLS Status Date"))} ref={el => refs.current['MLS Status Date'] = el}>

       <SelectAnyoneByMinMax
              headerTitle={'Last Sale Date'}
              box2Placeholder={'Select Range'}
              isHighlightIndex={filteredFields['MLS Status Date'] === navIndex ? true : false}
              headerClassName={isFilteredSection("MLS Status Date") ? newsearchfilter.filteredSubTitle : ""}
              defaultValues={{
                min: oio.saleDateMin,
                max: oio.saleDateMax
              }}
              onValueChange={(fromDate, toDate) => {
                if(fromDate || toDate) {
               
                  comboDateValueChange(fromDate,toDate,'MLS Status Date','saleDate')
                } else {
                  handleOldRemoveFilter('saleDate')
                  handleClearFilter('MLS Status Date')
                }
              }}
              orText={'or'}
              options={LastSaleDateOptions}
            />

       </div>

          <div className={classNames(newsearchfilter.pb_28, getClassNames("Include Unknown Sales Dates"), getClassNames("Pre-Probate (Deceased Owners)"))} ref={el => refs.current['Include Unknown Sales Dates'] = el}>
            <Row>
              <Col id="missingSaleIncluded" md={6} xs={12} className={getClassNames("Include Unknown Sales Dates")}>
                <ToggleList
                  headerTitle={'Include Unknown Sales Dates'}
                  backGroudGray={false}
                  selected={oio.missingSaleIncluded}
                  options={IncludeExcludeOptions}
                  isHighlightIndex={filteredFields['Include Unknown Sales Dates'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Include Unknown Sales Dates") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={(selectedOption) =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      'missingSaleIncluded',
                      'Include Unknown Sales Dates',
                    )
                  }
                />
              </Col>
              <div ref={el => refs.current['Pre-Probate (Deceased Owners)'] = el}></div>
              <Col id="ownerDeceased" md={6} xs={12} className={getClassNames("Pre-Probate (Deceased Owners)")}>
                <ToggleList
                  headerTitle={'Pre-Probate (Deceased Owners)'}
                  backGroudGray={false}
                  selected={oio.ownerDeceased}
                  options={IncludeExcludeOptions}
                  isHighlightIndex={filteredFields['Pre-Probate (Deceased Owners)'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Pre-Probate (Deceased Owners)") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={(selectedOption) =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      'ownerDeceased',
                      'Pre-Probate (Deceased Owners)',
                    )
                  }
                />
              </Col>
            </Row>
          </div>

          <div className={classNames(newsearchfilter.pb_28, getClassNames("Intra-Family Transfer"))} ref={el => refs.current['Intra-Family Transfer'] = el}>
            <Row>
              <Col id="interFamilyTransfer" md={6} xs={12}>
                <ToggleList
                  headerTitle={'Intra-Family Transfer'}
                  backGroudGray={false}
                  selected={oio.interFamilyTransfer}
                  options={IncludeExcludeOptions}
                  isHighlightIndex={filteredFields['Intra-Family Transfer'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Intra-Family Transfer") ? newsearchfilter.filteredSubTitle : ""}
                  onSelectOption={(selectedOption) =>
                    handleBooleanSelectPCNew(
                      selectedOption,
                      'interFamilyTransfer',
                      'Intra-Family Transfer',
                    )
                  }
                />
              </Col>
            </Row>
          </div>

          <div className={classNames(newsearchfilter.pb_28, newsearchfilter.clsBorderTop, getClassNames("Tax Exemption Status"))}></div>

          <div id="exemptions" className={classNames(newsearchfilter.pb_28, getClassNames("Tax Exemption Status"))} ref={el => refs.current['Tax Exemption Status'] = el}>
            <div className={newsearchfilter.clsToggleMarginMins}> 
              <ToggleSection
                headerTitle={'Tax Exemption Status'}
                options={TaxExemptionOptions}
                selectedIds={oio.exemptions}
                isHighlightIndex={filteredFields['Tax Exemption Status'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Tax Exemption Status") ? newsearchfilter.filteredSubTitle : ""}
                onSelectClickAction={(selectedOptions) => {
                  handleSelectAllClick(
                    selectedOptions,
                    'Tax Exemption Status',
                    "exemptions"
                  )
                }
                }
              /> 
              </div> 
          </div>



        </div>
      </div>
    // </div>
  )
}

export default OwnerInformationOccupancy